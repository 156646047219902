export default function WhiteBox() {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_381:11162)">
        <path
          d="M121.939 23.7059C121.152 22.5831 119.818 22.0772 118.514 22.2711C118.464 22.2592 118.414 22.238 118.365 22.2309L64.9312 14.3878C64.3474 14.3004 63.7517 14.3642 63.1962 14.5675L20.3999 30.2536C20.381 30.2607 20.3644 30.2725 20.3455 30.2796C20.3006 30.2985 20.258 30.3268 20.2108 30.3434C19.9767 30.4474 19.7593 30.5703 19.5584 30.7145C19.4969 30.7594 19.4378 30.8043 19.3763 30.854C19.1565 31.0336 18.9556 31.2298 18.7878 31.4544C18.7783 31.4685 18.7641 31.4756 18.7547 31.4898L0.683518 56.2103C-0.00907159 57.1582 -0.188719 58.3921 0.206033 59.4983C0.600785 60.607 1.5203 61.4437 2.65964 61.7368L18.341 65.786V104.554C18.341 106.112 19.3598 107.49 20.8513 107.947L78.2677 125.491C78.2866 125.5 78.3079 125.493 78.3268 125.502C78.6341 125.587 78.9556 125.642 79.2771 125.644C79.2842 125.644 79.2936 125.649 79.3031 125.649H79.3054C79.6434 125.649 79.9744 125.587 80.3053 125.491C80.4093 125.457 80.5062 125.413 80.6126 125.368C80.7521 125.316 80.8892 125.278 81.0263 125.207L122.227 102.342C123.355 101.716 124.052 100.529 124.052 99.2407V60.4793L137.989 53.7898C138.927 53.3407 139.62 52.4968 139.885 51.4898C140.147 50.4805 139.955 49.4097 139.357 48.5564L121.939 23.7059ZM64.7918 21.5359L106.498 27.6581L78.8327 40.5219L35.0222 32.4495L64.7918 21.5359ZM23.1679 37.4749L73.3676 46.7268L58.6128 68.8613L9.49335 56.1796L23.1679 37.4749ZM25.4324 101.931V67.6156L59.2771 76.3545C59.5725 76.4278 59.868 76.468 60.1635 76.468C61.3288 76.468 62.4445 75.8912 63.1135 74.889L75.7574 55.9243V117.307L25.4324 101.931ZM116.958 97.1511L82.8464 116.078V60.0727L89.075 73.3076C89.4792 74.1609 90.2072 74.8228 91.0936 75.1348C91.4789 75.2766 91.8808 75.3404 92.2803 75.3404C92.8074 75.3404 93.3298 75.2222 93.8144 74.9953L116.953 63.8903L116.958 97.1511ZM93.9727 67.0554L84.0283 45.9278L117.835 30.2086L131.153 49.2064L93.9727 67.0554Z"
          fill="#E1E1E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_381:11162">
          <rect width="140" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
