import { axios } from 'lib';
import { incidentReportTestSubmit } from '../pages/report/ReportTestPage';



const SubmitIncidentTest = (data: incidentReportTestSubmit) => {
  return axios.post('anonymous/incident-test/create', data);
};



export {
  SubmitIncidentTest,
};
