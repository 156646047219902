export default function SuccessPasscode() {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_469:14049)">
          <path
            d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
            fill="#32BA7C"
          />
          <path
            d="M37.2246 72.5555L62.9344 98.2653C84.2278 92.587 100.001 73.1864 100.001 50.0003C100.001 49.5271 100.001 49.0539 100.001 48.5807L79.8114 29.9688L37.2246 72.5555Z"
            fill="#0AA06E"
          />
          <path
            d="M51.2616 61.1989C53.4698 63.4072 53.4698 67.1926 51.2616 69.4008L46.6875 73.975C44.4793 76.1832 40.6938 76.1832 38.4856 73.975L18.454 53.7857C16.2458 51.5775 16.2458 47.792 18.454 45.5838L23.0281 41.0097C25.2363 38.8015 29.0218 38.8015 31.23 41.0097L51.2616 61.1989Z"
            fill="white"
          />
          <path
            d="M68.7695 26.3417C70.9777 24.1335 74.7632 24.1335 76.9714 26.3417L81.5456 30.9158C83.7538 33.124 83.7538 36.9095 81.5456 39.1177L46.8453 73.6603C44.6371 75.8685 40.8516 75.8685 38.6434 73.6603L34.0692 69.0862C31.861 66.878 31.861 63.0925 34.0692 60.8843L68.7695 26.3417Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_469:14049">
            <rect width="100" height="100" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
