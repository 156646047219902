import { axios } from 'lib';

const Base_URL: string = '/anonymous/contents';
const PRE_FIX: string = '/anonymous/file/preview';
export interface IQueryGetContent {
  title?: string;
  type: string;
  page_number: number;
  page_size: number;
}

export interface IQueryGetContentDetail {
  type?: string;
  id?: string;
}

export interface IQueryGetCountUnRead {
  type?: string;
}

export interface IDownloadFileDetail {
  files: any;
  is_download: boolean;
}
const getListContent = (query: IQueryGetContent) => {
  return axios.get(`${Base_URL}/list`, { params: query });
};
const getListContentDetail = (query: IQueryGetContentDetail) => {
  return axios.get(`${Base_URL}/detail`, { params: query });
};

const getCountUnReads = (query: IQueryGetCountUnRead) => {
  return axios.get(`${Base_URL}/count-unreads`, { params: query });
};

const downloadFileDetail = (data: IDownloadFileDetail) => {
  return axios.post(PRE_FIX, data);
};

const getFilePreview = (data: IDownloadFileDetail) => {
  return axios.post(PRE_FIX, data);
};

export {
  getListContent,
  getListContentDetail,
  getCountUnReads,
  downloadFileDetail,
  getFilePreview,
};
