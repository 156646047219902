export default function AddIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => props?.onClick()}
    >
      <path
        d="M21.5 9.75H14.75V3C14.75 2.17172 14.0783 1.5 13.25 1.5H11.75C10.9217 1.5 10.25 2.17172 10.25 3V9.75H3.5C2.67172 9.75 2 10.4217 2 11.25V12.75C2 13.5783 2.67172 14.25 3.5 14.25H10.25V21C10.25 21.8283 10.9217 22.5 11.75 22.5H13.25C14.0783 22.5 14.75 21.8283 14.75 21V14.25H21.5C22.3283 14.25 23 13.5783 23 12.75V11.25C23 10.4217 22.3283 9.75 21.5 9.75Z"
        fill="#212121"
      />
    </svg>
  );
}
