import React from 'react';

export default function ArrowLeft() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_462:12965)">
          <path
            d="M4.99063 7.80234L9.24063 3.55234C9.53438 3.25859 10.0094 3.25859 10.3 3.55234L11.0063 4.25859C11.3 4.55234 11.3 5.02734 11.0063 5.31797L7.99375 8.33047L11.0062 11.343C11.3 11.6367 11.3 12.1117 11.0062 12.4023L10.3 13.1086C10.0063 13.4023 9.53125 13.4023 9.24063 13.1086L4.99063 8.8586C4.69688 8.57109 4.69688 8.09609 4.99063 7.80234Z"
            fill="#404040"
          />
        </g>
        <defs>
          <clipPath id="clip0_462:12965">
            <rect
              width="10"
              height="16"
              fill="white"
              transform="translate(16 3.33398) rotate(90)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
