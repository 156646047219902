import { LeftOutlined } from '@ant-design/icons';
import { Col, Row, Layout, Button, Typography } from 'antd';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import styles from './index.module.scss';
import { storage } from 'utils';
import { ApiPassCode } from 'features/passcode/api';
import SuccessPasscode from 'components/common/icon-svg/success';

const { Content } = Layout;
const { Title, Text } = Typography;
const Success = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location: any = useLocation();
  const locationState = location.state;

  const token = storage.getToken();
  const [result, setResult] = useState<Boolean>(storage.getCurentPasscodeEnable());

  useEffect(() => {
    if (locationState) {
      const querydata = {
        action: locationState?.action,
        current_passcode: '',
        new_passcode: '',
        confirm_passcode: '',
      };
      ApiPassCode(querydata)
        .then((res: any) => {
          storage.setCurentPasscodeEnable(res.data.passcode_enabled);
          setResult(res.data.passcode_enabled);
        })
        .catch((err) => {});
    } else {
      history.push({
        pathname: '/',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const goBack = () => {
    history.push('/');
  };
  if (!token) {
    return <Redirect to="auth/login" />;
  }
  return (
    <>
      <HeaderComponent />
      <Content className={styles.layout}>
        <Row className={styles.container}>
          <Col className={styles.card_success} xs={20} sm={16} md={12} lg={12}>
            <Text className={styles.btn_back} onClick={() => goBack()}>
              <LeftOutlined style={{ marginRight: '12px' }} />
              {t('login.back')}
            </Text>
            <div className={styles.card}>
              <Title className={styles.card_title}>{t('passcode.new.finish.title')}</Title>
              <div>
                <SuccessPasscode />
              </div>
              <Text className={styles.card_text}>
                {token && result
                  ? t('passcode.new.finish.description')
                  : t(`passcode.remove.finish.description`)}
              </Text>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.btn}
                onClick={() => {
                  history.push({ pathname: '/' });
                }}
                style={{ minHeight: 46 }}
              >
                {t('passcode.new.finish.home')}
              </Button>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Success;
