import React from 'react';

export default function LogOutIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4141 10.664L12.8516 17.2265C12.2656 17.8125 11.25 17.4023 11.25 16.5625V12.8125H5.9375C5.41797 12.8125 5 12.3945 5 11.875V8.12498C5 7.60544 5.41797 7.18748 5.9375 7.18748H11.25V3.43748C11.25 2.60154 12.2617 2.18748 12.8516 2.77341L19.4141 9.33591C19.7773 9.7031 19.7773 10.2969 19.4141 10.664ZM7.5 17.0312V15.4687C7.5 15.2109 7.28906 15 7.03125 15H3.75C3.05859 15 2.5 14.4414 2.5 13.75V6.24998C2.5 5.55857 3.05859 4.99998 3.75 4.99998H7.03125C7.28906 4.99998 7.5 4.78904 7.5 4.53123V2.96873C7.5 2.71091 7.28906 2.49998 7.03125 2.49998H3.75C1.67969 2.49998 0 4.17966 0 6.24998V13.75C0 15.8203 1.67969 17.5 3.75 17.5H7.03125C7.28906 17.5 7.5 17.289 7.5 17.0312Z"
        fill="white"
      />
    </svg>
  );
}
