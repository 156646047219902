// eslint-disable-next-line react-hooks/exhaustive-deps
import './check.scss';
import { Col, Row, Layout, Button, Typography, Form, Input, FormInstance, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { getIncidentList, IQueryGetListIncident } from 'features/message/api';
import { showError } from 'components/common/standby-notice';
import { validateOnlyNumber } from 'utils/common';

const { Content } = Layout;
const { Title } = Typography;

const CheckMessage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const textInput = useRef<FormInstance>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = (values: IQueryGetListIncident) => {
    setIsLoading(true);
    getIncidentList(values)
      .then((res) => {
        if (res) {
          setTimeout(() => {
            setIsLoading(false);
            history.push({
              pathname: '/message',
              state: { values: values },
            });
          }, 500);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const body = document.getElementById('root');
    if (body) {
      body.style.width = '100%';
    }
    return () => {
      const root = document.getElementById('root');
      root?.removeAttribute('style');
    };
  }, []);

  return (
    <Spin spinning={isLoading}>
      <HeaderComponent />
      <Content className="check-message">
        <Row className="container">
          <Col className="card-check" xs={20} sm={16} md={12} lg={12}>
            <div className="card">
              <Title className="title">{t('chat.list.passcode.title')}</Title>
              <Form className="form" onFinish={onFinish} ref={textInput}>
                <Form.Item
                  name="passcode"
                  className="form-item"
                  style={{ marginBottom: 20 }}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value)
                          return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                        if (value.length !== 6)
                          return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                        const isNumber: boolean = validateOnlyNumber(value);
                        if (!isNumber) {
                          return Promise.reject(new Error(t('passcode.input.invalid')));
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="input"
                    placeholder={t('chat.list.passcode.current_code.placeholder')}
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit" className="btn" style={{ marginTop: 0 }}>
                  {t('chat.list.passcode.unlock')}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Spin>
  );
};
export default CheckMessage;
