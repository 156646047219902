import { Form, Radio, Select } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
interface IProps {
  listRating: any;
  changeAnswerRating: (e: any, data: any) => void;
  answersRating: any;
}

const { Option } = Select;
export default function ListRating({ listRating, changeAnswerRating, answersRating }: IProps) {
  const { t } = useTranslation();

  return (
    <>
      {listRating?.answer_value.length > 0 && (
        <Form.Item
          className={listRating?.is_required ? 'rating' : 'before'}
          name={listRating?._id}
          label={listRating?.question_name}
          rules={[
            {
              required: listRating?.is_required,
              message: t('report.new.question_lists.required'),
            },
          ]}
        >
          <Select
            optionLabelProp="label"
            placeholder={t('report.new.rating')}
            onChange={(e) => changeAnswerRating(e, listRating)}
            className="select-rating"
          >
            {listRating?.answer_value?.map((e, index) => {
              const findValue = answersRating.find((e) => e?._id === listRating?._id);
              return (
                <Option className="menu-item" key={index} value={e}>
                  {e}
                  <Radio
                    checked={e === findValue?.answer}
                    className={e === findValue?.answer ? ' active-checked' : ''}
                  />
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
}
