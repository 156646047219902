export const API_URL: string = process.env.REACT_APP_API_URL as string;
export const APP_VERSION: string = process.env.REACT_APP_APP_VERSION as string;

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCUigCOKodEYVIXFqKLmBdgJ0-4pWZHi8M',
  authDomain: 'standby-f41b5.firebaseapp.com',
  projectId: 'standby-f41b5',
  storageBucket: 'standby-f41b5.appspot.com',
  messagingSenderId: '159260347456',
  appId: '1:159260347456:web:24fdf2b05ba941cdda63a5',
  measurementId: 'G-Z4PQGX7C50',
};

export const VAPIDKEY =
  'BIS2tYr5TipmwyTVc4720yLaFSalP37rE0zlajJuqUi7Rje2VY9Q8l_ZwmaUYmyvquH8OJzC_7gyocU3V8EGp8c';
