export default function AttachContent() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_386_11515)">
          <path
            d="M2.35143 14.567C0.525492 12.6829 0.559523 9.64473 2.39475 7.76755L8.94976 1.06252C10.3346 -0.354109 12.5857 -0.354234 13.9707 1.06252C15.3423 2.46545 15.344 4.72905 13.9707 6.13364L8.2567 11.9728C7.32373 12.927 5.79941 12.9137 4.88225 11.9416C3.99866 11.005 4.027 9.52055 4.92763 8.5993L9.41961 4.0107C9.61279 3.81339 9.92936 3.81002 10.1267 4.00317L10.8411 4.70252C11.0384 4.8957 11.0418 5.21227 10.8486 5.40958L6.3571 9.99773C6.20297 10.1554 6.19347 10.4174 6.33685 10.5694C6.47347 10.7142 6.68825 10.7166 6.8271 10.5745L12.5411 4.73542C13.1541 4.10848 13.1541 3.08777 12.5408 2.46048C11.9411 1.84714 10.9796 1.84683 10.3797 2.46048L3.82469 9.16548C2.73834 10.2767 2.72159 12.0755 3.78747 13.1753C4.85028 14.2719 6.56825 14.2733 7.63304 13.1842L13.0099 7.68423C13.203 7.48677 13.5195 7.4832 13.717 7.67623L14.4319 8.37511C14.6294 8.56814 14.633 8.8847 14.4399 9.08217L9.06301 14.5821C7.20126 16.4864 4.18978 16.4639 2.35143 14.567Z"
            fill="#21C0F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_386_11515">
            <rect width="14" height="16" fill="white" transform="translate(1)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
