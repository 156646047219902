import { Menu, Layout, Dropdown, Button, Space, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { storage } from 'utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import './index.scss';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import Symbol from 'components/common/icon-svg/symbol';
import Standby from 'components/common/icon-svg/standby';
import Group from 'components/common/icon-svg/group';
import ReloadIcon from 'components/common/icon-svg/reload';
import UnlockIcon from 'components/common/icon-svg/unlock';
import Lock from 'components/common/icon-svg/lock';
import SearchIcon from 'components/common/icon-svg/search';
import debounce from 'lodash/debounce';
import Search from 'antd/lib/input/Search';
import ModalGlobal from 'components/common/showModal/modalGlobal';

const { Header } = Layout;
const data = [{ title: '日本語', lang: 'ja' }];
interface IProps {
  loadingSearch?: (title: any) => void;
  info?: any;
}

function HeaderComponent(props: IProps) {
  const [language, setLanguage] = useState<String>('1');
  const [dataOrangzation, setDataOrangzation] = useState({});
  const [isModalGoBack, setModalGoback] = useState(false);
  const [callbackFunc, setCallbackFunc] = useState('');
  const history = useHistory();
  const location: any = useLocation();
  // local storage
  const token = storage.getToken();
  const currentUser = storage.getCurentUser();
  const passcode_enabled = storage.getCurentPasscodeEnable();
  const curentOrganization = storage.getCurentOrganization();
  // i18
  const { t, i18n } = useTranslation();
  const currentLanguageCode = Cookies.get('i18next') || 'ja';

  const currentLang = data.find((item) => item.lang === currentLanguageCode);

  const { loadingSearch, info } = props;

  useEffect(() => {
    if (currentLang) {
      setLanguage(currentLang.title);
    }
  }, [currentLang, dataOrangzation]);
  const menu = (
    <Menu className="menu_multi">
      {data.map((item, index) => {
        return (
          <Menu.Item key={index} onClick={() => handleChange(item)}>
            {item.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const ListOrganization = (
    <Menu className="menu_multi">
      {token &&
        currentUser?.org_data &&
        (currentUser?.org_data as any).map((list: any) => {
          return (
            <Menu.Item
              key={list.organization_id}
              onClick={() => changeOrganization(list.organization_id)}
            >
              {list.organization_name}
            </Menu.Item>
          );
        })}
    </Menu>
  );
  const changeOrganization = (id: any) => {
    if (currentUser) {
      const Organization: object = currentUser?.org_data.find((e: any) => e.organization_id === id);
      storage.setCurentOrganization(Organization);
      setDataOrangzation(Organization);
    }
  };
  const handleChange = (item: any) => {
    setLanguage(item.title);
    i18n.changeLanguage(item.lang);
  };

  const goFormPasscode = () => {
    history.push({
      pathname: token && passcode_enabled ? '/passcode/info' : '/passcode/info',
    });
  };

  const reLoadFunc = () => {
    window.location.reload();
  };
  const isContentPathName: boolean = location?.pathname === '/content' ? true : false;

  const handleChangeValue = (e: any) => {
    (loadingSearch as any)(e.target.value);
  };
  const handleSearch = debounce(handleChangeValue, 800);

  const handleGoOutBtn = (callback: string) => {
    const { location } = history;
    if (
      location.pathname.includes('/passcode/newpass') ||
      location.pathname.includes('/passcode/change') ||
      location.pathname.includes('/report') ||
      location.pathname.includes('/report-test')
    ) {
      setModalGoback(true);
      setCallbackFunc(callback);
    } else {
      switch (callback) {
        case 'handleGoBackHome':
          handleGoback();
          break;
        case 'goFormPasscode':
          goFormPasscode();
          break;
        case 'reLoadFunc':
          reLoadFunc();
          break;
        default:
      }
    }
  };

  const handleGoOutOK = () => {
    setModalGoback(false);
    switch (callbackFunc) {
      case 'handleGoBackHome':
        handleGoback();
        break;
      case 'goFormPasscode':
        goFormPasscode();
        break;
      case 'reLoadFunc':
        reLoadFunc();
        break;
      default:
    }
  };

  const handleGoback = () => {
    storage.setKeyMenu('1');
    history.push({
      pathname: '/',
    });
  };

  const handleCancel = () => {
    setModalGoback(false);
  };

  return (
    <Header className="header_component">
      <ModalGlobal
        visible={isModalGoBack}
        handleOk={handleGoOutOK}
        handleCancel={handleCancel}
        contentText={t(`passcode.leave.title`)}
        text={t(`passcode.leave.ok`)}
        textCancel={t(`passcode.leave.cancel`)}
      />
      <div className="logo" onClick={() => handleGoOutBtn('handleGoBackHome')}>
        <Space size={8} className="space">
          <Symbol />
          <Standby />
        </Space>
      </div>
      {token && (
        <div className="container-org">
          <Dropdown overlay={ListOrganization} className="item" trigger={['click']}>
            <Button className="btn_changeorzgation">
              <Space className="space-orgzation">
                <Group />
                {token && curentOrganization?.organization_name}
              </Space>
            </Button>
          </Dropdown>
        </div>
      )}
      {isContentPathName && (
        <div className="search">
          <Search
            placeholder={t('header.content.input.placeholder')}
            className="search-input"
            suffix={<SearchIcon />}
            onChange={handleSearch}
          />
        </div>
      )}
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['1']}
        className="menu"
        style={{ flex: !isContentPathName ? '1' : 'unset' }}
      >
        {token && (
          <>
            <Menu.Item key="1" className="menu_item">
              <div style={{ display: 'flex' }} onClick={() => handleGoOutBtn('reLoadFunc')}>
                <ReloadIcon />
              </div>
            </Menu.Item>
            <Menu.Item key="2" className="menu_item last">
              <div style={{ display: 'flex' }} onClick={() => handleGoOutBtn('goFormPasscode')}>
                {token && passcode_enabled ? <UnlockIcon /> : <Lock />}
              </div>
            </Menu.Item>
          </>
        )}
      </Menu>
      <div className="lang">
        <Dropdown overlay={menu} className="item" placement="bottomLeft" trigger={['click']}>
          <Button ghost className="btn">
            {language}
            <DownOutlined style={{ marginLeft: 20 }} />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
}

export default HeaderComponent;
