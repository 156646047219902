import { Space, Typography } from 'antd';
import { showError } from 'components/common/standby-notice';
import { IPayloadAppendMessageIncidents, messageActions } from 'features/message/messageSlice';
import {
  disconnectSocket,
  initialSocket,
  leaveRoom,
  onHandleErrorSocket,
  onReceiveMessage,
  onReceiveDeleteMessage,
} from 'lib/socket';
import moment from 'moment';
import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ScrollableFeed from 'react-scrollable-feed';
import { storage } from 'utils';
import get from 'lodash/get';
import { formatDate } from 'utils/common';
import { axios } from 'lib';
import { API_URL } from 'config';
const { Text } = Typography;

interface IListMessageProps {
  loadingGetListMessageChat: (activeId: any) => void;
  itemSelected: any;
  setIsClosedInputChat: any;
  chats: Array<any>;
}
const ListMessage = ({
  loadingGetListMessageChat,
  itemSelected,
  setIsClosedInputChat,
  chats,
}: IListMessageProps) => {
  const { t } = useTranslation();
  const client_id = storage.getClientId();

  const dropRef: any = useRef();

  const dispatch = useDispatch();

  const initialChatRoom = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (itemSelected?.incident_id && token) {
        axios.get(`${API_URL}`, {
          params: { id: itemSelected?.incident_id, type: 'initialChatRoom:Prepare' },
        });
        initialSocket(token, itemSelected?.incident_id);
      } else {
        axios.get(`${API_URL}`, { params: { token: token + '--', type: 'initialChatRoomError' } });
      }
      resolve();
    });
  useEffect(() => {
    if (itemSelected?.incident_id && itemSelected?.incident_id !== '') {
      loadingGetListMessageChat(itemSelected?.incident_id);
      initialChatRoom().then(() => {
        onReceiveMessage((msg: any) => {
          let payload: IPayloadAppendMessageIncidents;
          if (msg.files > 0 && msg.text) {
            const newMsg: any = {
              ...msg,
              textMessageFile: `${t('chat.detail.attachment', { number: msg.files })}`,
            };
            payload = {
              msg: newMsg,
            };
          } else {
            payload = {
              msg,
            };
          }
          dispatch(messageActions.appendListMessageSaga(payload));

          if (msg.is_admin === false) return executeScroll();
        });
        onReceiveDeleteMessage(client_id, () => {
          loadingGetListMessageChat(itemSelected?.incident_id);
        });
      });
      onHandleErrorSocket((msg: any) => {
        if (msg) {
          switch (get(msg, 'response.error_code')) {
            case 'IC1007':
              showError(t(`incidents.detail.listDetail.header.text.closed`));

              // chuyen trang thai close khi dang chat
              setIsClosedInputChat(true);
              break;
            // others case
            default:
              break;
          }
        }
      });
    }
    return () => {
      if (itemSelected?.incident_id) {
        leaveRoom(itemSelected?.incident_id);
        disconnectSocket();
      }
    };
  }, [itemSelected?.incident_id]);

  const executeScroll = () => {
    dropRef.current?.scrollIntoView();
  };

  const convertDate = (date) => {
    const current = new Date();
    if (formatDate(current) === formatDate(date)) {
      return moment(date).format('HH:mm');
    }
    return moment(date).format('YYYY/MM/DD HH:mm');
  };
  return (
    <div className="main" id="message_chat">
      <ScrollableFeed className="scrollable">
        {Array.isArray(chats) &&
          chats.length > 0 &&
          chats.map((item, index) => {
            return (
              <Fragment key={index}>
                <Space className={item?.is_admin ? 'main_space left' : 'main_space'}>
                  {item?.is_admin ? (
                    !item?.is_deleted ? (
                      <div className="message-left">
                        <Space className="orzgantion_chat">
                          <Text>{item?.text}</Text>
                        </Space>
                        <Text className="text_time">{convertDate(item?.createdAt)}</Text>
                      </div>
                    ) : (
                      <div className="message-left">
                        <Space className="orzgantion_chat ">
                          <Text style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              alt=""
                              src="/image/trash.png"
                              className="ic-delete"
                              style={{ width: '16px', height: '16px', cursor: 'auto' }}
                            />
                            {t('message.admin.delete')}
                          </Text>
                        </Space>
                        <Text className="text_time">{convertDate(item?.deletedAt)}</Text>
                      </div>
                    )
                  ) : (
                    <Space className="flex-chat">
                      <div className="message-right">
                        <div className="my_chat">
                          {item?.files > 0 && (
                            <div className="text_chat">
                              {t('chat.detail.attachment', { number: item?.files })}
                            </div>
                          )}
                          <div style={{ float: 'left' }} className="text_chat">
                            {item?.text}
                          </div>
                        </div>
                        <Text className="text_time">{convertDate(item?.createdAt)}</Text>
                      </div>
                    </Space>
                  )}
                </Space>
                <div ref={dropRef}></div>
              </Fragment>
            );
          })}
      </ScrollableFeed>
    </div>
  );
};

export default ListMessage;
