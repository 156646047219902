import { Button, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IModalPreviewProps {
  onRef?: any;
}
const ModalTerm = ({ onRef }: IModalPreviewProps) => {
  const { t } = useTranslation();

  const [data, setVisible] = useState({ visible: false, url: null, type: null });
  const [loading, setLoading] = useState(false);

  const [titleModal, setTitleModal] = useState<string>('');

  const { url, type, visible } = data;

  useMemo(() => {
    if (onRef) onRef(handleVisible);
    return () => {
      if (onRef) onRef(undefined);
    };
  }, []);

  useEffect(() => {
    if (!url) return;
    renderData(url);
  }, [data]);

  function handleVisible(data) {
    setVisible(data);
    setLoading(true);
  }

  const renderPage = async ({ pdfDoc, scale }) => {
    const totalPage = pdfDoc._pdfInfo.numPages;
    const element = document.getElementById('element_canvas_append');
    if (!element) return;
    element.innerHTML = '';
    for (let i = 1; i <= totalPage; i++) {
      const canvas = document.createElement('canvas');
      const page = await pdfDoc.getPage(i);
      const ctx = canvas.getContext('2d');
      const viewport = page.getViewport({ scale });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      canvas.style.width = '100%';
      element.append(canvas);
      page.render({
        canvasContext: ctx,
        viewport: viewport,
      });
    }
  };

  const renderData = async (url) => {
    try {
      setLoading(true);
      const loadingTask = pdfjs.getDocument(url);
      const pdfDoc = await loadingTask.promise;
      await renderPage({ pdfDoc, scale: 5 });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!visible) return null;

  return (
    <Modal
      width={'90%'}
      closable={false}
      className="modal-preview"
      title={type === 'TERM' ? t('terms.title') : t('policies.title')}
      visible={visible}
      footer={
        <Button className="btn_closepre" onClick={() => handleVisible(false)}>
          {t('modal.term_of_use.close')}
        </Button>
      }
    >
      <Spin spinning={loading}>
        <div
          style={{
            textAlign: 'center',
            background: '#535659',
            padding: 5,
          }}
          id="element_canvas_append"
        />
      </Spin>
    </Modal>
  );
};

export default ModalTerm;
