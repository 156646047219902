// eslint-disable-next-line react-hooks/exhaustive-deps
import { LeftOutlined } from '@ant-design/icons';
import { Col, Row, Layout, Button, Typography, Form, Input, FormInstance, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { ApiPassCode } from 'features/passcode/api';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { storage } from 'utils';
import { showError, showSuccess } from 'components/common/standby-notice';
import { validateOnlyNumber } from 'utils/common';
import './passcode.scss';
import ModalGlobal from 'components/common/showModal/modalGlobal';
const { Content } = Layout;
const { Title, Text } = Typography;

interface FromSetPassCode {
  action?: string | any;
  new_passcode?: string;
  confirm_passcode?: string;
  current_passcode?: string;
}
const UpdatePassCode = () => {
  const [info, setInfo] = useState<FromSetPassCode>();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isModalGoBack, setIsModalGoBack] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();
  const location: any = useLocation();
  const state = location?.state;
  const token = storage.getToken();
  const textInput = useRef<FormInstance>(null);

  useEffect(() => {
    if (state) {
      setInfo({ ...info, action: state?.action });
    } else {
      setInfo({ ...info, action: 'UPDATE' });
    }
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const onFinish = (values) => {
    setIsLoadingUpdate(true);

    const valueChange = {
      ...info,
      ...values,
    };

    if (info?.action) {
      ApiPassCode(valueChange)
        .then((res) => {
          showSuccess(t('passcode.new.finish.title'));
          setTimeout(() => {
            setIsLoadingUpdate(false);
          }, 500);
          if (res) {
            history.push({
              pathname: '/passcode/success',
              state: { action: 'STATUS' },
            });
          }
        })
        .catch((err) => {
          setIsLoadingUpdate(false);
        });
    } else {
      showError(t('Not Have Action'));
    }
  };

  if (!token) {
    return <Redirect to="/auth/login" />;
  }
  const handleGoback = () => {
    setIsModalGoBack(false);
    history.push('/');
  };
  const showModalGoBack = () => {
    setIsModalGoBack(true);
  };
  const handleCancel = () => {
    setIsModalGoBack(false);
  };

  return (
    <Spin spinning={isLoadingUpdate}>
      <ModalGlobal
        visible={isModalGoBack}
        handleOk={handleGoback}
        handleCancel={handleCancel}
        contentText={t(`passcode.leave.title`)}
        text={t(`passcode.leave.ok`)}
        textCancel={t(`passcode.leave.cancel`)}
      />
      <HeaderComponent />
      <Content className="layout_container">
        <Row className="container">
          <Col className="card_passcode" xs={20} sm={16} md={12} lg={12}>
            <Text className="card_text" onClick={showModalGoBack}>
              <LeftOutlined style={{ marginRight: '12px' }} />
              {t('login.back')}
            </Text>
            <div className="card">
              <Title className="title">
                {state?.action === 'REMOVE'
                  ? t('passcode.remove.title')
                  : t('passcode.reset.title')}
              </Title>
              <Form className="form_input" onFinish={onFinish} ref={textInput}>
                <Form.Item
                  name="current_passcode"
                  className="form_item"
                  style={{ marginBottom: 20 }}
                  dependencies={['currentcode']}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value)
                          return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                        if (value.length !== 6)
                          return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                        const isNumber: boolean = validateOnlyNumber(value);
                        if (!isNumber) {
                          return Promise.reject(new Error(t('passcode.input.invalid')));
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="input"
                    placeholder={
                      state?.action === 'REMOVE'
                        ? t('passcode.remove.current_passcode.placeholder')
                        : t('passcode.reset.current_code.placeholder')
                    }
                  />
                </Form.Item>
                {state?.action === 'REMOVE' ? (
                  ''
                ) : (
                  <>
                    <Form.Item
                      name="new_passcode"
                      className="form_item"
                      style={{ marginBottom: '20px' }}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value)
                              return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                            if (value.length !== 6)
                              return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                            const isNumber: boolean = validateOnlyNumber(value);
                            if (!isNumber) {
                              return Promise.reject(new Error(t('passcode.input.invalid')));
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="input"
                        placeholder={t('passcode.reset.new_code.placeholder')}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirm_passcode"
                      className="form_item"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value)
                              return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                            if (value.length !== 6)
                              return Promise.reject(new Error(t('home.menu.passcode.minlength')));

                            const isNumber: boolean = validateOnlyNumber(value);
                            if (!isNumber) {
                              return Promise.reject(new Error(t('passcode.input.invalid')));
                            }
                            if (getFieldValue('new_passcode') !== value) {
                              return Promise.reject(
                                new Error(t('home.menu.passcode.set_new_code.input.not_match'))
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="input"
                        placeholder={t('passcode.reset.confirm_code.placeholder')}
                      />
                    </Form.Item>
                  </>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn"
                  style={{ marginTop: `${state?.action === 'REMOVE' ? '0' : '20'}` }}
                >
                  {state?.action === 'REMOVE'
                    ? t('passcode.remove.remove_btn')
                    : t('passcode.reset.set_btn')}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Spin>
  );
};
export default UpdatePassCode;
