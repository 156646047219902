
export default function Standby() {
    return (
        <svg width="99" height="16" viewBox="0 0 99 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.914894 3.90148C1.39746 -0.166681 7.553 -0.901754 10.454 1.3203C11.8793 2.47622 10.3699 4.30549 9.11293 3.37963C7.55862 2.39205 4.02914 1.93193 3.58585 4.11471C3.02473 7.56001 12.1542 5.58485 12.0027 11.168C11.8568 16.5044 4.14698 16.6278 1.22913 14.2318C0.942023 13.9864 0.754064 13.645 0.700367 13.2712C0.646669 12.8973 0.730904 12.5168 0.93734 12.2005C1.04294 12.0575 1.17805 11.9389 1.33349 11.8528C1.48894 11.7666 1.6611 11.7148 1.83831 11.701C2.01551 11.6871 2.19362 11.7115 2.36056 11.7726C2.5275 11.8336 2.67937 11.9298 2.80589 12.0546C4.52854 13.2274 8.97827 14.1083 9.27005 11.1288C9.00071 7.99769 0.263989 9.86624 0.914894 3.90148Z" fill="#404040"/>
        <path d="M18.3658 3.37962H14.6904C14.5066 3.3915 14.3224 3.36556 14.149 3.3034C13.9756 3.24124 13.8169 3.14418 13.6825 3.01823C13.5481 2.89227 13.441 2.74009 13.3678 2.5711C13.2946 2.40211 13.2568 2.2199 13.2568 2.03573C13.2568 1.85156 13.2946 1.66934 13.3678 1.50035C13.441 1.33136 13.5481 1.17918 13.6825 1.05323C13.8169 0.927271 13.9756 0.830212 14.149 0.768051C14.3224 0.705891 14.5066 0.67995 14.6904 0.691832H24.6223C24.8061 0.67995 24.9904 0.705891 25.1638 0.768051C25.3371 0.830212 25.4959 0.927271 25.6303 1.05323C25.7646 1.17918 25.8717 1.33136 25.9449 1.50035C26.0181 1.66934 26.0559 1.85156 26.0559 2.03573C26.0559 2.2199 26.0181 2.40211 25.9449 2.5711C25.8717 2.74009 25.7646 2.89227 25.6303 3.01823C25.4959 3.14418 25.3371 3.24124 25.1638 3.3034C24.9904 3.36556 24.8061 3.3915 24.6223 3.37962H21.1321V13.9849C21.1321 15.8085 18.3658 15.8534 18.3658 13.9849V3.37962Z" fill="#404040"/>
        <path d="M25.9183 15.2867C25.7402 15.1997 25.5813 15.078 25.4509 14.9287C25.3205 14.7794 25.2213 14.6055 25.1591 14.4173C25.0969 14.2291 25.073 14.0303 25.0887 13.8327C25.1044 13.6351 25.1594 13.4427 25.2506 13.2667L31.2771 1.48302C31.3874 1.22945 31.5694 1.01362 31.8006 0.862022C32.0319 0.710426 32.3024 0.629669 32.5789 0.629669C32.8554 0.629669 33.1259 0.710426 33.3572 0.862022C33.5885 1.01362 33.7704 1.22945 33.8807 1.48302L39.8006 13.2667C40.6591 14.9051 38.1172 16.207 37.3653 14.5685L36.4394 12.7224H28.6791L27.7757 14.5685C27.4558 15.2867 26.6759 15.4326 25.9183 15.2867ZM34.9469 9.95602L32.607 4.76561L29.9921 9.95602H34.9469Z" fill="#404040"/>
        <path d="M41.7312 2.2237C41.7312 1.46619 41.8995 0.731112 42.9881 0.731112C43.7456 0.731112 43.9139 0.921895 44.4807 1.46619L51.433 10.1636V2.01609C51.4365 1.65433 51.5727 1.30643 51.8158 1.0385C52.0589 0.770572 52.3919 0.601275 52.7517 0.562775C53.1331 0.586726 53.4933 0.74646 53.7671 1.01305C54.0409 1.27964 54.2102 1.63547 54.2443 2.01609V13.8334C54.2631 14.1701 54.16 14.5023 53.954 14.7692C53.7479 15.0361 53.4525 15.2198 53.122 15.2867C52.8041 15.2994 52.4875 15.2399 52.1959 15.1125C51.9043 14.9852 51.6454 14.7934 51.4386 14.5516L44.4807 5.73074V13.8334C44.5015 14.0184 44.4826 14.2056 44.4252 14.3827C44.3678 14.5598 44.2732 14.7225 44.1478 14.8601C44.0225 14.9977 43.8692 15.1069 43.6982 15.1805C43.5272 15.254 43.3425 15.2903 43.1564 15.2867C42.9653 15.2923 42.7751 15.2584 42.5978 15.187C42.4204 15.1156 42.2598 15.0083 42.1259 14.8718C41.9921 14.7353 41.8879 14.5726 41.82 14.3939C41.752 14.2152 41.7218 14.0243 41.7312 13.8334V2.2237Z" fill="#404040"/>
        <path d="M58.6098 15.2867C58.4275 15.3046 58.2435 15.2844 58.0695 15.2273C57.8954 15.1701 57.7352 15.0774 57.599 14.9549C57.4628 14.8324 57.3536 14.6829 57.2784 14.5159C57.2032 14.3489 57.1636 14.1681 57.1621 13.9849V2.05538C57.1933 1.69526 57.3603 1.36046 57.629 1.11876C57.8978 0.877061 58.2484 0.746522 58.6098 0.753567H63.4187C73.0139 0.753567 72.8063 15.2867 63.6094 15.2867H58.6098ZM59.9341 3.31791V12.7224H63.4187C69.086 12.7224 69.3385 3.31791 63.4187 3.31791H59.9341Z" fill="#404040"/>
        <path d="M74.2709 15.2867C74.1013 15.2884 73.9332 15.2555 73.7766 15.1902C73.6201 15.1249 73.4785 15.0284 73.3604 14.9066C73.2424 14.7849 73.1503 14.6404 73.0897 14.4819C73.0292 14.3235 73.0016 14.1544 73.0084 13.9849V2.05538C72.9998 1.88405 73.0268 1.7128 73.0877 1.55244C73.1487 1.39209 73.2422 1.24612 73.3625 1.12377C73.4827 1.00142 73.627 0.905346 73.7863 0.841625C73.9456 0.777904 74.1163 0.747921 74.2878 0.753572H80.1908C82.9403 0.753572 84.4329 2.62212 84.4329 4.76562C84.4509 5.26465 84.3563 5.76133 84.1562 6.21884C83.9561 6.67635 83.6556 7.08295 83.277 7.40852C84.0006 7.65219 84.6254 8.12426 85.0575 8.75371C85.4896 9.38316 85.7056 10.1359 85.673 10.8987C85.673 13.3116 84.0345 15.2867 80.881 15.2867H74.2709ZM80.174 3.31791H75.7411V6.44338H80.174C81.0774 6.44338 81.6441 5.88226 81.6441 4.76001C81.6382 4.37452 81.4805 4.0069 81.2053 3.73693C80.9301 3.46695 80.5595 3.31639 80.174 3.31791ZM75.7411 12.7224H80.8642C83.2994 12.7224 83.2994 9.00772 80.8642 9.00772H75.7411V12.7224Z" fill="#404040"/>
        <path d="M86.2619 2.72874C85.1396 1.32031 87.5019 -0.295728 88.613 1.23614L92.4735 6.99329L96.4014 1.23614C97.5236 -0.295728 99.8018 1.23614 98.7132 2.64457L93.7978 9.63619V13.9625C93.9885 15.7861 91.0258 15.7861 91.0258 13.9625V9.63619L86.2619 2.72874Z" fill="#404040"/>
        </svg>
    )
}
