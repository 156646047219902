export default function SymbolHome() {
  return (
    <svg width="94" height="69" viewBox="0 0 94 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.846 17.3406C91.884 10.8954 87.7088 5.22394 81.6096 2.52503C81.5042 2.46224 81.3786 2.42078 81.2637 2.35798C80.4272 2.01203 79.547 1.70991 78.6489 1.47888L78.47 1.42675C70.8851 -0.487832 63.2161 2.35798 58.6228 8.12307C58.246 8.67754 57.8479 9.25334 57.3989 9.83861C57.3989 9.84928 57.3882 9.84927 57.3882 9.85994C57.3574 9.9014 57.3254 9.93221 57.2935 9.98552C52.5757 17.3927 45.8166 23.1459 37.99 26.6837C37.9592 26.6943 37.9272 26.7145 37.8964 26.7251C37.509 26.8934 37.1228 27.0592 36.7354 27.2168C36.8716 27.4786 37.0067 27.7191 37.1334 27.9597C38.6807 30.7853 38.7743 31.8824 39.9259 34.5505C40.7529 36.4663 46.3497 48.9372 58.6228 54.5968C65.5596 57.8194 72.4538 57.83 77.8006 56.9829C77.7485 57.0351 77.6964 57.0765 77.6336 57.1287C77.5601 57.1926 77.4866 57.2542 77.4132 57.3277C76.5258 58.0611 75.6112 58.7613 74.6728 59.4259C75.6112 58.7613 76.5258 58.0611 77.4132 57.3277C80.9189 54.4392 84.0052 51.0295 86.5679 47.1802C89.1732 43.2455 91.2347 38.8619 92.5842 34.0908L92.6469 33.829C93.9443 28.0426 93.9964 22.2574 92.846 17.3406Z"
        fill="#FFA4C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8004 56.9828C72.4535 57.8299 65.5594 57.8193 58.6226 54.5967C46.3495 48.9371 40.7527 36.4662 39.9257 34.5504C38.7753 31.8823 38.6805 30.7852 37.1332 27.9596C37.0065 27.719 36.8714 27.4785 36.7351 27.2167C33.5126 28.5673 30.1123 29.5294 26.6077 30.0945C26.5971 30.104 26.5864 30.104 26.5769 30.1147C21.5855 31.2662 16.2801 31.3492 10.9771 30.1774C9.7627 30.0199 8.5803 29.7912 7.41923 29.4761C7.40857 29.4761 7.38724 29.4654 7.37776 29.4654C7.35644 29.4559 7.32563 29.4453 7.30431 29.4346C4.7926 28.4726 2.59604 27.2688 0.722917 25.9822C0.493072 27.0591 0.367486 28.1373 0.335498 29.2154C0.324835 29.9997 0.356824 30.7639 0.430279 31.5281C0.440942 31.5802 0.440942 31.6217 0.451605 31.6631C0.661309 32.9285 0.943284 34.1855 1.27739 35.42C4.88738 48.3518 15.5906 59.6924 30.29 64.9445C30.3327 64.9646 30.3741 64.9765 30.4156 64.9978C32.278 65.5831 33.9628 66.0013 35.407 66.2939C35.7719 66.3982 36.1392 66.493 36.5148 66.5866C39.8428 67.4242 43.1601 67.8732 46.455 67.9787C47.4798 68.0107 48.4952 68.0107 49.5093 67.968C49.9287 67.9585 50.347 67.9372 50.7664 67.9064C51.2474 67.8839 51.7284 67.8424 52.2094 67.7903C61.5324 66.9005 70.3103 63.1969 77.413 57.3276C77.4864 57.2541 77.5599 57.1925 77.6334 57.1286C77.6962 57.0764 77.7483 57.035 77.8004 56.9828Z"
        fill="#18BCF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.37729 29.466C7.38796 29.466 7.4081 29.4766 7.41876 29.4766C8.58102 29.7906 9.76342 30.0204 10.9766 30.178C16.2808 31.3497 21.585 31.2668 26.5765 30.1152C26.5871 30.1046 26.5978 30.1046 26.6073 30.0939C30.1118 29.5299 33.5133 28.5667 36.7347 27.2173C36.5688 26.914 36.3792 26.5882 36.1909 26.2434C33.89 22.2685 29.8097 16.283 22.7473 14.2227C22.0459 14.0024 11.7408 11.1566 5.1286 17.6751C4.19619 18.5957 3.38107 19.6632 2.70101 20.8243C2.36572 21.3989 2.06242 22.0173 1.78992 22.6441C1.31957 23.7329 0.964141 24.8513 0.723633 25.9827C2.59675 27.2694 4.79213 28.4719 7.30384 29.4352C7.32516 29.4446 7.35597 29.4553 7.37729 29.466Z"
        fill="#18C9F3"
      />
    </svg>
  );
}
