export default function EmojiIcon() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_615:16968)">
          <path
            d="M11.6538 0.375C5.21604 0.375 0 5.57812 0 12C0 18.4219 5.21604 23.625 11.6538 23.625C18.0917 23.625 23.3077 18.4219 23.3077 12C23.3077 5.57812 18.0917 0.375 11.6538 0.375ZM11.6538 21.375C6.4707 21.375 2.25558 17.1703 2.25558 12C2.25558 6.82969 6.4707 2.625 11.6538 2.625C16.837 2.625 21.0521 6.82969 21.0521 12C21.0521 17.1703 16.837 21.375 11.6538 21.375ZM7.89454 11.25C8.72629 11.25 9.39826 10.5797 9.39826 9.75C9.39826 8.92031 8.72629 8.25 7.89454 8.25C7.06279 8.25 6.39082 8.92031 6.39082 9.75C6.39082 10.5797 7.06279 11.25 7.89454 11.25ZM15.4132 11.25C16.2449 11.25 16.9169 10.5797 16.9169 9.75C16.9169 8.92031 16.2449 8.25 15.4132 8.25C14.5814 8.25 13.9094 8.92031 13.9094 9.75C13.9094 10.5797 14.5814 11.25 15.4132 11.25ZM15.6011 14.6531C14.6237 15.825 13.1811 16.5 11.6538 16.5C10.1266 16.5 8.684 15.8297 7.70658 14.6531C7.30715 14.175 6.59288 14.1141 6.11827 14.5078C5.63896 14.9062 5.57787 15.6141 5.9726 16.0922C7.38234 17.7797 9.45465 18.7453 11.6538 18.7453C13.853 18.7453 15.9254 17.7797 17.3351 16.0922C17.7345 15.6141 17.6687 14.9062 17.1894 14.5078C16.7148 14.1141 16.0005 14.175 15.6011 14.6531Z"
            fill="#A8A8A8"
          />
        </g>
        <defs>
          <clipPath id="clip0_615:16968">
            <rect width="23.3077" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
