// eslint-disable-next-line react-hooks/exhaustive-deps
import { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Input, Button, Typography, FormInstance } from 'antd';
import styles from './login.module.scss';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Footer } from 'antd/lib/layout/layout';
import { storage } from 'utils';
import { ILoginAcessCode, postAcessCode } from '../../api';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { showError } from 'components/common/standby-notice';
import SymbolAuth from 'components/common/icon-svg/symbolAuth';
import './login.scss';
import ModalTerm from 'components/Layout/components/SiderBar/ModalTerm';
import { API_URL } from 'config';
import { get } from 'lodash';
import { EType } from 'components/Layout/components/SiderBar/types';
import { removeAllData } from 'lib';

const { Title } = Typography;
let modalTermVisible;

function LoginPage() {
  const { t } = useTranslation();
  const textInput = useRef<FormInstance>(null);
  const location: any = useLocation();
  const locationPathName: string = location?.pathname;
  const token = storage.getToken();
  const client_id: string = storage.getClientId();
  const [infoLogin, setInfoLogin] = useState<ILoginAcessCode>();
  const history = useHistory();

  useEffect(() => {
    storage.setFirtTime(true);
    storage.setIsCallDeactive('false');
    check();
  }, []);
  const check = () => {
    if (token) {
      history.push({
        pathname: '/',
      });
    }
    setInfoLogin({ ...infoLogin, platform: 'WEB' });

    if (location?.pathname === '/auth/login') {
      storage.clearCurrentState();
    }
  };

  const onFinish = ({ access_code }) => {
    if (client_id) {
      const data = {
        ...infoLogin,
        access_code: access_code.replace(/\s+/g, ''),
        client_id: client_id,
      };
      postAcessCode(data)
        .then((res: any) => {
          const curentState = {
            list: [{ ...data }],
          };

          storage.setCurrentState(curentState);

          if (res.data?.challenger_name === 'TERMS') {
            history.push({
              pathname: '/auth/term',
              state: {
                ...data,
                data: res.data,
                choice_name: res?.data?.organization_name,
              },
            });
          } else {
            if (!storage.getCurrentState()) {
              history.push({
                pathname: '/auth/select?step=1',
                state: {
                  ...data,
                  data: res.data,
                  choice_name: res?.data?.organization_name,
                },
              });
            } else {
              history.push({
                pathname: '/auth/select?step=1',
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.status !== 503) {
            showError(t(`errors.code.${err?.data?.error_code}`));
          }
          removeAllData();
        });
    } else {
      const data = {
        ...infoLogin,
        access_code: access_code,
      };
      postAcessCode(data)
        .then((res: any) => {
          const curentState = {
            list: [{ ...data, pathname: locationPathName, name: res?.data?.organization_name }],
          };

          storage.setCurrentState(curentState);

          if (res.data?.challenger_name === 'TERMS') {
            history.push({
              pathname: '/auth/term?step=1',
              state: {
                ...data,
                data: res.data,
                choice_name: res?.data?.organization_name,
              },
            });
          } else {
            history.push({
              pathname: '/auth/select?step=1',
              state: {
                ...data,
                data: res.data,
                choice_name: res?.data?.organization_name,
              },
            });
          }
        })
        .catch((err) => {
          if (err?.status !== 503) {
            showError(t(`errors.code.${err?.data?.error_code}`));
          }
          removeAllData();
        });
    }
  };

  const handleShowModalPDF = (type: string) => {
    getPreviewPDF(type);
  };

  const getPreviewPDF = async (type: string) => {
    const query = {
      platform: 'WEB_APP',
      category: type,
    };
    try {
      const result = await axios.get(API_URL + '/terms', { params: query });
      const url = get(result, 'data.data.0.url');
      if (url) {
        modalTermVisible?.({
          visible: true,
          type,
          url,
        });
      }
    } catch (error) {
      showError(t('file.preview.error'));
    }
  };

  return (
    <>
      <HeaderComponent />
      <Content className={styles.layout_container}>
        <Row className={styles.container}>
          <Col className={styles.card_input} xs={20} sm={16} md={12} lg={12}>
            <div className={styles.card_image}>
              <div className={styles.top}>
                <img className={styles.top_image} src="/image/background.png" alt="" />
              </div>
              <div className={styles.logo}>
                <SymbolAuth />
                {t('STANDBY')}
              </div>
            </div>
            <Form className={styles.form_input} onFinish={onFinish} ref={textInput}>
              <Form.Item
                name="access_code"
                className={styles.form_item}
                validateTrigger={'onSubmit'}
                rules={[
                  {
                    required: true,
                    message: t('login.input.access_code.required'),
                  },
                  {
                    min: 8,
                    message: t('login.input.access_code.invalid'),
                  },
                  {
                    max: 20,
                    message: t('login.input.access_code.invalid'),
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  allowClear
                  placeholder={t('login.access_code.placeholder')}
                />
              </Form.Item>
              <Form.Item className={styles.form_item}>
                <Button type="primary" htmlType="submit" className={styles.btn}>
                  {t('login.next')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Footer className="footer" style={{ backgroundColor: 'transparent' }}>
          {t('sider.term-standby')}
          <Title level={5} className="footer-title" onClick={() => handleShowModalPDF(EType.TERM)}>
            {t('footer.term_of_use')}
          </Title>
        </Footer>
      </Content>
      <ModalTerm onRef={(ref) => (modalTermVisible = ref)} />
    </>
  );
}

export default LoginPage;
