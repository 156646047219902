export default function UnlockIcon() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2957:31973)">
          <path
            d="M17.292 8.75H16.3545V5.9375C16.3545 2.66406 13.6904 0 10.417 0C7.14355 0 4.47949 2.66406 4.47949 5.9375V8.75H3.54199C2.50684 8.75 1.66699 9.58984 1.66699 10.625V18.125C1.66699 19.1602 2.50684 20 3.54199 20H17.292C18.3271 20 19.167 19.1602 19.167 18.125V10.625C19.167 9.58984 18.3271 8.75 17.292 8.75ZM13.2295 8.75H7.60449V5.9375C7.60449 4.38672 8.86621 3.125 10.417 3.125C11.9678 3.125 13.2295 4.38672 13.2295 5.9375V8.75Z"
            fill="#34C759"
          />
        </g>
        <defs>
          <clipPath id="clip0_2957:31973">
            <rect width="17.5" height="20" fill="white" transform="translate(1.66699)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
