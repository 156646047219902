import { Card, Col, Image, notification, Row, Typography, Space, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import './sos.scss';
import { useCallback, useEffect, useState } from 'react';
import { getInfo } from '../api';
import { showError } from 'components/common/standby-notice';
import SupportIcon from 'components/common/icon-svg/support';
import LinkIcon from 'components/common/icon-svg/link';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WhiteBox from 'components/common/icon-svg/whiteBox';

const { Text } = Typography;

type ListSOS = [
  {
    title: string;
    type: string;
    value: string;
  }
];
const SOS = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ListSOS>();
  const GetInfo = useCallback(() => {
    getInfo()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    GetInfo();
  }, [GetInfo]);

  return (
    <>
      <div className="wrapper sos">
        {Array.isArray(data) && data.length <= 0 ? (
          <Row className="row-nodata">
            <Col span={24} className="col_notfound">
              <WhiteBox />
              <Text className="notfound_text">{t('sos.empty_data')}</Text>
            </Col>
          </Row>
        ) : (
          <Row gutter={14} className="row">
            {data?.map((item, index) => {
              return (
                <Col key={index} span={8} sm={24} lg={12} xl={8} xxl={6} className="col">
                  <Card
                    title={
                      <>
                        <Tooltip placement="topLeft" title={item?.title}>
                          {item?.title}
                        </Tooltip>
                      </>
                    }
                    bordered={false}
                    className="card"
                  >
                    {item.type === 'URL' ? (
                      <Meta
                        description={
                          <Space className="space_sos space">
                            <LinkIcon />
                            <Tooltip placement="topLeft" title={item?.value}>
                              <a
                                href="#i"
                                onClick={() => {
                                  let url = item?.value.indexOf("http") === 0 ? item?.value : `https://${item?.value}`;
                                  window.open(url,"_blank");
                                }}
                              >
                                {item?.value}
                              </a>
                            </Tooltip>
                          </Space>
                        }
                      />
                    ) : (
                      <Meta
                        description={
                          <Space className="space">
                            <SupportIcon />
                            <Tooltip placement="topLeft" title={item?.value}>
                              <Link to="#" className="sos_phone">
                                {item?.value}
                              </Link>
                            </Tooltip>
                          </Space>
                        }
                      />
                    )}
                  </Card>
                </Col>
              );
            })}

          </Row>)}
      </div>
    </>
  );
};

export default SOS;
