// eslint-disable-next-line react-hooks/exhaustive-deps

import { Button, Row, Space, Typography } from 'antd';
import SymbolHome from 'components/common/icon-svg/symbolHome';
import ModalGlobal from 'components/common/showModal/modalGlobal';
import { ApiPassCode } from 'features/passcode/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { storage } from 'utils';
import './index.scss';
import { getMessaging, getToken } from 'firebase/messaging';
import { update_token_device } from 'features/notification/api';
import { VAPIDKEY } from 'config';

const { Title, Text } = Typography;

interface IHomeProps {
  info: any;
}
const HomePage = ({ info }: IHomeProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const location: any = useLocation();
  const firstTime = storage.getFirstTime();
  const locationState = location.state;

  useEffect(() => {
    if (firstTime && !locationState?.forceRemove) {
      handleCallPopup();
    } else {
      if (locationState?.action === 'REMOVE') return setIsModalVisible(true);
    }
  }, [firstTime]);

  const getTokenFirebase = async () => {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: VAPIDKEY })
      .then(async (currentToken) => {
        console.log('currentToken:', currentToken);
        await update_token_device({ token_device: currentToken });
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  const requestPermission = async () => {
    if (Notification.permission === 'granted') {
      console.log('Notification permission granted.');
      await getTokenFirebase();
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          await getTokenFirebase();
        } else {
          console.log('Unable to get permission to notify:', permission);
        }
      });
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    storage.setKeyMenu('1');
  }, []);

  useEffect(() => {
    callPasscode();
  }, []);

  const callPasscode = async () => {
    const data = {
      action: 'STATUS',
      confirm_passcode: '',
      current_passcode: '',
      new_passcode: '',
    };
    const result = await ApiPassCode(data);
    if (result && result.data) {
      storage.setCurentPasscodeEnable(result?.data?.passcode_enabled);
    }
  };

  const handleCallPopup = async () => {
    const data = {
      action: 'STATUS',
      confirm_passcode: '',
      current_passcode: '',
      new_passcode: '',
    };

    const result = await ApiPassCode(data);

    storage.setCurentPasscodeEnable(result?.data?.passcode_enabled);
    if (!result?.data?.passcode_enabled) {
      setIsModalVisible(true);
      storage.setFirtTime(false);
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
    storage.setFirtTime(false);
    if (locationState?.action === 'REMOVE') {
      history.push({
        pathname: '/passcode/change',
        state: { action: 'REMOVE' },
      });
    } else {
      history.push({
        pathname: '/passcode/info',
      });
    }
  };
  const handleCancel = () => {
    history.push({
      pathname: '/',
    });
    setIsModalVisible(false);
  };

  const goReport = () => {
    history.push({ pathname: '/report' });
  };
  const goReportTest = () => {
    history.push({ pathname: '/report-test' });
  };

  const handleChangeKeyMenu = () => {
    storage.setKeyMenu('3');
  };

  const is_created_incident_test = Boolean(storage.getKeyCreateIncidentTest());

  return (
    <Row className="home_component">
      <ModalGlobal
        visible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contentText={
          locationState?.action === 'REMOVE'
            ? t(`passcode.remove_confirm.title`)
            : t(`home.suggestion.title`)
        }
        type=""
        text={
          locationState?.action === 'REMOVE'
            ? t('passcode.remove_confirm.ok')
            : t('home.suggestion.ok')
        }
        textCancel={
          locationState?.action === 'REMOVE'
            ? t('passcode.remove_confirm.cancel')
            : t('home.suggestion.cancel')
        }
      />
      <div className="card_image">
        <img className="top_image" src="/image/background.png" alt="" />
        <div className="logo">
          <div className="Oval" onClick={goReport}>
            <img className="img-oval" src="/image/Oval.png" alt="" />
            <div className="logo_image">
              <Space direction="vertical" className="space-logo" size={16}>
                <SymbolHome />
                {t('home.button.report')}
              </Space>
            </div>
          </div>
          {!is_created_incident_test ? (
            <Button onClick={goReportTest} type="primary" className="btn-insident-test" block>
              {t('btn_text_create_survey_homepage')}
            </Button>
          ) : (
            <div className="btn-insident-test-success">
              <svg
                style={{ cursor: 'auto' }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99935 1.6665C5.40768 1.6665 1.66602 5.40817 1.66602 9.99984C1.66602 14.5915 5.40768 18.3332 9.99935 18.3332C14.591 18.3332 18.3327 14.5915 18.3327 9.99984C18.3327 5.40817 14.591 1.6665 9.99935 1.6665ZM13.9827 8.08317L9.25768 12.8082C9.14102 12.9248 8.98268 12.9915 8.81602 12.9915C8.64935 12.9915 8.49102 12.9248 8.37435 12.8082L6.01602 10.4498C5.77435 10.2082 5.77435 9.80817 6.01602 9.5665C6.25768 9.32484 6.65768 9.32484 6.89935 9.5665L8.81602 11.4832L13.0993 7.19984C13.341 6.95817 13.741 6.95817 13.9827 7.19984C14.2243 7.4415 14.2243 7.83317 13.9827 8.08317Z"
                  fill="#777777"
                />
              </svg>
              {t('btn_text_create_survey_homepage_success')}
            </div>
          )}
        </div>
      </div>
      <div className="content">
        <div className="cardbottom">
          {info?.organization_name && (
            <Title className="cardbottom_title">{info?.organization_name}</Title>
          )}
          {info?.home_message && info?.working_time ? (
            <Text className="cardbottom_text">{info?.home_message}</Text>
          ) : (
            <div className="cardbottom_text_without_time">
              <Text className="cardbottom_text">{info?.home_message}</Text>{' '}
            </div>
          )}
          {info?.working_time && <Text className="cardbottom_button">{info?.working_time}</Text>}
          {info?.working_time ? (
            <Link
              to="/SOS"
              onClick={handleChangeKeyMenu}
              style={{ textDecoration: 'underline', color: '#777777' }}
            >
              {t(`home.sos_link`)}
            </Link>
          ) : (
            <Link
              to="/SOS"
              onClick={handleChangeKeyMenu}
              style={{ textDecoration: 'underline', color: '#777777', marginTop: '15px' }}
            >
              {t(`home.sos_link`)}
            </Link>
          )}
        </div>
      </div>
    </Row>
  );
};

export default HomePage;
