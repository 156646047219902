import { Checkbox, Form } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  listCheckBox: any;
  answerCheckBox: (e, data) => void;
}
export default function ListCheckBox({ listCheckBox, answerCheckBox }: IProps) {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        className={listCheckBox?.is_required ? 'checkbox checkbox-required' : 'before'}
        name={listCheckBox?._id}
        valuePropName="checked"
        rules={[
          {
            required: listCheckBox?.is_required,
            message: t('report.new.question_lists.required'),
          },
        ]}
      >
        <Checkbox onChange={(e) => answerCheckBox(e, listCheckBox)}>
          {listCheckBox?.question_name}
        </Checkbox>
      </Form.Item>
    </>
  );
}
