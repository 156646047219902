export default function AttchFileIcon() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_334:11590)">
          <path
            d="M2.35143 14.566C0.525492 12.6819 0.559523 9.64376 2.39475 7.76657L8.94976 1.06154C10.3346 -0.355086 12.5857 -0.355211 13.9707 1.06154C15.3423 2.46448 15.344 4.72807 13.9707 6.13266L8.2567 11.9718C7.32373 12.9261 5.79941 12.9128 4.88225 11.9406C3.99866 11.004 4.027 9.51957 4.92763 8.59832L9.41961 4.00973C9.61279 3.81241 9.92936 3.80904 10.1267 4.0022L10.8411 4.70154C11.0384 4.89473 11.0418 5.21129 10.8486 5.4086L6.3571 9.99676C6.20297 10.1544 6.19347 10.4164 6.33685 10.5684C6.47347 10.7132 6.68825 10.7156 6.8271 10.5735L12.5411 4.73445C13.1541 4.10751 13.1541 3.08679 12.5408 2.45951C11.9411 1.84616 10.9796 1.84585 10.3797 2.45951L3.82469 9.16451C2.73834 10.2758 2.72159 12.0745 3.78747 13.1743C4.85028 14.2709 6.56825 14.2723 7.63304 13.1832L13.0099 7.68326C13.203 7.48579 13.5195 7.48223 13.717 7.67526L14.4319 8.37413C14.6294 8.56716 14.633 8.88373 14.4399 9.08119L9.06301 14.5811C7.20126 16.4854 4.18978 16.4629 2.35143 14.566Z"
            fill="#404040"
          />
        </g>
        <defs>
          <clipPath id="clip0_334:11590">
            <rect width="14" height="16" fill="white" transform="translate(1)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
