import './index.scss';
import { Badge, Button, Layout, Menu, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearAll, storage } from 'utils';
import { useState } from 'react';
import { LogOut } from 'features/auth/api';
import ModalGlobal from 'components/common/showModal/modalGlobal';
import ModalTerm from './ModalTerm';
import axios from 'axios';
import { showError } from 'components/common/standby-notice';
import HomeIcon from 'components/common/icon-svg/home';
import ChatIcon from 'components/common/icon-svg/chat';
import SosIcon from 'components/common/icon-svg/sos';
import ContentIcon from 'components/common/icon-svg/content';
import ReportIcon from 'components/common/icon-svg/report';
import LogOutIcon from 'components/common/icon-svg/logout';
import { API_URL } from 'config';
import { get } from 'lodash';
import { EType } from './types';
const { Sider } = Layout;
const { Title, Text } = Typography;

interface ISiderProps {
  newMessage: number;
  numberContent: number;
  keyRoute: string;
}

let modalTermVisible;
const SiderBar = ({ newMessage, numberContent, keyRoute }: ISiderProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setisModalVisible] = useState(false);
  const passcode_enabled: boolean = storage.getCurentPasscodeEnable();
  const history = useHistory();
  const ShowModal = () => {
    setisModalVisible(true);
  };

  const handleShowModalPDF = (type: string) => {
    getPreviewPDF(type);
  };

  const handleOk = () => {
    LogOut()
      .then((res) => {
        clearAll(() => {
          return window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const handleCancel = () => {
    setisModalVisible(false);
  };

  const getPreviewPDF = async (type: string) => {
    const query = {
      platform: 'WEB_APP',
      category: type,
    };
    try {
      const result = await axios.get(API_URL + '/terms', { params: query });
      const url = get(result, 'data.data.0.url');
      if (url) {
        modalTermVisible?.({
          visible: true,
          type,
          url,
        });
      }
    } catch (error) {
      showError(t('file.preview.error'));
    }
  };

  const handleClick = () => {
    if (passcode_enabled === false) {
      history.push('/message');
    } else {
      history.push('/message/check');
    }
  };
  const handleClickGoToReport = () => {
    history.push('/report');
  };

  return (
    <>
      <Sider className="sider_component">
        <ModalGlobal
          visible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          contentText={t('logout.title')}
          type="logout"
          textCancel={t('logout.cancel')}
          text={t('logout.ok')}
        />
        <Menu mode="inline" className="sider_menu">
          <Menu.Item key="1" className={`fisrt-child ${keyRoute === '1' ? 'menu-active' : ''}`}>
            <Link to="/">
              <HomeIcon />
              {t('sidebar.top')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={handleClick}
            className={`${keyRoute === '2' ? 'menu-active' : ''}`}
          >
            <div className="chat">
              <Badge count={newMessage} color="#ff3b30" offset={[-45, 11]}>
                <ChatIcon />
                {t('sidebar.message')}
              </Badge>
            </div>
          </Menu.Item>
          <Menu.Item key="3" className={`${keyRoute === '3' ? 'menu-active' : ''}`}>
            <Link to="/SOS">
              <SosIcon />
              {t('sidebar.sos')}
            </Link>
          </Menu.Item>
          <Menu.Item key="4" className={`${keyRoute === '4' ? 'menu-active' : ''}`}>
            <Link to="/content" className="content-link">
              <div className="chat">
                <Badge count={numberContent} color="#ff3b30" offset={[-45, 11]}>
                  <ContentIcon />
                  {t('sidebar.content')}
                </Badge>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="5" onClick={handleClickGoToReport}>
            <Button className="btn" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 0 }}>
                <ReportIcon />
                <Title className="title" style={{ marginBottom: 0 }}>
                  {t('sidebar.report')}
                </Title>
              </div>
            </Button>
          </Menu.Item>
          <Menu.Item key="6" onClick={ShowModal}>
            <div className="logout">
              <LogOutIcon />
              {t('sidebar.logout')}
            </div>
          </Menu.Item>
          <Menu.Item key="7" className="last-child">
            <div className="container-term">
              <Text className="term-title"> {t('sider.term-standby')}</Text>
              <Text className="term-title">{t('sidebar.version', { version: `2.0.3` })}</Text>
            </div>
            <Text className="term-user" onClick={() => handleShowModalPDF(EType.TERM)}>
              {t('sidebar.terms_of_use')}
            </Text>
            <Text className="term-user" onClick={() => handleShowModalPDF(EType.PRIVACY)}>
              {t('sidebar.policy')}
            </Text>
          </Menu.Item>
        </Menu>
        <ModalTerm onRef={(ref) => (modalTermVisible = ref)} />
      </Sider>
    </>
  );
};

export default SiderBar;
