import { fork, put, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { authActions, IPayloadBlockUser } from './authSlice';

function* blockUser(payload: IPayloadBlockUser) {
  try {
    yield put(authActions.blockUser(payload));
  } catch (error) {}
}

function* watchingBlockUser() {
  const action: PayloadAction<IPayloadBlockUser> = yield take(authActions.blockUser.type);
  yield fork(blockUser, action.payload);
}

export default function* authSaga() {
  yield fork(watchingBlockUser);
}
