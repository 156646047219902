// eslint-disable-next-line react-hooks/exhaustive-deps
import { useState, useRef } from 'react';
import _ from 'lodash';
import { Spin, Row, Col, Form, Input, Button, Typography, FormInstance, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { LeftOutlined } from '@ant-design/icons';
import { showSuccess } from 'components/common/standby-notice';
import './index.scss';
import ModalGlobal from 'components/common/showModal/modalGlobal';
import { storage } from 'utils';
import { SubmitIncidentTest } from 'features/incident-test/api';

const { Title, Text } = Typography;
const { TextArea } = Input;

export interface incidentReportTestSubmit {
  org_id: string | null;
  description: string;
}

export interface IListQuestion {
  visibility_custom: boolean;
  _id: string;
  question_name: string;
  question_type: string;
  answer_value: any[];
  is_required: boolean;
}
function ReportTestPage() {
  const { t } = useTranslation();
  const ref = useRef<FormInstance>(null);
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoadingSubmitIncident, setIsLoadingSubmitIncident] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>('');
  const [visiable, setVisiable] = useState<boolean>(false);

  const [incidentReport, setIncidentReprot] = useState<incidentReportTestSubmit>({
    org_id: null,
    description: '',
  });
  const user = storage.getCurentOrganization();

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setTypeModal('submit');
    setIsLoadingSubmitIncident(false);
  };

  const onFinish = (values) => {
    const valueSend = {
      ...incidentReport,
    };

    setIsLoadingSubmitIncident(true);

    SubmitIncidentTest(valueSend)
      .then((res) => {
        setTimeout(() => {
          setIsLoadingSubmitIncident(false);
        }, 1000);
        if (res) {
          storage.setKeyCreateIncidentTest();
          showSuccess(t('report.finish.title'));
          if (res.data.message) {
            history.push({
              pathname: '/report-test-success',
              state: { message: res.data.message },
            });
          } else {
            history.push({
              pathname: '/report-test-success',
            });
          }
        }
      })
      .catch((err) => {
        setIsLoadingSubmitIncident(false);
      });
  };

  const handleGoback = () => {
    setTypeModal('back');
    setVisiable(true);
  };

  const handleOk = () => {
    setIsLoadingUpload(true);

    if (typeModal === 'submit') {
      setVisiable(false);
      setIsLoadingSubmitIncident(false);
    } else if (typeModal === 'remove') {
      setIncidentReprot({ ...incidentReport });

      setTimeout(() => {
        setIsLoadingUpload(false);
      }, 500);
      setVisiable(false);
    } else {
      history.push({
        pathname: '/',
      });
    }
  };
  const handleCancel = () => {
    setVisiable(false);
  };

  return (
    <>
      <HeaderComponent />
      <ModalGlobal
        visible={visiable}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contentText={
          typeModal === 'back'
            ? t(`report.leave.title`)
            : typeModal === 'remove'
            ? t(`report.remove_files.title`)
            : t(`report.new.question_lists.required`)
        }
        type={typeModal}
        text={
          typeModal === 'back'
            ? t(`report.leave.ok`)
            : typeModal === 'remove'
            ? t(`report.remove_files.delete`)
            : t(`report.leave.ok`)
        }
        textCancel={
          typeModal === 'back' ? t(`report.leave.cancel`) : t(`report.remove_files.cancel`)
        }
      />
      <Content className="Incident-test">
        <Spin spinning={isLoadingSubmitIncident}>
          <Row className="row">
            <Col className="col" xs={20} sm={16} md={12} lg={12}>
              <Text
                className="title"
                style={{ padding: '16px 0', cursor: 'pointer' }}
                onClick={handleGoback}
              >
                <LeftOutlined style={{ marginRight: '10px' }} />
                {t('report.leave_btn')}
              </Text>
              <div className="container">
                <div
                  style={{
                    backgroundImage: `url('/image/bg-insident-test.png')`,
                    height: 116,
                    padding: 20,
                  }}
                >
                  <Title level={5} style={{ fontSize: 18 }} className="report_title">
                    テスト送信しよう
                  </Title>
                  <Text>STANDBYの使い方をテストするためのページです。</Text> <br />
                  <Text>このページで送信した内容は相談員には届きません。</Text>
                </div>
                <div className="body">
                  <Title level={5} className="report_title">
                    {user?.organization_name}
                  </Title>
                  <Form
                    className="form"
                    ref={ref}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    autoComplete={'off'}
                  >
                    <Title level={5} className="report_message" style={{ paddingBottom: 12 }}>
                      {t('report.new.content.title')}
                    </Title>
                    <Form.Item
                      name="description"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          message: t('report.new.description.required'),
                        },
                        {
                          whitespace: true,
                          message: t('report.new.description.whitespace'),
                        },
                      ]}
                      validateTrigger={'onSubmit'}
                    >
                      <TextArea
                        maxLength={12000}
                        className="input-area"
                        placeholder={t('「テスト」と書いて送信しよう！')}
                        style={{ minHeight: 118 }}
                        onChange={(e) => {
                          form.setFields([
                            {
                              name: 'description',
                              errors: [],
                            },
                          ]);
                          setIncidentReprot({
                            ...incidentReport,
                            description: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item className="item-btn">
                      <Button
                        loading={isLoadingUpload}
                        type="primary"
                        htmlType="submit"
                        className="btn-finish"
                      >
                        {t('テスト送信する')}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </Content>
    </>
  );
}
export default ReportTestPage;
