
export default function Symbol() {
    return (
            <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.8127 7.81649C43.5881 6.2941 42.9828 4.85304 42.0529 3.62691C41.123 2.40078 39.8986 1.42926 38.4932 0.80242L38.3305 0.723863C37.9275 0.556183 37.5131 0.417421 37.0904 0.30863L37.0343 0.280574C35.3246 -0.156732 33.5249 -0.0901012 31.8523 0.472432C30.1796 1.03497 28.7054 2.06938 27.6074 3.45093C27.4278 3.71466 27.2371 4.01206 27.0463 4.26456C27.0283 4.28067 27.0131 4.29963 27.0014 4.32068C24.786 7.79051 21.6077 10.5398 17.855 12.2325C17.8402 12.2357 17.8249 12.2357 17.8101 12.2325C17.6306 12.3167 17.4454 12.3953 17.249 12.4682L17.4398 12.8217C17.9485 13.8318 18.3909 14.874 18.7641 15.9416C19.1568 16.8506 21.8054 22.7536 27.6186 25.4358C30.4659 26.712 33.631 27.103 36.7032 26.5581L36.6191 26.631L36.5181 26.7208C36.0972 27.0687 35.6652 27.3998 35.2219 27.7196C35.6652 27.3998 36.0972 27.0687 36.5181 26.7208C39.9807 23.8733 42.4874 20.033 43.7005 15.7171L43.7341 15.5937C44.3343 13.0389 44.3611 10.3829 43.8127 7.81649Z" fill="#FFA4C9"/>
            <path d="M36.6919 26.5917C33.6196 27.1366 30.4545 26.7456 27.6072 25.4695C21.794 22.7817 19.1455 16.8786 18.7527 15.9696C18.3795 14.902 17.9372 13.8598 17.4284 12.8498L17.2377 12.4963C15.6994 13.1375 14.091 13.5952 12.4456 13.8598C10.0142 14.424 7.48734 14.4374 5.05001 13.8991C4.48218 13.8261 3.91982 13.7155 3.36664 13.568H3.33297C2.22182 13.1428 1.16511 12.5872 0.185058 11.9127C0.0751874 12.4239 0.0131956 12.9443 -0.000112891 13.467C-0.00488211 13.8323 0.0101031 14.1975 0.0447771 14.5612C0.0447771 14.5612 0.0447771 14.6061 0.0447771 14.6229C0.144418 15.2223 0.275528 15.8161 0.437565 16.4017C2.12094 22.5292 7.21596 27.8991 14.1739 30.3849H14.2356C15.0116 30.6308 15.8002 30.8349 16.598 30.9966L17.1254 31.1368C18.666 31.5273 20.2447 31.7474 21.8333 31.7934C22.3158 31.7934 22.7984 31.7934 23.281 31.7934L23.8758 31.7597L24.5603 31.7092C28.9443 31.2825 33.1006 29.5551 36.4955 26.7488L36.5965 26.6591L36.6919 26.5917Z" fill="#18BCF3"/>
            <path d="M3.33275 13.5624H3.36641C3.9196 13.7099 4.48196 13.8205 5.04979 13.8935C7.48447 14.4346 10.0093 14.4251 12.4398 13.8654C14.0852 13.6008 15.6936 13.1431 17.2318 12.5019C17.1533 12.356 17.0635 12.2045 16.9793 12.0418C15.8907 10.1508 13.9605 7.29465 10.6162 6.34074C10.2851 6.23973 5.4033 4.88742 2.27222 7.97922C1.83333 8.42247 1.45588 8.92259 1.14997 9.4662C0.988127 9.74499 0.843825 10.0336 0.717903 10.3303C0.495841 10.8391 0.326616 11.3694 0.212891 11.9127C1.18347 12.577 2.22871 13.1251 3.32713 13.5456L3.33275 13.5624Z" fill="#18C9F3"/>
            </svg>
    )
}
