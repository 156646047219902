import { Card, Col, Row, Typography, Badge } from 'antd';
import { showError } from 'components/common/standby-notice';
import _ from 'lodash';
import { getCountUnReads, IQueryGetCountUnRead } from 'features/content/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './content.scss';
import { storage } from 'utils';
import { initialNotification, onReceiveNotice, onReceiveNotificationBroadCast } from 'lib/socket';
import ContentListComponent from './components/ContentList';
import { randomStr } from 'utils/common';

export enum ETypeQuery {
  ARTICLE = 'ARTICLE',
  BROADCAST = 'BROADCAST',
  NOTICE = 'NOTICE',
}

interface IContentProps {
  keySearch: any;
  setKeySearch: (x: string) => void;
}
const ContentPage = ({ keySearch, setKeySearch }: IContentProps) => {
  const { t } = useTranslation();

  const column = [
    {
      type: ETypeQuery.ARTICLE,
      title: t('content.content'),
    },
    {
      type: ETypeQuery.BROADCAST,
      title: t('content.broadcast'),
    },
    {
      type: ETypeQuery.NOTICE,
      title: t('content.notice'),
    },
  ];
  const [numberUnreadBroadCast, setNumberUnreadBroadCast] = useState<number>(0);
  const [numberUnreadNotice, setNumberUnreadNotice] = useState<number>(0);
  const [isFetch, setIsFetch] = useState<string>('');

  const client_id: string = storage.getClientId();

  useEffect(() => {
    getCountNumberUnReadBroadCast();
    getCountNumberUnReadNotice();
    setKeySearch('');
  }, []);

  useEffect(() => {
    if (client_id) {
      initialBroadCast().then(() => {
        onReceiveNotificationBroadCast((msg: any) => {
          if (msg) {
            getCountNumberUnReadBroadCast();
            setIsFetch(randomStr(10));
          }
        });
        onReceiveNotice((msgNotice: any) => {
          if (msgNotice) {
            getCountNumberUnReadNotice();
            setIsFetch(randomStr(10));
          }
        });
      });
    }
  }, []);

  const initialBroadCast = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();
      if (client_id && token) {
        initialNotification(token);
      }
      resolve();
    });

  const getCountNumberUnReadBroadCast = () => {
    const query: IQueryGetCountUnRead = {
      type: ETypeQuery.BROADCAST,
    };
    getCountUnReads(query)
      .then((res) => {
        setNumberUnreadBroadCast(res.data.unreads);
      })
      .catch((err) => {});
  };

  const getCountNumberUnReadNotice = () => {
    const query: IQueryGetCountUnRead = {
      type: ETypeQuery.NOTICE,
    };
    getCountUnReads(query)
      .then((res) => {
        setNumberUnreadNotice(res.data.unreads);
      })
      .catch((err) => {});
  };
  const renderNumberNotification = (type) => {
    if (type === ETypeQuery.BROADCAST) {
      return numberUnreadBroadCast;
    }
    return numberUnreadNotice;
  };
  return (
    <>
      <Row className="container_sos" gutter={14}>
        {column.map((item, index) => {
          return (
            <Col span={8} sm={8} lg={8} xl={8} className="col" key={index}>
              <Card
                title={
                  item.type !== ETypeQuery.ARTICLE ? (
                    <Badge count={renderNumberNotification(item?.type)} color="#ff3b30">
                      <Typography.Text className="container_title">{item.title}</Typography.Text>
                    </Badge>
                  ) : (
                    <Typography.Text className="container_title">{item.title}</Typography.Text>
                  )
                }
                className="card_content"
              >
                <ContentListComponent isFetch={isFetch} keySearch={keySearch} type={item.type} />
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ContentPage;
