import React from 'react';

export default function RemoveIcon(props) {
  const handleClick = () => {
    props?.onClick();
  };
  return (
    <>
      <svg
        className={props?.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClick}
      >
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#1A94FF"
        />
        <path
          d="M16.4711 8.47206C16.7314 8.21171 16.7314 7.7896 16.4711 7.52925C16.2107 7.2689 15.7886 7.2689 15.5283 7.52925L11.9997 11.0578L8.47108 7.52925C8.21073 7.2689 7.78862 7.2689 7.52827 7.52925C7.26792 7.7896 7.26792 8.21171 7.52827 8.47206L11.0569 12.0007L7.52827 15.5292C7.26792 15.7896 7.26792 16.2117 7.52827 16.4721C7.78862 16.7324 8.21073 16.7324 8.47108 16.4721L11.9997 12.9435L15.5283 16.4721C15.7886 16.7324 16.2107 16.7324 16.4711 16.4721C16.7314 16.2117 16.7314 15.7896 16.4711 15.5292L12.9425 12.0007L16.4711 8.47206Z"
          fill="white"
        />
      </svg>
    </>
  );
}
