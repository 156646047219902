import React from 'react';

export default function Folder() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.8622 13.1699L20.8446 18.343C20.6098 18.7456 20.2735 19.0796 19.8694 19.3117C19.4652 19.5439 19.0073 19.666 18.5412 19.666H1.87604C1.10425 19.666 0.623375 18.8288 1.01225 18.1621L4.02992 12.989C4.26476 12.5864 4.60102 12.2524 5.00517 12.0203C5.40932 11.7882 5.86726 11.666 6.33333 11.666H22.9985C23.7703 11.666 24.2512 12.5032 23.8622 13.1699ZM6.33333 10.3327H20V8.33268C20 7.2281 19.1046 6.33268 18 6.33268H11.3333L8.66667 3.66602H2C0.895417 3.66602 0 4.56143 0 5.66602V17.2513L2.87821 12.3172C3.59225 11.0931 4.91621 10.3327 6.33333 10.3327Z"
          fill="white"
        />
      </svg>
    </>
  );
}
