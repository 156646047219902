export default function ContentIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1667 3.88898H3.05556C2.59531 3.88898 2.22222 4.26207 2.22222 4.72231V5.00009H0.833333C0.37309 5.00009 0 5.37318 0 5.83342V15.2779C0 16.3518 0.870556 17.2223 1.94444 17.2223H18.3333C19.2538 17.2223 20 16.4761 20 15.5556V4.72231C20 4.26207 19.6269 3.88898 19.1667 3.88898ZM1.94444 15.5556C1.87077 15.5556 1.80012 15.5264 1.74803 15.4743C1.69593 15.4222 1.66667 15.3515 1.66667 15.2779V6.66676H2.22222V15.2779C2.22222 15.3515 2.19296 15.4222 2.14086 15.4743C2.08877 15.5264 2.01812 15.5556 1.94444 15.5556ZM10.1389 15.0001H4.86111C4.63101 15.0001 4.44444 14.8135 4.44444 14.5834V14.3056C4.44444 14.0755 4.63101 13.889 4.86111 13.889H10.1389C10.369 13.889 10.5556 14.0755 10.5556 14.3056V14.5834C10.5556 14.8135 10.369 15.0001 10.1389 15.0001ZM17.3611 15.0001H12.0833C11.8532 15.0001 11.6667 14.8135 11.6667 14.5834V14.3056C11.6667 14.0755 11.8532 13.889 12.0833 13.889H17.3611C17.5912 13.889 17.7778 14.0755 17.7778 14.3056V14.5834C17.7778 14.8135 17.5912 15.0001 17.3611 15.0001ZM10.1389 11.6668H4.86111C4.63101 11.6668 4.44444 11.4802 4.44444 11.2501V10.9723C4.44444 10.7422 4.63101 10.5556 4.86111 10.5556H10.1389C10.369 10.5556 10.5556 10.7422 10.5556 10.9723V11.2501C10.5556 11.4802 10.369 11.6668 10.1389 11.6668ZM17.3611 11.6668H12.0833C11.8532 11.6668 11.6667 11.4802 11.6667 11.2501V10.9723C11.6667 10.7422 11.8532 10.5556 12.0833 10.5556H17.3611C17.5912 10.5556 17.7778 10.7422 17.7778 10.9723V11.2501C17.7778 11.4802 17.5912 11.6668 17.3611 11.6668ZM17.3611 8.33342H4.86111C4.63101 8.33342 4.44444 8.14686 4.44444 7.91676V6.52787C4.44444 6.29776 4.63101 6.1112 4.86111 6.1112H17.3611C17.5912 6.1112 17.7778 6.29776 17.7778 6.52787V7.91676C17.7778 8.14686 17.5912 8.33342 17.3611 8.33342Z"
        fill="white"
      />
    </svg>
  );
}
