// eslint-disable-next-line react-hooks/exhaustive-deps

import { Col, Row, Layout, Button, Typography, Image } from 'antd';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import styles from './success.module.scss';
import { storage } from 'utils';
import SuccessReport from 'components/common/icon-svg/successReport';
import { useEffect } from 'react';
import { off } from 'process';

const { Content } = Layout;
const { Title, Text } = Typography;
const ReportSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const token = storage.getToken();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    const root = document.getElementById('root');
    if (root) {
      root.style.width = '100%';
    }
    return () => {
      if (root) {
        root.removeAttribute('style');
      }
    };
  }, []);

  if (!token) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <HeaderComponent />
      <Content className={styles.contentReport}>
        <Row className={styles.container}>
          <Col className={styles.col} xs={20} sm={16} md={12} lg={12}>
            <div className={styles.card}>
              <Title className={styles.title}>{t('report.finish.title')}</Title>
              <div>
                <SuccessReport />
              </div>

              {location.state ? (
                <div className={styles.container_text}>
                  <Text className={styles.text}>{t(`${(location.state as any).message}`)}</Text>
                </div>
              ) : (
                ''
              )}

              <Button
                type="primary"
                htmlType="submit"
                className={styles.btn}
                onClick={() => {
                  history.push({ pathname: '/' });
                }}
              >
                {t('report.new.back_to_home')}
              </Button>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default ReportSuccess;
