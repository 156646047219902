import React from 'react';

export default function Lock() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2957:31978)">
          <path
            d="M17.292 10H7.60449V5.97269C7.60449 4.42582 8.84277 3.14066 10.3896 3.12504C11.9521 3.10941 13.2295 4.37894 13.2295 5.93754V6.56254C13.2295 7.08207 13.6475 7.50004 14.167 7.50004H15.417C15.9365 7.50004 16.3545 7.08207 16.3545 6.56254V5.93754C16.3545 2.65629 13.6787 -0.0116803 10.3975 3.84578e-05C7.11621 0.0117572 4.47949 2.71488 4.47949 5.99613V10H3.54199C2.50684 10 1.66699 10.8399 1.66699 11.875V18.125C1.66699 19.1602 2.50684 20 3.54199 20H17.292C18.3271 20 19.167 19.1602 19.167 18.125V11.875C19.167 10.8399 18.3271 10 17.292 10Z"
            fill="#FF3B30"
          />
        </g>
        <defs>
          <clipPath id="clip0_2957:31978">
            <rect width="17.5" height="20" fill="white" transform="translate(1.66699)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
