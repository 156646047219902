import { API_URL } from 'config';

import io from 'socket.io-client';
import { axios } from 'lib';
import { storage } from '../utils';

export interface IMsgNotification {
  client_id: string;
  message: object;
  org_id: string;
}

let socket: any;

export const initialSocket = (token: string, roomId: string) => {
  socket = io(`${API_URL}/chat`, {
    transports: ['websocket'],
    secure: true,
    path: '/socket.io',
    query: { token: token, from: 'anonymouse' },
  });

  socket.on('connect_error', (err) => {
    console.log(`connect_error due to ${err.message}`);
    return axios.get(`${API_URL}`, {
      params: {
        roomId: roomId,
        type: 'connectionSocket:Error:connect_error',
        message: err.message,
      },
    });
  });

  socket.on('disconnect', (err) => {
    return axios.get(`${API_URL}`, {
      params: { roomId: roomId, type: 'connectionSocket:Error:disconnect', message: err.message },
    });
  });

  onConnect(roomId);
  onPing();
};

let socketNotification: any;
export const initialNotification = (token: string) => {
  socketNotification = io(`${API_URL}/notifications`, {
    path: '/socket.io',
    transports: ['websocket'],
    secure: true,
    query: { token, from: 'anonymouse' },
  });
};

/**
 * @socket
 * @emit
 */

export const joinRoom = (room: string) => {
  if (socket) {
    return socket.emit('joinRoom', room);
  }
};

export const leaveRoom = (room: string) => {
  if (socket) {
    return socket.emit('leaveRoom', room);
  }
};

export const sendMessage = async (room: string, message: any) => {
  if (window.navigator.onLine === false) {
    await axios.get(`${API_URL}/error`, { params: { type: 'sendMessage:Error:OffLine' } });
    throw 'Cannot connect to chat server';
  }
  if (socket && socket.connected) {
    if (message?.files > 0) {
      return socket.emit('msgToServer', { ...message, room });
    } else {
      return socket.emit('msgToServer', { text: message, room });
    }
  } else {
    await axios.get(`${API_URL}/error`, { params: { type: 'sendMessage:Error', roomId: room } });
    throw 'Cannot connect to chat server';
  }
};

/**
 * @socket
 * @on
 */

export const onReceiveMessage = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('msgToClient', (msg: any) => {
      callback(msg);
    });
  }
};

export const onReceiveNotification = (client_id, callback: (msg: IMsgNotification) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_message_anonymouse_${client_id}`, (msg: IMsgNotification) => {
      callback(msg);
    });
  }
};

export const onReceiveNotificationBroadCast = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_admin_broadcast`, (msg: any) => {
      callback(msg);
    });
  }
};
export const onReceiveNotice = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_cms_content`, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStartMaintaince = (callback: (msg: any) => void) => {
  if (socketNotification) {
    const onEvent: string = `start_maintaince`;
    return socketNotification.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStopMaintaince = (callback: (msg: any) => void) => {
  if (socketNotification) {
    const onEvent: string = `stop_maintaince`;
    return socketNotification.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onReceiveAll = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_admin_content`, (msg: any) => {
      callback(msg);
    });
  }
};
export const onHandleErrorSocket = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('Error', async (msg: any) => {
      console.log('Socket error', msg);
      const url_refresh = API_URL + '/anonymous/refresh_token';
      await fetch(url_refresh, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: storage.getRefreshToken(),
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          storage.setToken(response?.data?.access_token);
        });

      callback(msg);
    });
  }
};

export const onConnect = (roomId: string) => {
  if (socket) {
    return socket.on('connect', () => {
      joinRoom(roomId);
      return axios.get(`${API_URL}`, { params: { roomId: roomId, type: 'initialSocket:Success' } });
    });
  } else {
    return axios.get(`${API_URL}`, {
      params: { type: 'noSocketConnection:Error', roomId: roomId },
    });
  }
};

export const onJoinRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('joinedRoom', (room: string) => {
      callback(room);
    });
  }
};

export const onLeftRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('leftRoom', (room: string) => {
      callback(room);
    });
  }
};

/**
 * Disconnect socket
 */

export const disconnectSocket = () => {
  if (socket) {
    return socket.disconnect();
  }
};
export const disconnectSocketNotification = () => {
  if (socketNotification) {
    return socketNotification.disconnect();
  }
};

export const onPing = () => {
  socket.on('ping', () => {});
};

export const onReceiveDeleteMessage = (client_id, callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`delete_message_anonymouse_${client_id}`, (msg: any) => {
      callback(msg);
    });
  }
};
