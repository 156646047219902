import CryptoJS from 'crypto-js';

const key = process.env.DATA_KEY || '';

function encrypt(data: string) {
  return CryptoJS.AES.encrypt(data, key).toString();
}

function decrypt(ciphertext: string) {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    throw error;
  }
}

const hasher = {
  encrypt,
  decrypt,
};

export { hasher };
