export default function SuccessReport() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 40.1412V47.7008L50 84.9551L49.9785 84.9705L0 47.7008V40.1412L13.8379 29.5283H86.1621L100 40.1412Z"
        fill="#FEA5C9"
      />
      <path d="M100 40.1412V47.7008L50 84.9551V29.5283H86.1621L100 40.1412Z" fill="#FEA5C9" />
      <path
        d="M87.3047 0V58.3631L50.0234 82.9559L50 82.9402L12.6953 58.3555V0L87.3047 0Z"
        fill="#EAF4F4"
      />
      <path d="M87.3047 0V58.3631L50.0234 82.9559L50 82.9402V0L87.3047 0Z" fill="#CAE8EA" />
      <path
        d="M100 53.5432V100H0V47.7012L49.9785 79.1291L50.0234 79.1582L100 53.5432Z"
        fill="#21C0F6"
      />
      <path
        d="M99.9997 47.7012V100H49.9783V79.1291L50.0232 79.1582L99.9997 47.7012Z"
        fill="#3EB5E9"
      />
      <path
        d="M50 62.8398C63.654 62.8398 74.7227 51.7711 74.7227 38.1172C74.7227 24.4632 63.654 13.3945 50 13.3945C36.3461 13.3945 25.2773 24.4632 25.2773 38.1172C25.2773 51.7711 36.3461 62.8398 50 62.8398Z"
        fill="#00DC7B"
      />
      <path
        d="M74.7227 38.1172C74.7227 51.75 63.6328 62.8398 50 62.8398V13.3945C63.6328 13.3945 74.7227 24.4844 74.7227 38.1172Z"
        fill="#00AA8E"
      />
      <path
        d="M61.0745 33.7695L47.4553 47.3887L38.926 38.8594L43.0686 34.7168L47.4553 39.1035L50.0002 36.5586L56.9319 29.627L61.0745 33.7695Z"
        fill="white"
      />
      <path
        d="M61.0742 33.7695L50 44.8438V36.5586L56.9316 29.627L61.0742 33.7695Z"
        fill="#EAF4F4"
      />
    </svg>
  );
}
