import Axios, { AxiosRequestConfig } from 'axios';
import { history } from 'lib';
import { storage, clearAll } from '../utils';
import { API_URL } from 'config';
import { showError } from 'components/common/standby-notice';
import I18n from 'config/i18n';
import { authActions } from 'features/auth/authSlice';
import { appConnect } from 'App';
import nProgress from 'nprogress';
import { ETypeErrorCode } from 'utils/common';

nProgress.configure({
  minimum: 0.8,
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

function backToLogin() {
  if (window.location.href.includes('/auth/login')) {
    return appConnect.history.push('/auth/login');
  }
  return (window.location.href = '/auth/login');
}

function authRequestInterceptor(config: AxiosRequestConfig) {
  nProgress.start();
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const removeAllData = () => {
  storage.clearToken();
  storage.clearRefreshToken();
  storage.clearKeyMenu();
  storage.clearCurentOrganization();
};

export const listAccessCodeLogout = [
  'OG10005', // This org is not have access code
  'OG10006', // The access code is inactive
  'OG10000', // Organization inactive
  'OG10004', // Organization is deleted
  'AD60002', // refresh_token invalid
  'AD60001', // refresh_token required
  'AN30004', // access_code inactive
  'OG10001', //org deleted
  'AD30001', // org deactive
];

export const accessCodeBlock = [
  'ATE10003', //Actived user is block
];

const TIMEOUT = 20000; // 20s
const axios = Axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
});

axios.interceptors.request.use(authRequestInterceptor, (error) => {
  nProgress.done();
  return Promise.reject(error);
});
axios.interceptors.response.use(
  (response) => {
    nProgress.done();
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      handleError401(error);
      return Promise.reject(error?.response);
    }

    if (error.response?.status === 503) {
      handleError503(error);
      return Promise.reject(error?.response);
    }

    showErrorMessage(error?.response?.data?.error_code);

    nProgress.done();
    if (!error || error.message === 'Network Error') {
      showError(I18n.t('errors.code.network_error'));
    } else if (error.response?.status === 404) {
      handleError404(error);
    } else if (error.response?.status === 400) {
      handleError400(error);
    } else if (error.response?.status > 500) {
      showError(I18n.t('errors.code.SYS00000'));
    } else if (error.response?.status === 403) {
      showErrorMessage(error?.response?.data?.error_code);
      handleError403(error);
    }

    return Promise.reject(error?.response);
  }
);

const handleError403 = (error: any) => {
  if (!error?.response?.data?.error_code) return;
  if (listAccessCodeLogout.includes(error?.response?.data?.error_code)) {
    removeAllData();
    return backToLogin();
  }
  if (error?.response?.data?.error_code === 'ATE10003') {
    history.push('/');
    appConnect.dispatch(authActions.blockUser({ flag: true }));
  }
  throw error;
};

const handleError503 = (error: any) => {
  if (!error?.response?.data?.error_code) return;

  if (error?.response?.data?.error_code === 'SYS10001') {
    history.push('/');
    appConnect.dispatch(authActions.maintenance({ flag: true }));
  }
};

const handleError404 = (error: any) => {
  if (error?.response.data?.status.toString() === 'ERROR') {
    if (error?.response.data?.error_code === ETypeErrorCode.OG10001) {
      return backToLogin();
    } else {
      return Promise.reject(error?.response);
    }
  }
};

const handleError400 = (error: any) => {
  if (error?.response.data?.status.toString() === 'ERROR') {
    if (error?.response.data?.error_code === ETypeErrorCode.AN30004) {
      return backToLogin();
    } else {
      return Promise.reject(error?.response);
    }
  }
};
const handleError401 = (error: any) => {
  const url_refresh = API_URL + '/anonymous/refresh_token';
  const url_logout = API_URL + '/anonymous/logout';
  if (!storage.getRefreshToken()) return removeAllData();
  fetch(url_refresh, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh_token: storage.getRefreshToken(),
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      storage.setToken(response?.data?.access_token);
      if (error.response?.config?.url === '/anonymous/logout') {
        fetch(url_logout, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${response?.data?.access_token}`,
          },
          body: JSON.stringify({
            refresh_token: storage.getRefreshToken(),
          }),
        })
          .then((res) => res.json())
          .then(() => {
            clearAll(() => {
              return window.location.reload();
            });
          })
          .catch(() => {
            clearAll(() => {
              return window.location.reload();
            });
          });
      } else {
        if (!response) {
          removeAllData();
          return backToLogin();
        }
        if (listAccessCodeLogout.includes(error?.response?.data?.error_code)) {
          removeAllData();
          return backToLogin();
        }
        if (!response?.data?.access_token) {
          removeAllData();
          return backToLogin();
        }
        // case done
        const newRequest = {
          ...error?.response?.config,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${response?.data?.access_token}`,
          },
        };
        return axios.request(newRequest);
      }
    });
};

const showErrorMessage = (errorCode: string) => {
  if ([...listAccessCodeLogout, ...accessCodeBlock].includes(errorCode)) return;
  showError(I18n.t(`errors.code.${errorCode}`));
};

export { axios };
