import { useEffect, useState } from 'react';
import { getListContent, IQueryGetContent } from 'features/content/api';
import { ETypeQuery } from '../types';
import { showError } from 'components/common/standby-notice';
import { Spin, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get } from 'lodash';
import ImageLoading from './ImageLoading';
import ContentIcon from 'components/common/icon-svg/attchIcon';

const { Meta } = Card;

interface IProps {
  type: ETypeQuery;
  keySearch: string;
  isFetch: string;
}

interface IPagination {
  hasNextPage: boolean | undefined;
  hasPrevPage: boolean | undefined;
  limit: number;
  nextPage: number;
  page: number;
  pagingCounter: number;
  prevPage: null;
  totalDocs: number;
  totalPages: number;
}

function ContentListComponent({ type, keySearch, isFetch }: IProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any[]>([]);
  const [heightScroll, setHeightScroll] = useState(window.innerHeight - 162);

  const [pagination, setPagination] = useState<IPagination>({
    hasNextPage: false,
    hasPrevPage: false,
    limit: 15,
    nextPage: 0,
    page: 1,
    pagingCounter: 0,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0,
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeightScroll(window.innerHeight - 152);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setHeightScroll(window.innerHeight - 162);
      });
    };
  }, []);

  const loadingListData = (page: number, pageSize: number, noFilter = true) => {
    const query: IQueryGetContent = {
      type,
      page_number: page,
      page_size: pageSize,
    };
    if (noFilter) {
      query.title = keySearch?.trim();
    }
    getListContent(query)
      .then((res) => {
        if (res.data.docs.length > 0) {
          const newPage = get(res, 'data.pagination.page');
          if (newPage > 1) {
            const newData = get(res, 'data.docs', []);
            setDataList([...dataList, ...newData]);
          } else {
            setDataList(get(res, 'data.docs'));
          }
          setPagination(get(res, 'data.pagination'));
        } else {
          setDataList([]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    loadingListData(pagination.page, pagination.limit, false);
  }, []);

  useEffect(() => {
    if (keySearch === '') {
      loadingListData(1, pagination.limit);
    }
    loadingListData(1, pagination.limit);
  }, [keySearch, isFetch]);

  const loadMore = () => {
    if (loadingMore) return;
    if (!pagination.hasNextPage) return;
    setLoadingMore(true);
    loadingListData(pagination.nextPage, pagination.limit);
  };

  const renderTitle = (record) => {
    switch (type) {
      case ETypeQuery.ARTICLE:
        return get(record, 'title');
      case ETypeQuery.BROADCAST:
        return get(record, 'subject');
      case ETypeQuery.NOTICE:
        return get(record, 'title');
      default:
        return null;
    }
  };

  const renderNew = (time) => {
    if (moment().diff(moment(time, 'YYYY/MM/DD'), 'days') <= 10)
      return <div className="new">{t('article.new')}</div>;
  };

  const checkRead = (record) => {
    if (type === ETypeQuery.ARTICLE) {
      return false;
    }
    if (!get(record, 'inforNotify')) {
      return false;
    }

    if (get(record, 'inforNotify.view_at')) {
      return false;
    }

    return true;
  };

  const renderDate = (item, type) => {
    if (type === ETypeQuery.BROADCAST) {
      return moment(item?.createdAt).format('YYYY/MM/DD HH:mm');
    }
    return moment(item?.date_published).format('YYYY/MM/DD HH:mm');
  };
  return (
    <Spin spinning={loading} size="small">
      <div id={`scrollable${type}`} className="scrollable" style={{ height: heightScroll }}>
        <InfiniteScroll
          dataLength={dataList?.length}
          next={loadMore}
          hasMore={true}
          loader={<Spin size="small" spinning={loadingMore} className="spin-content" />}
          scrollableTarget={`scrollable${type}`}
          className="inifinite-scroll"
        >
          {(dataList || []).map((item, index) => {
            let files;
            if (type === ETypeQuery.BROADCAST) {
              files = get(item, 'files', []);
            } else {
              files = get(item, 'file_url', []);
            }

            return (
              <Link
                className="link"
                key={index}
                to={
                  item.content_type
                    ? `/detail?${item?.content_type}=${item?._id}`
                    : `/detail?${type}=${item?._id}`
                }
              >
                <Meta
                  style={{
                    background: checkRead(item) ? '#E3F7FF' : '',
                    borderBottomWidth: index === dataList.length - 1 ? 0 : 1,
                  }}
                  className="main-content"
                  avatar={type === ETypeQuery.ARTICLE ? <ImageLoading record={item} /> : null}
                  title={renderTitle(item)}
                  description={
                    <Typography.Text className="text_attechment">
                      <div className="wrap">
                        {type === ETypeQuery.ARTICLE && renderNew(item?.date_published)}
                        {renderDate(item, type)}
                      </div>
                      {type !== ETypeQuery.ARTICLE && files?.length > 0 && (
                        <div className="wrap-file">
                          <ContentIcon />
                          {t('content.file.uploaded', { number: files?.length })}
                        </div>
                      )}
                    </Typography.Text>
                  }
                />
              </Link>
            );
          })}
        </InfiniteScroll>
      </div>
    </Spin>
  );
}

export default ContentListComponent;
