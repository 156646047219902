// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { Fragment, useRef, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  FormInstance,
  Upload,
  Image,
  Spin,
  Select,
  DatePicker,
  Checkbox,
  Tooltip,
} from 'antd';
import styles from './detail.module.scss';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { LeftOutlined } from '@ant-design/icons';
import './detail.scss';
import { getIncidentDetail, IQueryGetIncidentDetail } from 'features/message/api';
import { storage } from 'utils';
import moment from 'moment';
import { ETypeQuestion } from 'features/incident/pages/report/typeQuestion';
const { Title, Text } = Typography;
const { TextArea } = Input;

interface IGetValueIncidentDetail {
  description: string;
  files: number;
  incident_number: number;
  questions: any[];
}

function IncidentDetail() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref = useRef<FormInstance>(null);
  const token = storage.getToken();
  const history = useHistory();
  const [IndexUrl, setIndexUrl] = useState<number>(0);
  const { id }: any = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [listIncidentDetail, setListIncidentDetail] = useState<IGetValueIncidentDetail>();
  const [listQuestion, setListQuestion] = useState<any[]>();

  React.useEffect(() => {
    getListIncidentDetail();
  }, []);

  const getListIncidentDetail = () => {
    setLoading(true);
    if (id) {
      const query: IQueryGetIncidentDetail = { incident_id: id };
      getIncidentDetail(query)
        .then((res) => {
          const values = res.data;
          let array: any[] = [];

          values.questions.forEach((element, index) => {
            array = array.concat({ id: index + 1, ...element });
          });

          SetValueListQuestion(array);
          setListIncidentDetail(values);
          setIndexUrl(values.files);
          setListQuestion(array);
          form.setFieldsValue({
            description: values.description,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const SetValueListQuestion = (data: any[]) => {
    const questions = {};
    data.forEach((item: any) => {
      if (item.type === ETypeQuestion.DATE) {
        if (item?.answer === '') return;
        questions[item?.id] = moment(item?.answer);
      } else if (item.type === ETypeQuestion.CHECKBOXLIST) {
        if (item?.answer?.length > 0) {
          questions[item?.id] = item?.answer;
        }
      } else {
        questions[item?.id] = item?.answer;
      }
    });
    form.setFieldsValue(questions);
  };

  const HandleGoback = () => {
    history.goBack();
  };

  const uploadButton = (
    <div
      className="upload-btn"
      style={{
        justifyContent: 'center',
        border: '1px dashed #d9d9d9',
      }}
    >
      <div className="containerUpload">
        <Image
          preview={false}
          style={{ width: 16, marginRight: 13 }}
          src="/image/AttachUpLoad.png"
        />
        <div>{t(`incident.detail.uploaded_files`, { file: IndexUrl })}</div>
      </div>
    </div>
  );

  if (!token) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      <HeaderComponent />
      <Content className="incident-detail">
        <Spin spinning={loading}>
          <Row className={styles.incident_detail}>
            <Col className={styles.card_input} xs={20} sm={16} md={12} lg={12}>
              <Text
                className={styles.title}
                style={{ padding: '16px 0', cursor: 'pointer' }}
                onClick={HandleGoback}
              >
                <LeftOutlined style={{ marginRight: '10px' }} />
                {t('report.leave_btn')}
              </Text>
              <div className={styles.form_incident}>
                <Title level={5} className={styles.form_title}>
                  {t(`chat.incident.title`, { incident: listIncidentDetail?.incident_number })}
                </Title>
                <Form className={styles.form_input} ref={ref} form={form} colon={false}>
                  <Title level={5} className={styles.form_message} style={{ paddingBottom: 12 }}>
                    {t('report.new.content.title')}
                  </Title>
                  <Form.Item
                    name="description"
                    className={styles.form_item}
                    style={
                      form.getFieldValue('description')?.length > 300
                        ? { minHeight: 150 }
                        : { minHeight: 50 }
                    }
                  >
                    <TextArea
                      className={styles.inputarea}
                      disabled
                      style={
                        form.getFieldValue('description')?.length > 300
                          ? { minHeight: 150 }
                          : { minHeight: 50 }
                      }
                    />
                  </Form.Item>
                  {IndexUrl > 0 && (
                    <Form.Item>
                      <div
                        className={styles.form_message}
                        style={{ marginBottom: 10, fontWeight: 600 }}
                      >
                        {t('incident.detail.uploaded_files.title')}
                      </div>
                      <Upload
                        multiple={true}
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        disabled
                      >
                        {uploadButton}
                      </Upload>
                    </Form.Item>
                  )}
                  {Array.isArray(listQuestion) && listQuestion?.length > 0 && (
                    <div className="qs_list">
                      <Title level={5} className={styles.ReportMessage}>
                        {t('incident.detail.question_lists')}
                      </Title>
                      <Text className="question-text">
                        ( <span style={{ marginRight: 8 }}></span>
                        {t('incident.detail.question_title')})
                      </Text>
                    </div>
                  )}

                  {Array.isArray(listQuestion) &&
                    listQuestion?.length > 0 &&
                    listQuestion?.map((item, index) => {
                      const isDropDown: boolean = item?.type === ETypeQuestion.DROPDOWN;
                      const isTextBox: boolean = item?.type === ETypeQuestion.TEXTBOX;
                      const isCheckBoxList: boolean = item?.type === ETypeQuestion.CHECKBOXLIST;
                      const isMail: boolean = item?.type === ETypeQuestion.EMAIL;
                      const isCheckBox: boolean = item?.type === ETypeQuestion.CHECKBOX;
                      const isRating: boolean = item?.type === ETypeQuestion.RATING;
                      const isDate: boolean = item?.type === ETypeQuestion.DATE;
                      return (
                        <Fragment key={index}>
                          {isDropDown && (
                            <Form.Item
                              name={item?.id}
                              label={item?.name}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <Select
                                disabled
                                dropdownClassName="dropdown-display"
                                optionLabelProp="label"
                                className="select-dropdown"
                              />
                            </Form.Item>
                          )}
                          {isCheckBoxList && (
                            <Form.Item
                              name={item?.id}
                              label={item?.name}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                disabled
                                dropdownClassName="dropdown-display"
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                showArrow={true}
                                showSearch={false}
                              />
                            </Form.Item>
                          )}
                          {isDate && (
                            <Form.Item
                              name={item?.id}
                              label={item?.name}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <DatePicker
                                format="YYYY/MM/DD"
                                clearIcon={false}
                                disabled
                                placeholder=""
                              />
                            </Form.Item>
                          )}
                          {isRating && (
                            <Form.Item
                              name={item?.id}
                              label={item?.name}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <Select
                                disabled
                                optionLabelProp="label"
                                dropdownClassName="dropdown-display"
                                className="select-rating"
                              />
                            </Form.Item>
                          )}

                          {isTextBox && (
                            <Form.Item
                              name={item?.id}
                              label={item?.name}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <TextArea className={styles.input} disabled={true} />
                            </Form.Item>
                          )}
                          {isMail && (
                            <Tooltip title={item?.answer} placement="topLeft">
                              <Form.Item
                                className={item?.is_required ? 'email' : ' before'}
                                name={item?.id}
                                label={item?.name}
                                rules={[
                                  {
                                    required: item.is_required,
                                  },
                                ]}
                              >
                                <Input disabled={true} maxLength={256} className={styles.input} />
                              </Form.Item>
                            </Tooltip>
                          )}
                          {isCheckBox && (
                            <Form.Item
                              name={item?.id}
                              valuePropName="checked"
                              className={item.is_required ? 'checkbox-required' : ''}
                              rules={[
                                {
                                  required: item.is_required,
                                },
                              ]}
                            >
                              <Checkbox disabled className="checkbox-detail">
                                <Typography.Text
                                  className="checkbox-text"
                                  style={{ whiteSpace: 'pre-wrap' }}
                                >
                                  {' '}
                                  {item.name}
                                </Typography.Text>
                              </Checkbox>
                            </Form.Item>
                          )}
                        </Fragment>
                      );
                    })}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Content>
    </>
  );
}
export default IncidentDetail;
