import React from 'react';

export default function AttchMessageFile(props) {
  function handleClick() {
    props?.onClick();
  }
  return (
    <>
      <svg width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        onClick={handleClick}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21.75 18H2.25C1.00734 18 0 16.9927 0 15.75V2.25C0 1.00734 1.00734 0 2.25 0H21.75C22.9927 0 24 1.00734 24 2.25V15.75C24 16.9927 22.9927 18 21.75 18ZM5.25 2.625C3.80025 2.625 2.625 3.80025 2.625 5.25C2.625 6.69975 3.80025 7.875 5.25 7.875C6.69975 7.875 7.875 6.69975 7.875 5.25C7.875 3.80025 6.69975 2.625 5.25 2.625ZM3 15H21V9.75L16.8977 5.64773C16.6781 5.42808 16.3219 5.42808 16.1022 5.64773L9.75 12L7.14773 9.39773C6.92808 9.17808 6.57192 9.17808 6.35222 9.39773L3 12.75V15Z" fill="#A8A8A8" />
      </svg>
    </>
  );
}
