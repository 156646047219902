import moment from 'moment';

export enum ETypeErrorCode {
  AN30004 = 'AN30004',
  OG10005 = 'OG10005',
  OG10006 = 'OG10006',
  OG10000 = 'OG10000',
  OG10004 = 'OG10004',
  AD60002 = 'AD60002',
  OG10001 = 'OG10001',
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) return false;
  var emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  ) {
    return false;
  }
  return re.test(String(email).toLowerCase());
}

export function validateOnlyNumber(number: any) {
  if (!/^[0-9]+$/.test(number)) {
    return false;
  }
  return true;
}

export function randomStr(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const formatDate = (date) => {
  const d = moment(date).format('YYYY/DD/MM');
  return d;
};
