export default function ChatIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2507:16628)">
        <path
          d="M13.7733 7.6389C13.7733 4.88919 10.8774 2.66205 7.3034 2.66205C3.7294 2.66205 0.833496 4.88919 0.833496 7.6389C0.833496 8.70581 1.27208 9.68874 2.0155 10.5006C1.59869 11.44 0.911259 12.1865 0.901928 12.1958C0.833496 12.2674 0.814833 12.3731 0.85527 12.4664C0.895707 12.5598 0.982802 12.6158 1.08234 12.6158C2.22079 12.6158 3.16328 12.2332 3.84138 11.8381C4.84297 12.3265 6.02808 12.6158 7.3034 12.6158C10.8774 12.6158 13.7733 10.3886 13.7733 7.6389ZM17.5682 14.4821C18.3116 13.6733 18.7502 12.6873 18.7502 11.6204C18.7502 9.53944 17.086 7.7571 14.7282 7.01368C14.7562 7.21898 14.7687 7.42738 14.7687 7.6389C14.7687 10.933 11.4186 13.6111 7.3034 13.6111C6.96747 13.6111 6.64086 13.5862 6.31736 13.552C7.29718 15.3406 9.59898 16.5972 12.2803 16.5972C13.5556 16.5972 14.7407 16.3111 15.7423 15.8196C16.4204 16.2146 17.3629 16.5972 18.5013 16.5972C18.6009 16.5972 18.6911 16.5381 18.7284 16.4479C18.7688 16.3577 18.7502 16.252 18.6817 16.1773C18.6724 16.168 17.985 15.4246 17.5682 14.4821Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2507:16628">
          <rect
            width="17.9167"
            height="15.9259"
            fill="white"
            transform="translate(0.833496 1.66669)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
