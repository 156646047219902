import { Button, Modal } from 'antd';
import { useAppSelector } from 'app/hooks';
import { authActions, selectAuth } from 'features/auth/authSlice';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as Svg } from './svg/sys10001.svg';

const Error = () => {
  const { t } = useTranslation();
  const isBlockUser = useAppSelector(selectAuth).isBlock;
  const isMaintenance = useAppSelector(selectAuth).isMaintenance;
  const dispatch = useDispatch();

  const OkMaintaine = useCallback(() => {
    return dispatch(authActions.maintenance({ flag: false }));
  }, []);

  return (
    <Fragment>
      <Modal
        className="modal-user-block"
        title={t('app.message.account.disabled')}
        centered
        visible={isBlockUser}
        closable={false}
        onOk={() => null}
        onCancel={() => null}
        footer={[]}
      >
        <div>
          <img alt="" src="./image/ic-lock.png" />
        </div>
        <div className="text-des">{t('app.message.description.account.disabled')}</div>
      </Modal>
      <Modal
        className="modal-user-block"
        title={null}
        centered
        visible={isMaintenance}
        closable={false}
        footer={[]}
      >
        <Svg />
        <div style={{ whiteSpace: 'pre' }}>{t('errors.code.SYS10001')}</div>
        <Button
          onClick={OkMaintaine}
          style={{ width: '100%', marginTop: 50, height: 42 }}
          type="primary"
        >
          {t('modal.button.error.SYS10001.ok')}
        </Button>
      </Modal>
    </Fragment>
  );
};

export default Error;
