import { axios } from 'lib';

type InfoNewPass = {
  action: string | any;
  new_passcode: string;
  confirm_passcode: string;
  current_passcode: string;
};

const ApiPassCode = (data: InfoNewPass) => {
  return axios.post('anonymous/passcode/action', data);
};
export { ApiPassCode };
export type { InfoNewPass };
