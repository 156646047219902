import { Modal, Button, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './modal.module.scss';
import './index.scss';
interface IProps {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  contentText?: string;
  type?: string;
  text: string;
  textCancel: string;
}

const { Text } = Typography;

const ModalGlobal = ({
  visible,
  handleOk,
  handleCancel,
  contentText,
  type,
  text,
  textCancel,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={394}
      zIndex={700}
      visible={visible}
      onOk={handleOk}
      footer={null}
      closable={false}
      className={styles.Modal}
    >
      <Text className={styles.TextModal} style={{ padding: type === '' ? '22px' : '0' }}>
        {contentText ? contentText : t('modal.title.content.default')}
      </Text>

      <div
        style={{
          display: type === 'submit' ? '' : 'flex',
          width: '100%',
          marginTop: 15,
          padding: '0 18px',
        }}
      >
        {type === 'submit' ? (
          ''
        ) : (
          <Button
            type="ghost"
            className={styles.ButtonModal}
            style={{ marginRight: 15, color: '#21C0F6', border: '1.5px solid #21C0F6' }}
            onClick={handleCancel}
          >
            {textCancel}
          </Button>
        )}
        <Button
          type="primary"
          className={styles.ButtonOK}
          onClick={handleOk}
          style={{ width: type === 'submit' ? '100%' : '' }}
        >
          {text}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalGlobal;
