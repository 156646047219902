import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user';
import { RootState } from 'app/store';

export interface LoginPayload {
  access_code: string;
  platform: string;
  client_id: any;
}

export interface IPayloadBlockUser {
  flag: boolean;
}

export interface IPayloadMaintenance {
  flag: boolean;
}

export interface AuthState {
  loading: boolean;
  currentUser?: User;
  error?: any;
  isBlock: boolean;
  isMaintenance: boolean;
}

const initialState: AuthState = {
  loading: false,
  currentUser: undefined,
  isBlock: false,
  isMaintenance: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.currentUser = undefined;
    },
    blockUser(state, action: PayloadAction<IPayloadBlockUser>) {
      state.isBlock = true;
    },
    maintenance(state, action: PayloadAction<IPayloadMaintenance>) {
      const {payload} = action
      state.isMaintenance = payload.flag;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLogging = (state: RootState) => state.auth.loading;
export const selectAuth = (state: RootState) => state.auth;
export const selectList = (state: RootState) => state.auth.currentUser;
export const selectIsBlock = (state: RootState) => state.auth.isBlock;
// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
