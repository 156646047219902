export default function ArticleIcon(props) {
  return (
    <>
      <svg
        className={props?.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 3.66602H4.66667C3.79758 3.66602 3.05654 4.22339 2.78133 4.99935H1C0.447708 4.99935 0 5.44706 0 5.99935V17.3327C0 18.6213 1.04467 19.666 2.33333 19.666H23C23.5523 19.666 24 19.2183 24 18.666V4.66602C24 4.11372 23.5523 3.66602 23 3.66602ZM2 17.3327V6.99935H2.66667V17.3327C2.66667 17.5165 2.51713 17.666 2.33333 17.666C2.14954 17.666 2 17.5165 2 17.3327ZM22 17.666H4.64258C4.65817 17.5571 4.66667 17.4459 4.66667 17.3327V5.66602H22V17.666ZM7.16667 12.666H12.8333C13.1095 12.666 13.3333 12.4421 13.3333 12.166V8.16602C13.3333 7.88989 13.1095 7.66602 12.8333 7.66602H7.16667C6.89054 7.66602 6.66667 7.88989 6.66667 8.16602V12.166C6.66667 12.4421 6.89054 12.666 7.16667 12.666ZM8.33333 9.33268H11.6667V10.9993H8.33333V9.33268ZM6.66667 15.166V14.166C6.66667 13.8899 6.89054 13.666 7.16667 13.666H12.8333C13.1095 13.666 13.3333 13.8899 13.3333 14.166V15.166C13.3333 15.4421 13.1095 15.666 12.8333 15.666H7.16667C6.89054 15.666 6.66667 15.4421 6.66667 15.166ZM14.6667 15.166V14.166C14.6667 13.8899 14.8905 13.666 15.1667 13.666H19.5C19.7761 13.666 20 13.8899 20 14.166V15.166C20 15.4421 19.7761 15.666 19.5 15.666H15.1667C14.8905 15.666 14.6667 15.4421 14.6667 15.166ZM14.6667 9.16602V8.16602C14.6667 7.88989 14.8905 7.66602 15.1667 7.66602H19.5C19.7761 7.66602 20 7.88989 20 8.16602V9.16602C20 9.44214 19.7761 9.66602 19.5 9.66602H15.1667C14.8905 9.66602 14.6667 9.44214 14.6667 9.16602ZM14.6667 12.166V11.166C14.6667 10.8899 14.8905 10.666 15.1667 10.666H19.5C19.7761 10.666 20 10.8899 20 11.166V12.166C20 12.4421 19.7761 12.666 19.5 12.666H15.1667C14.8905 12.666 14.6667 12.4421 14.6667 12.166Z"
          fill="#404040"
        />
      </svg>
    </>
  );
}
