import { axios } from 'lib';
export interface  ILoginAcessCode {
  access_code?: string | null;
  client_id?: string | null;
  platform?: string | null;
  challenger_name?: string | null ,
challenger_answer?: string | null,
session?: string | null,

};


const postAcessCode = (data: ILoginAcessCode) => {
  return axios.post('/anonymous/access', data);
};
const apiPostValueSelect = (data: ILoginAcessCode) => {
  return axios.post('/anonymous/access', data);
};

const LogOut = () => {
  return axios.post('/anonymous/logout');
};
export { postAcessCode, apiPostValueSelect, LogOut };
