import { notification } from 'antd';
import './style.scss';

const showSuccess = (msg: string, duration = 2) => {
  notification.success({
    message: msg,
    duration,
    placement: 'topRight',
  });
};

const showError = (msg: string, duration = 4) => {
  notification.open({
    message: msg,
    duration,
    placement: 'topRight',
    className: 'notice-error-class',
    icon: null
  });
};

export { showSuccess, showError };
