import routes from 'routes';

export * from './storage';

export * from './hash';

export function searchRoute(r)  {
  let pathname = r.pathname;
  const routenow = routes.find((route) => {
    if(pathname.includes("/message/detail")) {
      pathname = "/message/detail/:id"
    } else if(pathname.includes("/auth/select")) {
      pathname = "/auth/select"
    }else if(pathname.includes("/auth/term"))  {
      pathname = "/auth/term"
    }
    return route.path === pathname
  });
  return routenow;
}