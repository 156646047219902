export default function SupportIcon() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_484:14941)">
          <path
            d="M15.5434 11.306L12.0434 9.80599C11.8939 9.74226 11.7277 9.72884 11.5699 9.76773C11.4121 9.80661 11.2712 9.89571 11.1684 10.0216L9.61844 11.9154C7.18586 10.7684 5.22819 8.81075 4.08125 6.37817L5.975 4.82816C6.10115 4.72556 6.19044 4.58465 6.22934 4.42677C6.26825 4.26889 6.25466 4.10263 6.19063 3.95316L4.69063 0.453155C4.62035 0.292032 4.49605 0.160481 4.33917 0.0811851C4.18229 0.00188911 4.00266 -0.0201814 3.83125 0.0187792L0.58125 0.768781C0.41599 0.806943 0.268545 0.899993 0.16298 1.03274C0.0574141 1.1655 -3.80691e-05 1.33011 1.89256e-08 1.49972C1.89256e-08 9.51536 6.49687 15.9997 14.5 15.9997C14.6697 15.9999 14.8343 15.9424 14.9672 15.8369C15.1 15.7313 15.1931 15.5838 15.2312 15.4185L15.9812 12.1685C16.02 11.9962 15.9974 11.8159 15.9175 11.6585C15.8376 11.5011 15.7053 11.3764 15.5434 11.306Z"
            fill="#21C0F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_484:14941">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
