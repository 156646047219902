export default function ContentIcon() {
  return (
    <>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_630_16202)">
          <path
            d="M1.46965 9.10435C0.328433 7.92683 0.349702 6.02796 1.49672 4.85472L5.5936 0.664072C6.45915 -0.221318 7.86609 -0.221396 8.73171 0.664072C9.58894 1.54091 9.58998 2.95565 8.73171 3.83353L5.16044 7.48298C4.57733 8.0794 3.62463 8.07108 3.05141 7.46349C2.49916 6.87813 2.51687 5.95034 3.07977 5.37456L5.88725 2.50669C6.008 2.38337 6.20585 2.38126 6.32917 2.50198L6.77567 2.93907C6.89901 3.05981 6.9011 3.25767 6.78038 3.38099L3.97319 6.24858C3.87686 6.34712 3.87092 6.51085 3.96053 6.60585C4.04592 6.69636 4.18016 6.69786 4.26694 6.60907L7.83821 2.95964C8.22128 2.5678 8.22128 1.92985 7.838 1.5378C7.46321 1.15446 6.86228 1.15427 6.48733 1.5378L2.39043 5.72843C1.71146 6.42296 1.70099 7.54718 2.36717 8.23454C3.03143 8.91995 4.10516 8.92081 4.77065 8.24013L8.1312 4.80265C8.25185 4.67923 8.4497 4.677 8.57312 4.79765L9.01994 5.23444C9.14335 5.35509 9.1456 5.55294 9.02494 5.67636L5.66438 9.11382C4.50079 10.304 2.61861 10.2899 1.46965 9.10435Z"
            fill="#777777"
          />
        </g>
        <defs>
          <clipPath id="clip0_630_16202">
            <rect width="8.75" height="10" fill="white" transform="translate(0.625)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
