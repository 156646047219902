import { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { useAppSelector } from 'app/hooks';
import 'moment/locale/ja';
import { searchRoute, storage } from 'utils';
import routes from 'routes';
import { selectAuth } from 'features/auth/authSlice';
import PrivateLayout from 'components/Layout/PrivateLayout';
import Error from 'Error';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const appConnect: any = {};

const App = () => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  appConnect.dispatch = dispatch;
  appConnect.history = history;
  const { ready } = useTranslation();

  useEffect(() => {
    const versionApp = storage.getVersionApp();
    const newAppVewrsion = '1.0.0';

    if (versionApp !== newAppVewrsion) {
      // TODO
      storage.clearAll();
      storage.setVersionApp(newAppVewrsion);
      window.location.reload();
    }

    window.addEventListener('online', handleOnline);
  }, []);

  function handleOnline() {
    window.location.reload();
  }

  if (auth.loading || !ready) {
    return <Spin size="small" />;
  }

  return (
    <Fragment>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index.toString()}
              path={route.path}
              render={(props) => {
                const { location } = props;
                const routesNow = searchRoute(location);
                const ComponentRoute: any = routesNow?.component;
                if (!routesNow) {
                  return <Redirect to="/404" />;
                }
                if (routesNow?.auth) {
                  const AcessToken = storage.getToken();
                  if (!AcessToken) {
                    return <Redirect to="/auth/login" />;
                  }
                }
                if (routesNow?.layout) {
                  return (
                    <PrivateLayout keyRoute={routesNow.keyRoute} {...props}>
                      <ComponentRoute {...props} />
                    </PrivateLayout>
                  );
                }
                return <ComponentRoute {...props} />;
              }}
            />
          );
        })}
      </Switch>
      <Error />
    </Fragment>
  );
};

export { appConnect };

export default App;
