import { Card, Col, Row, Typography, Button, Modal, Spin, Form } from 'antd';
import { showError } from 'components/common/standby-notice';
import axios from 'axios';
import _ from 'lodash';
import {
  getIncidentList,
  getListInicdentNotQuery,
  IPostReadChatIncidentFormValues,
  IQueryGetIncidentDetail,
  postHideIncident,
  postReadChatAnonymouse,
  IPostHideIcident,
  getCountNewMessage,
  getSurvey,
  updateSurvey,
  checkEnableSurvey,
} from 'features/message/api';
import { messageActions, selectListChats } from 'features/message/messageSlice';
import { sendMessage } from 'lib/socket';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { storage } from 'utils';
import './chat.scss';
import FormChat from './FormChat';
import ListMessage from './ListMessage';
import { postAttchmentFile, upLoad } from 'features/incident/api';
import ListIncident from './ListIncident';
import WhiteBox from 'components/common/icon-svg/whiteBox';
import nProgress from 'nprogress';
import I18n from 'config/i18n';
import {
  IMsgNotification,
  onReceiveNotification,
  initialNotification,
  onReceiveDeleteMessage,
} from 'lib/socket';
import ListDropdown from 'features/incident/pages/report/listDropdown';
import ListCheckBoxList from 'features/incident/pages/report/listCheckBoxList';
import ListRating from 'features/incident/pages/report/listQuestionRating';
import ListDate from 'features/incident/pages/report/listDate';
import ListTextBox from 'features/incident/pages/report/listTextbox';
import ListCheckBox from 'features/incident/pages/report/listCheckbox';
import ListEmail from 'features/incident/pages/report/listEmail';
import { ETypeQuestion } from 'features/incident/pages/report/typeQuestion';
import moment from 'moment';
import { Resizable } from 're-resizable';
const { Title, Text } = Typography;

export interface incidentReportSubmit {
  files: any[];
}
export interface incidentSurveySubmit {
  incident_id: string | null;
  questions: any[];
}

export interface IListSurvey {
  visibility_custom: boolean;
  _id: string;
  question_name: string;
  question_type: string;
  answer_value: any[];
  is_required: boolean;
}
const ChatPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [incidentId, setIncidentId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoadingListIncident, setIsLoadingListIncident] = useState<boolean>(false);
  const [isLoadingEndChat, setIsLoadingEndChat] = useState<boolean>(false);
  const [isLoadingSendSurvey, setIsLoadingSendSurvey] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>(null);
  const [isEnableSurveyAnswered, setIsEnableSurveyAnswered] = useState<boolean>(false);
  const inputRef: any = useRef();
  const inputFileRef: any = useRef();
  const chats = useSelector(selectListChats);
  const [FileMessage, setFileMessage] = useState<incidentReportSubmit>({
    files: [],
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isSurveyAlertModalVisible, setIsSurveyAlertModalVisible] = useState<boolean>(false);
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState<boolean>(false);
  const [listIncident, setListIncdent] = useState<any[]>();
  const [isClosedInputChat, setIsClosedInputChat] = useState<boolean>(false);
  const [answersCheckBoxList, setAnswersCheckBoxList] = useState<any[]>([]);
  const [answersDropDown, setAnswersDropDown] = useState<any[]>([]);
  const [answersRating, setAnswersRating] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [listSurvey, setListSurvey] = useState<IListSurvey[]>();
  const [isAllowServey, setisAllowServey] = useState<boolean>(false);
  const [incidentReport, setIncidentReprot] = useState<incidentSurveySubmit>({
    incident_id: null,
    questions: [],
  });

  const [resizeChat, setResizeChat] = useState<any>({
    height: 200,
    width: 300,
  });
  // const itemSelected?.incident_id: string = storage.getActiveId();
  let incident_number: number = itemSelected?.incident_id
    ? listIncident?.find((e) => e?.incident_id === itemSelected?.incident_id)?.incident_number
    : '';

  const values: any = (location.state as any)?.values;

  const passcode_enabled: boolean = storage.getCurentPasscodeEnable();
  const client_id = storage.getClientId();

  useEffect(() => {
    checkPassCode();
  }, []);

  useEffect(() => {
    const a = new URLSearchParams(history.location.search);
    const icident_id_qs = a.get('incident_id');
    if (icident_id_qs) {
      if (passcode_enabled === true && !values) {
        return history.push('/message/check');
      }
      setIsEnableSurveyAnswered(false);
      setIsLoadingListIncident(true);
      getIncidentList(values)
        .then((res) => {
          if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
            const itemFind = res.data?.find((e) => e?.incident_id === icident_id_qs);
            if (itemFind) {
              setIsClosedInputChat(false);
              setItemSelected(itemFind);
              setIsEnableSurveyAnswered(itemFind?.is_survey_answered ? false : true);
              handleReadMessage(itemFind?.incident_id);
              dispatch(messageActions.updateTotalnotificationSuccess(0));
              setFileMessage({ ...FileMessage, files: [] });
              if (!inputRef?.current) return;
              inputRef?.current!.resetFields();
            }
          }
        })
        .catch((err) => {
          handleListIncidentError(err);
        })
        .finally(() => {
          setIsLoadingListIncident(false);
        });
    }
  }, [history.location.search]);

  useEffect(() => {
    if (client_id) {
      checkEnableSurvey()
        .then((res) => {
          if (res) {
            setisAllowServey(res.data);
          }
        })
        .catch((err) => {})
        .finally(() => {});
      initialNotificationMesage();
      onReceiveNotification(client_id, (msg: IMsgNotification) => {
        if (msg) {
          reloadListIncident();
        }
      });
      onReceiveDeleteMessage(client_id, () => {
        reloadListIncident();
      });
    }
  }, []);

  const initialNotificationMesage = () => {
    const token: string = storage.getToken();
    if (client_id && token) {
      initialNotification(token);
    }
  };

  async function chooseValue(item: any) {
    if (!item) return;
    setIsEnableSurveyAnswered(false);
    history.push({
      pathname: `/message`,
      search: `incident_id=${item.incident_id}`,
      state: { values: values },
    });
    // setIsClosedInputChat(false);
    setItemSelected(item);
    // setIsEnableSurveyAnswered(item?.is_survey_answered ? false : true);
    // // reloadListIncident();
    // handleReadMessage(item?.incident_id);
    // dispatch(messageActions.updateTotalnotificationSuccess(0));
    // setFileMessage({ ...FileMessage, files: [] });
    if (!inputRef?.current) return;
    inputRef?.current!.resetFields();
  }

  const ShowModal = (id: any) => {
    setIsModalVisible(true);
    setIncidentId(id);
  };

  const checkRenderListMessage = () => {
    if (!itemSelected) return false;
    return true;
  };

  const checkRenderInputChat = () => {
    if (!itemSelected) return false;

    if (itemSelected.is_closed) return false;
    if (isClosedInputChat) return false;
    return true;
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const loadingGetListMessageChat = (id: string) => {
    if (id && id !== '') {
      const query: IQueryGetIncidentDetail = {
        incident_id: id,
      };
      dispatch(messageActions.getListMessageSaga(query));
    }
  };

  const handleListIncidentSuccess = (data = []) => {
    if (data.length === 0) {
      setListIncdent([]);
      return;
    }
    const newdata: any[] = data.map((e: any) => {
      return {
        incident_id: e.incident_id,
        is_end_chat: e.is_end_chat,
        incident_number: e.incident_number,
        last_message: e.last_message?.text,
        last_message_is_deleted: e.last_message?.is_deleted,
        files: e.last_message?.files,
        type: e.last_message?.type,
        message_unreads: e.message_unreads,
        total_message: e.total_message,
        org_id: e.last_message?.org_id,
        is_admin: e.last_message?.is_admin,
        createdAt: e.last_message?.createdAt,
        is_closed: e.is_closed,
      };
    });
    setListIncdent(newdata);
  };

  const handleListIncidentError = (err: any) => {};

  const checkPassCode = useCallback(() => {
    setIsLoadingListIncident(true);
    if (passcode_enabled === true) {
      if (!values) {
        return history.push('/message/check');
      }
      return getIncidentList(values)
        .then((res) => {
          if (res) {
            handleListIncidentSuccess(res.data);
          }
        })
        .catch((err) => {
          handleListIncidentError(err);
        })
        .finally(() => {
          setIsLoadingListIncident(false);
        });
    }
    return getListInicdentNotQuery()
      .then((res) => {
        if (res) {
          handleListIncidentSuccess(res.data);
        }
      })
      .catch((err) => {
        handleListIncidentError(err);
      })
      .finally(() => {
        setIsLoadingListIncident(false);
      });
  }, []);

  const chooseInicdentDetail = (id: any) => {
    history.push(`/message/detail/${id}`);
  };

  const handleUploadFile = (fileList: any[]) => {
    setIsLoadingUpload(true);
    let arrayKey: any = [];

    if (fileList?.length > 0) {
      Object.keys(fileList).forEach(function (key) {
        arrayKey = arrayKey.concat(fileList[key]);
      });
      arrayKey = arrayKey.map((e) => {
        return {
          name: encodeURIComponent(e.name),
          type:
            e.name.split('.').pop() === 'heic' || e.name.split('.').pop() === 'HEIC'
              ? 'HEIC'
              : e.type || `${e.name.split('.').pop()}`,
          size: e.size,
          file: e,
        };
      });

      customRequest(arrayKey);
    }
  };

  const customRequest = async (fileList: any[]) => {
    setIsLoadingUpload(true);

    const isAllowed = [
      'image/jpeg',
      'image/png',
      'audio/mpeg',
      'video/mp4',
      'heic',
      'video/quicktime',
      'MOV',
      'mov',
      'jpeg',
      'png',
      'mpeg',
      'mp4',
    ];
    const fileType = fileList?.map((e) => isAllowed.includes(e?.type.toLowerCase()));

    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);

    if (fileType.includes(false)) {
      setIsLoadingUpload(false);

      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.allowed'));
    }

    if (isFileTobig) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.bigfile', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.report.file.small'));
    }
    if (fileList.length > 10 || fileList.length + FileMessage.files.length > 10) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.maxfile', { number: 10 }));
    }

    try {
      let jsonCheck: any = {};

      for (let i = 0; i < fileList.length; i++) {
        jsonCheck[fileList[i].name] = {
          file: fileList[i].file,
          type: fileList[i].type,
        };
      }

      const payload = {
        files: [...fileList].map((eel) => eel.name),
      };
      const Urls = await upLoad(payload);

      const dataRemap = (Urls?.data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonCheck[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });

      nProgress.start();

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          const newFiles: any[] = dataRemap.map((item: any) => {
            return { fileKey: item?.fileKey, original_name: item?.original_name };
          });
          inputFileRef.current.value = '';
          setFileMessage({ ...FileMessage, files: [...FileMessage.files, ...newFiles] });
        })
        .finally(() => {
          nProgress.done();
          setIsLoadingUpload(false);
        });
    } catch (error) {
      inputFileRef.current.value = '';
      setIsLoadingUpload(false);
    }
  };
  const reloadListIncident = useCallback(async () => {
    if (passcode_enabled) {
      if (!values) {
        return history.push('/message/check');
      } else {
        await getIncidentList(values)
          .then((res) => {
            handleListIncidentSuccess(res.data);
          })
          .catch((err) => {
            if (err) {
              if (err?.data?.status.toString() === 'ERROR') {
                showError(I18n.t(`errors.code.${err?.data?.error_code}`));
              }
            }
          });
      }
    } else {
      await getListInicdentNotQuery()
        .then((res) => {
          handleListIncidentSuccess(res.data);
        })
        .catch((err) => {});
    }
  }, [values]);

  const handleReadMessage = async (incident_id: any) => {
    if (incident_id) {
      const payload: IPostReadChatIncidentFormValues = {
        incident_id: incident_id,
      };
      await postReadChatAnonymouse(payload)
        .then(() => {
          reloadListIncident();
        })
        .catch((err) => {});
    }
  };
  const postAttchMentFile = (files: any) => {
    const data = {
      files: files,
      incident_id: itemSelected?.incident_id,
    };

    postAttchmentFile(data)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  const handleRemoveUpload = (file) => {
    const indexPosition = FileMessage.files.findIndex((e) => e.original_name === file.name);
    FileMessage.files.splice(indexPosition, 1);
    setFileMessage({ files: FileMessage.files });
  };

  const onSendMessage = async (item: any) => {
    if (itemSelected?.incident_id) {
      if (FileMessage.files.length > 0) {
        await postAttchMentFile(FileMessage.files);
        const messageSubmit = {
          text: item,
          type: 'file',
          files: FileMessage.files.length,
        };
        setFileMessage({ files: [] });
        await sendMessage(itemSelected?.incident_id, messageSubmit);
      } else {
        if (item?.trim('')) {
          await sendMessage(itemSelected?.incident_id, item?.trim(''));
        }
      }
    }
  };

  const handleGetSurvey = async () => {
    setIsLoadingEndChat(true);
    const req = { incident_id: itemSelected?.incident_id };
    getSurvey(req)
      .then((result) => {
        if (result) {
          setListSurvey(result?.data);
          form.resetFields();
          setIsSurveyModalVisible(true);
        }
      })
      .catch((err) => {
        handleListIncidentError(err);
      })
      .finally(() => {
        setIsLoadingEndChat(false);
      });
  };
  const handleSurveyCancel = async () => {
    setIsSurveyModalVisible(false);
  };

  const changeAnswerCheckboxList = (value: any, data: any) => {
    const body = {
      _id: data._id,
      answer: value,
    };
    const index = answersCheckBoxList.length
      ? answersCheckBoxList.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersCheckBoxList];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: value,
      });
    }
    setAnswersCheckBoxList(array);

    SetAnswerQS(data, value);
  };

  const changeAnswerDropDown = (e: any, data: any) => {
    const body = {
      _id: data._id,
      answer: e,
    };
    const index = answersDropDown.length
      ? answersDropDown.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersDropDown];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: e,
      });
    }
    setAnswersDropDown(array);

    SetAnswerQS(data, e);
  };

  const changeAnswerRating = (e: any, data: any) => {
    const body = {
      _id: data._id,
      answer: e,
    };
    const index = answersRating.length
      ? answersRating.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersRating];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: e,
      });
    }
    setAnswersRating(array);
    SetAnswerQS(data, e);
  };

  const answersDate = (date: any, data: any) => {
    const newDate = moment(date).format('YYYY/MM/DD');
    SetAnswerQS(data, newDate);
  };

  const answerEmail = (e: any, data: any) => {
    SetAnswerQS(data, e.target.value);
  };

  const answerCheckBox = (e: any, data: any) => {
    if (e.target.checked) {
      SetAnswerQS(data, 'yes');
    } else {
      SetAnswerQS(data, 'no');
      form.resetFields(['61b079e83bb462001378034a']);
    }
  };

  const answerTextBox = (e: any, data: any) => {
    SetAnswerQS(data, e.target.value);
  };
  const SetAnswerQS = (data: any, value: any) => {
    const index = incidentReport?.questions.length
      ? incidentReport?.questions.findIndex((item: any) => item.question_id === data._id)
      : 0;

    const body = {
      question_id: data._id,
      answer: value,
    };

    if (index >= 0) {
      incidentReport.questions[index] = body;
    } else {
      incidentReport?.questions.push({
        question_id: data._id,
        answer: value,
      });
    }
  };

  const onFinish = async (values) => {
    let arrayValue: any[] = [];
    _.mapKeys(values, function (value, key) {
      let obj: any = {};
      if (value === true) {
        obj = {
          question_id: key,
          answer: value === true ? 'yes' : 'no',
        };
      } else if (key.split('.')[1] === 'date') {
        if (value) {
          obj = {
            question_id: key.split('.')[0],
            answer: moment(value).format('YYYY/MM/DD'),
          };
        } else {
          obj = {
            question_id: key.split('.')[0],
            answer: '',
          };
        }
      } else {
        obj = {
          question_id: key,
          answer: value ? value : '',
        };
      }

      if (obj?.question_id === 'description' || obj?.question_id === 'files') return;

      arrayValue = arrayValue.concat(obj);
    });

    const valueSend = {
      incident_id: itemSelected?.incident_id,
      survey: arrayValue,
    };

    setIsLoadingSendSurvey(true);

    updateSurvey(valueSend)
      .then((res) => {
        if (res) {
          setIsSurveyModalVisible(false);
          setIsSurveyAlertModalVisible(true);
          setIsEnableSurveyAnswered(false);
          setIsLoadingListIncident(false);
          reloadListIncident();
        }
      })
      .catch((err) => {
        handleListIncidentError(err);
      })
      .finally(() => {
        setIsLoadingSendSurvey(false);
      });
  };

  const handleHideIncident = () => {
    if (incidentId) {
      const data: IPostHideIcident = {
        incident_id: incidentId,
      };
      postHideIncident(data)
        .then(async () => {
          if (Array.isArray(listIncident) && listIncident.length > 1) {
            if (itemSelected?.incident_id === incidentId) {
              const index = listIncident.findIndex(
                (e) => e.incident_id === itemSelected?.incident_id
              );
              storage.clearActiveId();
            }
            reloadListIncident();
            setItemSelected(null);
          } else {
            storage.clearActiveId();
            reloadListIncident();
            setItemSelected(null);
          }
          dispatch(messageActions.getListMessageSaga({ reset: true }));
          const result = await getCountNewMessage();
          dispatch(messageActions.updateTotalnotificationSuccess(result?.data?.message_unreads));
        })
        .catch((err) => {
          console.log('errr ==>', err);
        })
        .finally(() => {
          setIsModalVisible(false);
        });
    }
  };
  return (
    <>
      {Array.isArray(listIncident) && listIncident.length <= 0 ? (
        <Row className="chat_component">
          <Col span={24} className="col_notfound">
            <WhiteBox />
            <Text className="notfound_text">{t('chat.empty')}</Text>
          </Col>
        </Row>
      ) : (
        <Row className="chat_component" gutter={16}>
          <Modal
            width={394}
            zIndex={700}
            visible={isModalVisible}
            footer={null}
            closable={false}
            className="modal-hideincident"
          >
            <Text className="text_modal">{t(`chat.hide.title`)}</Text>
            <div className="content-modal">
              <Button type="ghost" className="button_modal cancel" onClick={handleCancel}>
                {t('chat.hide.cancel')}
              </Button>
              <Button type="primary" className="button_modal" onClick={handleHideIncident}>
                {t('chat.hide.ok')}
              </Button>
            </div>
          </Modal>
          <ListIncident
            itemSelected={itemSelected}
            listIncident={listIncident}
            chooseValue={chooseValue}
            chooseInicdentDetail={chooseInicdentDetail}
            ShowModal={ShowModal}
            reloadListIncident={reloadListIncident}
            isLoadingListIncident={isLoadingListIncident}
          />
          <Col span={16} className="col right">
            <Spin spinning={isLoading}>
              {checkRenderListMessage() && chats ? (
                <Card
                  title={t(`chat.incident.title`, { incident: incident_number })}
                  className="chat_content"
                >
                  <ListMessage
                    chats={chats}
                    loadingGetListMessageChat={loadingGetListMessageChat}
                    itemSelected={itemSelected}
                    setIsClosedInputChat={setIsClosedInputChat}
                  />
                </Card>
              ) : null}
            </Spin>
            {checkRenderInputChat() ? (
              <Card className="card_sendmessage">
                <Resizable
                  enable={{
                    top: true,
                    right: false,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                  }}
                  minHeight={190}
                  maxHeight={600}
                >
                  <FormChat
                    ref={inputRef}
                    isClosed={itemSelected.is_closed}
                    inputFileRef={inputFileRef}
                    listIncident={listIncident}
                    reloadListIncident={reloadListIncident}
                    isLoading={isLoadingUpload}
                    isLoadingListIncident={isLoadingListIncident}
                    handleUploadFile={handleUploadFile}
                    indexFile={FileMessage.files.length}
                    onSendMessage={onSendMessage}
                    handleReadMessage={handleReadMessage}
                    activeIncidentId={itemSelected?.incident_id}
                    isShowButtonAnswered={isAllowServey}
                    isEnableSurveyAnswered={isEnableSurveyAnswered}
                    handleRemoveUpload={handleRemoveUpload}
                    FileMessage={FileMessage}
                    onGetSurvey={handleGetSurvey}
                  />
                </Resizable>
              </Card>
            ) : null}
          </Col>
          <Modal
            width={630}
            zIndex={700}
            visible={isSurveyModalVisible}
            footer={null}
            closable={false}
            className="modal-hideincident modal-survey"
          >
            <Spin spinning={isLoadingSendSurvey}>
              <div className="content-survey">
                <h1>{t('form_create_survey_title')}</h1>
                <div className="description">{t('text_thank_create_survey')}</div>
                <div className="qs_list">
                  <Title level={5}>{t('incident.detail.question_lists')}</Title>
                  <Text className="question-text">
                    ( <span style={{ marginRight: 8 }}></span>
                    {t('incident.detail.question_title')})
                  </Text>
                </div>
                <Form
                  className="form survey-modal"
                  // ref={ref}
                  onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  form={form}
                  autoComplete={'off'}
                >
                  {listSurvey?.map((item, index) => {
                    const valueProps = item;
                    const isDropDown: boolean = item?.question_type === ETypeQuestion.DROPDOWN;
                    const isTextBox: boolean = item?.question_type === ETypeQuestion.TEXTBOX;
                    const isCheckBoxList: boolean =
                      item?.question_type === ETypeQuestion.CHECKBOXLIST;
                    const isMail: boolean = item?.question_type === ETypeQuestion.EMAIL;
                    const isCheckBox: boolean = item?.question_type === ETypeQuestion.CHECKBOX;
                    const isRating: boolean = item?.question_type === ETypeQuestion.RATING;
                    const isDate: boolean = item?.question_type === ETypeQuestion.DATE;
                    return (
                      <Fragment key={index}>
                        {isDropDown && (
                          <ListDropdown
                            listDropdown={valueProps}
                            changeAnswerDropDown={changeAnswerDropDown}
                            answersDropDown={answersDropDown}
                          />
                        )}
                        {isCheckBoxList && (
                          <ListCheckBoxList
                            listCheckBoxList={valueProps}
                            changeAnswerCheckboxList={changeAnswerCheckboxList}
                            answersCheckBoxList={answersCheckBoxList}
                          />
                        )}
                        {isDate && <ListDate listDate={valueProps} answersDate={answersDate} />}
                        {isRating && (
                          <ListRating
                            listRating={valueProps}
                            changeAnswerRating={changeAnswerRating}
                            answersRating={answersRating}
                          />
                        )}

                        {isTextBox && (
                          <ListTextBox listTextBox={valueProps} answersTextBox={answerTextBox} />
                        )}
                        {isMail && <ListEmail listEmail={valueProps} answersEmail={answerEmail} />}
                        {isCheckBox && (
                          <ListCheckBox listCheckBox={valueProps} answerCheckBox={answerCheckBox} />
                        )}
                      </Fragment>
                    );
                  })}
                </Form>
              </div>
              <div className="content-modal">
                <Button
                  className="button_modal cancel chat-survey-cancel"
                  onClick={handleSurveyCancel}
                >
                  {t('chat.survey.cancel')}
                </Button>
                <Button
                  type="primary"
                  className="button_modal chat-survey-ok"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t('chat.survey.ok')}
                </Button>
              </div>
            </Spin>
          </Modal>

          <Modal
            width={604}
            zIndex={700}
            visible={isSurveyAlertModalVisible}
            footer={null}
            closable={false}
            className="modal-hideincident modal-thank"
          >
            <h1>アンケート回答</h1>
            <p className="title">回答完了</p>
            <p className="">
              <img className="top_image" src="/image/success.png" alt="" />
            </p>
            <p className="">アンケートへのご協力ありがとうございました。</p>
            <div className="content-modal">
              <Button
                type="primary"
                className="button_modal"
                onClick={() => {
                  setIsSurveyAlertModalVisible(false);
                }}
              >
                {t('modal.term_of_use.close')}
              </Button>
            </div>
          </Modal>
        </Row>
      )}
    </>
  );
};

export default ChatPage;
