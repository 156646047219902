import { Image, Spin } from 'antd';
import { getFilePreview } from 'features/content/api';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { ReactComponent as Svg } from './img/image_default.svg';

function ImageLoading({ record }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<any>(null);

  const loadingImage = async (fileKey) => {
    setLoading(true);
    try {
      const result = await getFilePreview({
        files: [fileKey],
        is_download: false,
      });
      setUrl(get(result, 'data.0.url'));
    } catch (error) {
      console.log('image loading error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filekey = get(record, 'file_url.0.fileKey');
    if (filekey) {
      loadingImage(filekey);
    }
  }, [record]);

  return (
    <Spin spinning={loading} size="small">
      {url ? <Image className="image" src={url} width={80} preview={false} /> : <Svg />}
    </Spin>
  );
}

export default ImageLoading;
