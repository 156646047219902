import React from 'react';

export default function LinkIcon() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_386:11728)">
          <path
            d="M10.2057 5.79348C12.0728 7.66252 12.0471 10.659 10.2169 12.4994C10.2135 12.5032 10.2094 12.5072 10.2057 12.511L8.10566 14.611C6.25348 16.4632 3.24007 16.4629 1.38816 14.611C-0.464024 12.7591 -0.464024 9.74536 1.38816 7.89348L2.54773 6.73392C2.85523 6.42642 3.38479 6.6308 3.40066 7.06536C3.42091 7.61917 3.52023 8.17558 3.70348 8.71289C3.76554 8.89483 3.7212 9.09608 3.58526 9.23202L3.17629 9.64098C2.30048 10.5168 2.27301 11.9429 3.1402 12.8272C4.01595 13.7203 5.45538 13.7256 6.33785 12.8432L8.43785 10.7435C9.31882 9.86251 9.31513 8.43858 8.43785 7.5613C8.3222 7.44586 8.2057 7.35617 8.1147 7.29352C8.05032 7.24931 7.99716 7.19067 7.95947 7.12227C7.92178 7.05387 7.90059 6.97762 7.8976 6.89958C7.88523 6.56936 8.00223 6.22908 8.26316 5.96814L8.9211 5.31017C9.09363 5.13764 9.36429 5.11645 9.56435 5.25608C9.79347 5.41606 10.0081 5.59589 10.2057 5.79348ZM14.6099 1.38905C12.758 -0.462891 9.74457 -0.463141 7.89238 1.38905L5.79238 3.48905C5.78863 3.4928 5.78457 3.49686 5.78113 3.50061C3.95095 5.34098 3.92529 8.33752 5.79238 10.2065C5.98997 10.4041 6.20456 10.5839 6.43367 10.7439C6.63373 10.8835 6.90442 10.8623 7.07692 10.6898L7.73485 10.0319C7.99579 9.77092 8.11279 9.43064 8.10041 9.10042C8.09742 9.02238 8.07624 8.94613 8.03855 8.87773C8.00085 8.80933 7.9477 8.75069 7.88332 8.70648C7.79232 8.64383 7.67582 8.55414 7.56016 8.4387C6.68288 7.56142 6.6792 6.13748 7.56016 5.25652L9.66016 3.15683C10.5426 2.27436 11.982 2.27967 12.8578 3.17277C13.725 4.05714 13.6976 5.4832 12.8217 6.35902L12.4128 6.76798C12.2768 6.90392 12.2325 7.10517 12.2945 7.28711C12.4778 7.82442 12.5771 8.38083 12.5974 8.93464C12.6133 9.3692 13.1428 9.57358 13.4503 9.26608L14.6099 8.10651C16.4621 6.25467 16.4621 3.24092 14.6099 1.38905Z"
            fill="#21C0F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_386:11728">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
