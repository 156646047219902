import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import SiderBar from './components/SiderBar/SiderBar';
import HeaderComponent from './components/header/HeaderComponent';
import { useEffect, useState } from 'react';
import {
  disconnectSocketNotification,
  IMsgNotification,
  initialNotification,
  onReceiveNotice,
  onReceiveNotification,
  onReceiveNotificationBroadCast,
  onStartMaintaince,
  onStopMaintaince,
} from 'lib/socket';
import { useAppSelector } from 'app/hooks';
import { storage } from 'utils';
import './layout.scss';
import { getHomeInfo } from 'features/home/api';
import {
  messageActions,
  selectNotifications,
  selectTotalNotice,
} from 'features/message/messageSlice';
import { IQueryGetCountUnRead } from 'features/content/api';
import { useDispatch } from 'react-redux';
import { authActions } from 'features/auth/authSlice';
const { Content } = Layout;

interface InfoHomePage {
  organization_id: string;
  organization_name: string;
  home_message: string;
  incident_message: string;
  working_time: string;
  term_url: string;
}

function PrivateLayout(props) {
  const { keyRoute } = props;
  const client_id = storage.getClientId();
  const location: any = useLocation();
  const [info, setInfo] = useState<InfoHomePage>();
  const curentOrganization = storage.getCurentOrganization();
  const currentUser = storage.getCurentUser();
  const [keySearch, setKeySearch] = useState<string>();
  const dispatch = useDispatch();

  const { children } = props;
  const numberMessage = useAppSelector(selectNotifications);
  const numberNotice = useAppSelector(selectTotalNotice);

  useEffect(() => {
    getInfoHome();
    countNewMessageUnread();
    getCountNumber();
  }, []);

  const getInfoHome = () => {
    getHomeInfo()
      .then((result) => {
        const newCurentOrganization = {
          ...curentOrganization,
          organization_name: result?.data?.organization_name,
        };
        storage.setCurentOrganization(newCurentOrganization);

        const index = currentUser.org_data.findIndex(
          (e) => e.organization_id === result?.data?.organization_id
        );

        currentUser.org_data[index] = {
          ...currentUser.org_data[index],
          organization_name: result?.data?.organization_name,
        };

        storage.setCurrentUser(currentUser);

        setInfo(result?.data);
      })
      .catch((err) => { });
  };
  if (location.pathname !== '/message') {
    storage.clearActiveId();
  }
  const loadingSearch = (title: any) => {
    setKeySearch(title);
  };

  const initialNotificationMessage = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (client_id && token) {
        initialNotification(token);
      }
      resolve();
    });

  const getCountNumber = () => {
    const query: IQueryGetCountUnRead = {
      type: 'ALL',
    };
    return dispatch(messageActions.updateTotalContent(query));
  };

  const countNewMessageUnread = async () => {
    return dispatch(messageActions.updateTotalnotification({}));
  };

  const customProps = {
    info: info,
    keySearch,
    setKeySearch,
    getCountNumber,
  };

  useEffect(() => {
    if (client_id) {
      initialNotificationMessage().then(() => {
        onReceiveNotification(client_id, (msg: IMsgNotification) => {
          if (msg) {
            countNewMessageUnread();
          }
        });
        onReceiveNotificationBroadCast((msg: any) => {
          if (msg) {
            getCountNumber();
          }
        });
        onReceiveNotice((msg: any) => {
          if (msg) {
            getCountNumber();
          }
        });
        onStartMaintaince(() => {
          dispatch(authActions.maintenance({ flag: true }));
        });
        onStopMaintaince(() => {
          dispatch(authActions.maintenance({ flag: false }));
          window.location.reload();
        });
      });
    }
    return () => {
      if (client_id) {
        disconnectSocketNotification();
      }
    };
  }, [client_id]);

  return (
    <Layout className="container-fluid">
      <HeaderComponent loadingSearch={loadingSearch} info={info} />
      <Layout className="layout_private">
        <SiderBar newMessage={numberMessage} numberContent={numberNotice} keyRoute={keyRoute} />
        <Content className="main_body">
          {{ ...children, props: { ...children.props, ...customProps } }}
        </Content>
      </Layout>
    </Layout>
  );
}

export default PrivateLayout;
