import { axios } from 'lib';
import { incidentReportSubmit } from '../pages/report/ReportPage';

interface IKeyLinkPreview {
  filekey: string;
}
interface IPostAttchmentFile {
  files?: any[];
  incident_id?: string;
}

const getListQuestion = () => {
  return axios.get('anonymous/organization/questions');
};

const upLoad = (data: any) => {
  return axios.post('anonymous/incident/url-upload', data);
};

const postAttchmentFile = (data: IPostAttchmentFile) => {
  return axios.post('anonymous/incident/attchment-file', data);
};
const getLinkPreview = (payload: IKeyLinkPreview) => {
  return axios.get(`anonymous/upload/url-preview?filekey=${payload}`);
};
const postGetLinkPreview = (data) => {
  return axios.post('amdmin/file/preview', data);
};

const SubmitIncident = (data: incidentReportSubmit) => {
  return axios.post('anonymous/incident/create', data);
};

const getSettingUpload = () => {
  return axios.get('anonymous/organization/upload/settings');
};
export {
  getListQuestion,
  upLoad,
  SubmitIncident,
  getLinkPreview,
  postAttchmentFile,
  postGetLinkPreview,
  getSettingUpload,
};
