function SymbolAuth() {
  return (
    <svg width="72" height="53" viewBox="0 0 72 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2432 13.5526C70.5061 8.61452 67.3072 4.2692 62.6342 2.20139C62.5534 2.15328 62.4572 2.12151 62.3692 2.0734C61.7283 1.80834 61.0539 1.57687 60.3658 1.39986L60.2287 1.35992C54.4174 -0.106977 48.5417 2.0734 45.0224 6.49043C44.7337 6.91524 44.4287 7.3564 44.0847 7.80482C44.0847 7.81299 44.0765 7.81299 44.0765 7.82116C44.0529 7.85293 44.0284 7.87653 44.0039 7.91738C40.3893 13.5925 35.2107 18.0005 29.2142 20.711C29.1906 20.7191 29.1661 20.7346 29.1425 20.7427C28.8457 20.8716 28.5498 20.9987 28.2529 21.1194C28.3573 21.3201 28.4608 21.5043 28.5579 21.6886C29.7434 23.8535 29.8151 24.6941 30.6975 26.7383C31.3311 28.2061 35.6192 37.7609 45.0224 42.0972C50.3372 44.5662 55.6193 44.5744 59.7159 43.9253C59.6759 43.9653 59.636 43.997 59.5879 44.037C59.5316 44.086 59.4753 44.1332 59.419 44.1895C58.7391 44.7514 58.0384 45.2878 57.3194 45.7971C58.0384 45.2878 58.7391 44.7514 59.419 44.1895C62.105 41.9764 64.4697 39.364 66.4331 36.4147C68.4292 33.4002 70.0086 30.0416 71.0425 26.3861L71.0907 26.1855C72.0846 21.7521 72.1246 17.3197 71.2432 13.5526Z"
        fill="#FFA4C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.716 43.9252C55.6194 44.5743 50.3373 44.5661 45.0225 42.0971C35.6193 37.7608 31.3312 28.206 30.6976 26.7382C29.8162 24.694 29.7436 23.8534 28.5581 21.6885C28.461 21.5042 28.3575 21.32 28.2531 21.1194C25.7841 22.1542 23.1789 22.8912 20.4938 23.3242C20.4856 23.3315 20.4775 23.3315 20.4702 23.3397C16.6459 24.222 12.5811 24.2855 8.51807 23.3878C7.58765 23.267 6.68173 23.0919 5.79215 22.8504C5.78398 22.8504 5.76764 22.8422 5.76038 22.8422C5.74404 22.835 5.72044 22.8268 5.7041 22.8186C3.77971 22.0815 2.09677 21.1593 0.661643 20.1735C0.485543 20.9986 0.389324 21.8247 0.364815 22.6507C0.356645 23.2516 0.381154 23.8371 0.437433 24.4226C0.445603 24.4625 0.445603 24.4943 0.453773 24.5261C0.614441 25.4955 0.830482 26.4586 1.08646 27.4045C3.85232 37.3124 12.0528 46.0012 23.315 50.0252C23.3477 50.0406 23.3795 50.0497 23.4112 50.066C24.8382 50.5145 26.129 50.8349 27.2355 51.0591C27.5151 51.139 27.7965 51.2116 28.0843 51.2833C30.6341 51.9251 33.1757 52.2691 35.7001 52.3499C36.4853 52.3744 37.2632 52.3744 38.0403 52.3417C38.3616 52.3345 38.682 52.3181 39.0034 52.2945C39.3719 52.2773 39.7405 52.2455 40.109 52.2056C47.2519 51.5239 53.9773 48.6863 59.4192 44.1894C59.4755 44.1331 59.5318 44.0859 59.588 44.0369C59.6361 43.9969 59.6761 43.9652 59.716 43.9252Z"
        fill="#18BCF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75994 22.8427C5.76811 22.8427 5.78354 22.8509 5.79171 22.8509C6.68219 23.0914 7.58811 23.2675 8.51763 23.3882C12.5815 24.286 16.6455 24.2224 20.4697 23.3401C20.4779 23.332 20.4861 23.332 20.4933 23.3238C23.1784 22.8917 25.7845 22.1537 28.2527 21.1198C28.1256 20.8874 27.9803 20.6378 27.836 20.3737C26.0732 17.3282 22.947 12.7424 17.536 11.1638C16.9986 10.995 9.10312 8.81459 4.03706 13.8089C3.32267 14.5142 2.69815 15.3321 2.17712 16.2217C1.92023 16.6619 1.68785 17.1358 1.47907 17.616C1.1187 18.4502 0.846379 19.3071 0.662109 20.174C2.09723 21.1598 3.77926 22.0811 5.70366 22.8191C5.72 22.8264 5.7436 22.8345 5.75994 22.8427Z"
        fill="#18C9F3"
      />
    </svg>
  );
}

export default SymbolAuth;
