import { LeftOutlined } from '@ant-design/icons';
import { Col, Row, Layout, Button, Typography } from 'antd';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { storage } from 'utils';
import styles from './index.module.scss';

const { Content } = Layout;
const { Title, Text } = Typography;
const Info = () => {
  const passcode_enabled: boolean = storage.getCurentPasscodeEnable();
  const { t } = useTranslation();
  const history = useHistory();
  const token = storage.getToken();
  const goBack = () => {
    history.goBack();
  };

  if (!token) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <HeaderComponent />
      <Content className={styles.layout_container}>
        <Row className={styles.container}>
          <Col className={styles.card_passcode} xs={20} sm={16} md={12} lg={12}>
            <Text className={styles.text_goback} onClick={() => goBack()}>
              <LeftOutlined style={{ marginRight: '12px' }} />
              {t('login.back')}
            </Text>
            <div className={styles.card}>
              <Title className={styles.card_title}>{t('passcode.new.title')}</Title>
              <div>
                <Text className={styles.card_text}>
                  {passcode_enabled
                    ? t('passcode.edit.description')
                    : t('passcode.new.description')}
                </Text>
              </div>

              {passcode_enabled ? (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.btn}
                    onClick={() => {
                      history.push({
                        pathname: '/passcode/change',
                      });
                    }}
                  >
                    {t('passcode.edit.reset_btn')}
                  </Button>

                  <Button
                    type="ghost"
                    htmlType="submit"
                    className={styles.btn_remove}
                    onClick={() => {
                      history.push({
                        pathname: '/',

                        state: { action: 'REMOVE', forceRemove: true },
                      });
                    }}
                  >
                    {t('passcode.edit.remove_btn')}
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.btn}
                  onClick={() => {
                    history.push({
                      pathname: '/passcode/newpass',
                      state: { action: 'ADD' },
                    });
                  }}
                >
                  {t('passcode.new.set_new_code')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Info;
