import { axios } from 'lib';

export const BASE_URL_CHAT = 'anonymous/notification';

export interface IPayloadUpdateTokenNotification {
  token_device: string;
}

const update_token_device = (payload: IPayloadUpdateTokenNotification) => {
  return axios.post(`${BASE_URL_CHAT}/update_token_device`, payload);
};

export { update_token_device };
