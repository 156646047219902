import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IQueryGetIncidentDetail, IQueryGetListIncident } from './api';

export type IMessage = {
  admin_read: Date | null;
  anonymous_read: Date | null;
  createdAt: Date;
  files: number;
  incident_id: string;
  org_id: string;
  owner_incident_id: string;
  sender_id?: object | null;
  text: string;
  textMessageFile?: string;
  type: string;
  updatedAt: Date;
  __v: number;
  _id: string;
};

export interface IPayloadAppendMessageIncidents {
  msg: IMessage;
}

interface IIncidentsSelector {
  isLoading: boolean;
  listIncident?: any[];
  chats?: any;
  error?: any;
  notifications: number;
  totalNotice: number;
}

const initialState: IIncidentsSelector = {
  chats: [],
  listIncident: [],
  isLoading: false,
  notifications: 0,
  totalNotice: 0,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    // get list message
    getListMessageSaga(state, action: PayloadAction<IQueryGetIncidentDetail>) {
      state.isLoading = true;
    },
    getListMessageSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.chats = action.payload;
    },
    getListMessageFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // get list incident report
    getListIncidentReport(state, action: PayloadAction<IQueryGetListIncident>) {
      state.isLoading = true;
    },
    getListIncidentReportSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.listIncident = action.payload;
    },
    getListIncidentReportFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // get list incident not query param
    getListIncidentNotParams(state) {
      state.isLoading = true;
    },
    getListIncidentNotParamsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.listIncident = action.payload;
    },
    getListIncidentNotParamsFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // append msg to chat
    appendListMessageSaga(state, action: PayloadAction<IPayloadAppendMessageIncidents>) {
      state.isLoading = true;
    },
    appendMessageSuccess(state, action: PayloadAction<IMessage>) {
      state.isLoading = false;
      state?.chats?.push(action.payload);
    },
    appendListMessageFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateTotalnotification(state, action: PayloadAction<any>) {},
    updateTotalnotificationSuccess(state, action: PayloadAction<any>) {
      state.notifications = action.payload;
    },
    updateTotalContent(state, action: PayloadAction<any>) {},
    updateTotalContentSuccess(state, action: PayloadAction<any>) {
      state.totalNotice = action.payload;
    },
  },
});

// Actions
export const messageActions = messageSlice.actions;

// Selectors
export const selectIsLogging = (state: RootState) => state.message;
export const selectListChats = (state: RootState) => state.message.chats;
export const selectListIncident = (state: RootState) => state.message.listIncident;
export const selectNotifications = (state: RootState) => state.message.notifications;
export const selectTotalNotice = (state: RootState) => state.message.totalNotice;
// Reducer
const messageReducer = messageSlice.reducer;
export default messageReducer;
