import { Form, Input } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  listTextBox: any;
  answersTextBox: (e, data) => void;
}

export default function ListTextBox({ listTextBox, answersTextBox }: IProps) {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        className={listTextBox?.is_required ? 'textbox' : 'before'}
        name={listTextBox?._id}
        label={listTextBox?.question_name}
        rules={[
          {
            required: listTextBox?.is_required,
            message: t('report.new.question_lists.required'),
          },
          {
            whitespace: true,
            message: t('report.new.description.whitespace'),
          },
        ]}
      >
        <Input.TextArea
          maxLength={256}
          placeholder={t('report.new.input')}
          className="input"
          autoSize={{ minRows: 1, maxRows: 6 }}
          onChange={(e) => answersTextBox(e, listTextBox)}
        />
      </Form.Item>
    </>
  );
}
