// eslint-disable-next-line react-hooks/exhaustive-deps
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { Row, Col, Typography, List, Button, Form, Spin } from 'antd';
import styles from './select.module.scss';
import { Content, Footer } from 'antd/lib/layout/layout';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';
import axios from 'axios';
import { storage } from 'utils';
import { apiPostValueSelect, ILoginAcessCode, postAcessCode } from '../../api';
import { showError } from 'components/common/standby-notice';
import ModalTerm from 'components/Layout/components/SiderBar/ModalTerm';
import { EType } from 'components/Layout/components/SiderBar/types';
import { get } from 'lodash';
import { API_URL } from 'config';
import { removeAllData } from 'lib';

const { Title, Text } = Typography;

let modalTermVisible;

function SelectPage() {
  const { t } = useTranslation();
  const [infoSelect, setinfoSelect] = useState<ILoginAcessCode>();
  const [activeIndex, setActiveIndex] = useState<any>();
  const [listValue, setlistValue] = useState<any>();
  const [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const history: any = useHistory();
  const location: any = useLocation();
  const RefSelect: any = useRef();
  const locationState: any = location?.state;

  const locationPathName: string = location?.pathname;

  const state = storage.getCurrentState();

  useEffect(() => {
    checkAuthLogin();
  }, []);

  const checkAuthLogin = () => {
    if (locationState) {
      setlistValue({ ...locationState?.data });
    } else {
      if (state) {
        getValueSelect(state);
      }
    }
  };

  const getValueSelect = (state) => {
    setIsLoadingLogin(true);
    if (state) {
      if (Array.isArray(state.list)) {
        if (state?.list.length === 1) {
          const fisrtState = state?.list[0];
          postAcessCode({
            challenger_answer: fisrtState.challenger_answer,
            challenger_name: fisrtState.challenger_name,
            platform: fisrtState.platform,
            session: fisrtState.session,
            access_code: fisrtState.access_code,
            client_id: fisrtState.client_id,
          })
            .then((res) => {
              setlistValue(res.data);
              setName(state?.list[0].access_code);
              setIsLoadingLogin(false);
            })
            .catch((err) => {
              showError(t(`errors.code.${err?.data?.error_code}`));
              removeAllData();
              return history.push('/auth/login');
            })
            .finally(() => {
              setIsLoadingLogin(false);
            });
        } else {
          const deftaultList = state?.list[state?.list.length - 1];

          const dataReloadSelect = {
            challenger_name: deftaultList?.challenger_name,
            challenger_answer: deftaultList?.challenger_answer,
            session: deftaultList?.session,
            platform: state.list[0]?.platform,
          };

          apiPostValueSelect(dataReloadSelect)
            .then((res) => {
              setlistValue(res.data);
              setIsLoadingLogin(false);
            })
            .catch((err) => {
              showError(t(`errors.code.${err?.data?.error_code}`));
              removeAllData();
              return history.push('/auth/login');
            })
            .finally(() => {
              setIsLoadingLogin(false);
            });
        }
      } else {
        const dataSubmit = { ...state };
        postAcessCode(dataSubmit)
          .then((res) => {
            setlistValue(res.data);
            setName(state?.access_code);

            setIsLoadingLogin(false);
          })
          .catch((err) => {
            showError(t(`errors.code.${err?.data?.error_code}`));
            removeAllData();
            return history.push('/auth/login');
          })
          .finally(() => {
            setIsLoadingLogin(false);
          });
      }
    }
  };

  function chooseValue(item: any) {
    storage.setCurrentState(state);

    setActiveIndex(item.value);
    setName(item.name);
    setinfoSelect({
      ...infoSelect,
      challenger_answer: item.value,
      challenger_name: listValue.challenger_name,
      session: listValue.session,
      platform: state.list[0]?.platform,
    });
  }

  function onFinish() {
    loadingSelectValue();
  }

  const loadingSelectValue = () => {
    // TODO refactoring
    let dataSelect: ILoginAcessCode;

    if (
      Array.isArray(state.list) &&
      state.list[state.list.length - 1].selectedValue &&
      !activeIndex
    ) {
      dataSelect = {
        challenger_answer: state?.list[state.list.length - 1].selectedValue,
        challenger_name: state?.list[state.list.length - 1].selected_changeller_name,
        session: state?.list[state.list.length - 1].selected_session,
        platform: state?.list[state.list.length - 1].platform,
      };
    } else {
      dataSelect = { ...infoSelect };
    }

    if (!dataSelect.challenger_answer) return;
    if (!dataSelect.challenger_name) return;
    if (!dataSelect.session) return;
    setIsLoadingLogin(true);

    apiPostValueSelect(dataSelect)
      .then((res) => {
        let currentState: any;
        if (activeIndex) {
          currentState = {
            ...dataSelect,
            name: name,
            pathname: locationPathName,
          };
        } else {
          currentState = {
            ...dataSelect,
            name: state?.list[state.list.length - 1].selected_name,
            pathname: locationPathName,
          };
        }

        state?.list?.push(currentState);

        storage.setCurrentState(state);

        if (res.data?.challenger_name === 'TERMS') {
          history.push({
            pathname: '/auth/term',
            state: {
              data: res.data,
              platform: infoSelect?.platform,
              session: dataSelect.session,
              challenger_name: dataSelect.challenger_name,
              choice_name: res?.data?.organization_name || name,
              challenger_answer: dataSelect.challenger_answer,
            },
          });
        } else {
          setlistValue(res.data);
          setinfoSelect({
            ...infoSelect,
            challenger_answer: '',
            challenger_name: '',
            session: '',
            platform: '',
          });
        }
        setIsLoadingLogin(false);
      })
      .catch((err) => {
        if (err?.status !== 503) {
          showError(t(`errors.code.${err?.data?.error_code}`));
        }
        removeAllData();
        return history.push('/auth/login');
      })
      .finally(() => {
        setIsLoadingLogin(false);
      });
  };

  const goBack = () => {
    if (Array.isArray(state?.list)) {
      if (state?.list.length === 1) {
        history.push(state?.list[0]?.pathname);
        storage.clearCurrentState();
      } else {
        if (state?.list[state.list.length - 1].challenger_answer && state?.list.length > 1) {
          const dataChange = {
            ...state?.list[state.list.length - 2],
            selectedValue: state?.list[state.list.length - 1].challenger_answer,
            selected_changeller_name: state?.list[state.list.length - 1].challenger_name,
            selected_choicename: state?.list[state.list.length - 1].choice_name,
            selected_session: state?.list[state.list.length - 1].session,
            selected_name: state?.list[state.list.length - 1].name,
          };

          state.list[state.list.length - 2] = dataChange;

          state.list.splice(state.list.length - 1, 1);

          storage.setCurrentState(state);
          getValueSelect(state);
        }
      }
    }
    setActiveIndex(null);
  };

  const handleShowModalPDF = (type: string) => {
    getPreviewPDF(type);
  };

  const getPreviewPDF = async (type: string) => {
    const query = {
      platform: 'WEB_APP',
      category: type,
    };
    try {
      const result = await axios.get(API_URL + '/terms', { params: query });
      const url = get(result, 'data.data.0.url');
      if (url) {
        modalTermVisible?.({
          visible: true,
          type,
          url,
        });
      }
    } catch (error) {
      showError(t('file.preview.error'));
    }
  };

  let onceTime: boolean = false;

  useEffect(() => {
    if (onceTime) return;
    onceTime = true;
    if (!listValue) return;
    executeScroll();
  }, [listValue]);
  const executeScroll = () => {
    RefSelect.current?.scrollIntoView();
  };

  if (!state) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      <HeaderComponent />
      <Content className="layout_select">
        <Spin spinning={isLoadingLogin} className="spin-auth">
          <Row className={styles.container}>
            <Col className={styles.cardSelect} xs={20} sm={16} md={12} lg={12}>
              <Text className={styles.goBack} onClick={() => goBack()}>
                <LeftOutlined style={{ marginRight: '10px' }} />
                {t('login.back')}
              </Text>
              <div className={styles.card}>
                <Title level={5} className={styles.title}>
                  {t('login.selected')}
                </Title>
                <Title level={4} className="org_name">
                  {state?.list?.length === 1
                    ? locationState?.choice_name || state?.list[0]?.name
                    : state?.list[state?.list?.length - 1]?.name}
                </Title>
                <Form className={styles.formItem} onFinish={onFinish}>
                  <Form.Item name="selectOption" className={styles.select}>
                    {listValue?.choices && listValue?.choices?.length > 0 ? (
                      <List className={styles.listItem} size="large" bordered>
                        {listValue?.choices &&
                          listValue?.choices?.length > 0 &&
                          listValue?.choices.map((item: any, index: any) => {
                            const background =
                              state?.list.length > 0 &&
                              item.value === state?.list[state?.list?.length - 1].selectedValue
                                ? '#21C0F6'
                                : '';
                            const backgroundActiveIndex =
                              activeIndex === item.value ? '#21C0F6' : '';
                            const isCurrentElement =
                              item.value === state?.list[state?.list?.length - 1].selectedValue;
                            return (
                              <Fragment key={index}>
                                <div ref={isCurrentElement ? RefSelect : null}></div>
                                <List.Item
                                  style={{
                                    background: activeIndex ? backgroundActiveIndex : background,
                                  }}
                                  onClick={() => chooseValue(item)}
                                >
                                  {item?.name}
                                </List.Item>
                              </Fragment>
                            );
                          })}
                      </List>
                    ) : (
                      <div className="list-empty">{t('login.step2.list_org_empty')}</div>
                    )}
                  </Form.Item>

                  <Form.Item className={styles.btnItem}>
                    <Button
                      disabled={listValue?.choices && listValue?.choices?.length === 0}
                      type="primary"
                      htmlType="submit"
                      className={`${styles.btn} ${
                        listValue?.choices && listValue?.choices?.length === 0
                          ? styles.btnDisabled
                          : ''
                      }`}
                    >
                      {t('login.next')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer
            className="footer_select"
            style={{ background: 'transparent', padding: '16px 0 14px 0' }}
          >
            {t('©STANDBY.')}
            <Title
              className="footer_title"
              level={5}
              onClick={() => handleShowModalPDF(EType.TERM)}
            >
              {t('footer.term_of_use')}
            </Title>
          </Footer>
        </Spin>
      </Content>
      <ModalTerm onRef={(ref) => (modalTermVisible = ref)} />
    </>
  );
}

export default SelectPage;
