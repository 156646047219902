import React from 'react';

export default function ReportIcon() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.824 4.28727C19.6179 2.90617 18.7232 1.69085 17.4162 1.11251C17.3936 1.09906 17.3667 1.09017 17.3421 1.07671C17.1628 1.00258 16.9742 0.937843 16.7818 0.888337L16.7434 0.877166C15.1181 0.466897 13.4747 1.07671 12.4905 2.31209C12.4097 2.43091 12.3244 2.55429 12.2282 2.67971C12.2282 2.68199 12.2259 2.68199 12.2259 2.68428C12.2193 2.69316 12.2125 2.69976 12.2056 2.71119C11.1947 4.29844 9.74628 5.53128 8.06915 6.28936C8.06255 6.29164 8.0557 6.29596 8.04909 6.29824C7.96608 6.3343 7.88331 6.36984 7.80029 6.4036C7.82949 6.45971 7.85843 6.51125 7.8856 6.56279C8.21716 7.16829 8.23722 7.40338 8.48399 7.97512C8.6612 8.38564 9.86052 11.058 12.4905 12.2707C13.9769 12.9613 15.4542 12.9636 16.6 12.7821C16.5888 12.7932 16.5777 12.8021 16.5642 12.8133C16.5485 12.827 16.5327 12.8402 16.517 12.8559C16.3268 13.0131 16.1308 13.1631 15.9298 13.3056C16.1308 13.1631 16.3268 13.0131 16.517 12.8559C17.2682 12.237 17.9296 11.5063 18.4787 10.6815C19.037 9.83833 19.4787 8.89898 19.7679 7.87661L19.7814 7.8205C20.0594 6.58056 20.0705 5.34087 19.824 4.28727Z"
        fill="#FFA4C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6001 12.782C15.4543 12.9636 13.977 12.9613 12.4905 12.2707C9.86058 11.0579 8.66126 8.38561 8.48405 7.97509C8.23753 7.40336 8.21722 7.16826 7.88566 6.56276C7.85849 6.51122 7.82955 6.45969 7.80035 6.40358C7.1098 6.693 6.38117 6.89915 5.6302 7.02025C5.62791 7.02228 5.62563 7.02228 5.6236 7.02457C4.554 7.27134 3.41713 7.28911 2.28077 7.03802C2.02054 7.00426 1.76717 6.95526 1.51837 6.88773C1.51609 6.88773 1.51152 6.88544 1.50949 6.88544C1.50492 6.88341 1.49832 6.88113 1.49375 6.87884C0.955522 6.67269 0.48483 6.41475 0.0834474 6.13904C0.0341948 6.36981 0.00728365 6.60084 0.000428918 6.83187C-0.00185599 6.99994 0.00499874 7.16369 0.0207392 7.32745C0.0230241 7.33862 0.0230242 7.3475 0.0253091 7.35639C0.0702456 7.62753 0.130669 7.8969 0.202263 8.16144C0.975832 10.9325 3.26938 13.3627 6.41925 14.4881C6.42839 14.4924 6.43728 14.495 6.44616 14.4995C6.84526 14.6249 7.20628 14.7146 7.51576 14.7773C7.59395 14.7996 7.67265 14.8199 7.75313 14.84C8.46628 15.0195 9.17714 15.1157 9.88318 15.1383C10.1028 15.1451 10.3204 15.1451 10.5377 15.136C10.6276 15.134 10.7172 15.1294 10.807 15.1228C10.9101 15.118 11.0132 15.1091 11.1163 15.0979C13.114 14.9073 14.995 14.1136 16.517 12.8559C16.5328 12.8402 16.5485 12.827 16.5643 12.8133C16.5777 12.8021 16.5889 12.7932 16.6001 12.782Z"
        fill="#18BCF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50928 6.88557C1.51157 6.88557 1.51588 6.88785 1.51817 6.88785C1.76722 6.95513 2.02059 7.00438 2.28057 7.03815C3.41718 7.28924 4.5538 7.27146 5.62339 7.02469C5.62568 7.02241 5.62796 7.02241 5.62999 7.02012C6.38097 6.89928 7.10985 6.69287 7.80015 6.40371C7.76461 6.33871 7.72399 6.2689 7.68362 6.19502C7.19059 5.34325 6.31623 4.06066 4.80285 3.61916C4.65256 3.57194 2.44432 2.96212 1.02742 4.35896C0.827615 4.55623 0.652947 4.78497 0.50722 5.03377C0.435372 5.15691 0.370379 5.28943 0.311987 5.42373C0.211197 5.65705 0.135034 5.89671 0.0834961 6.13916C0.484879 6.41488 0.955317 6.67256 1.49354 6.87897C1.49811 6.881 1.50471 6.88328 1.50928 6.88557Z"
        fill="#18C9F3"
      />
    </svg>
  );
}
