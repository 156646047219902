// eslint-disable-next-line react-hooks/exhaustive-deps
import { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Spin, Row, Col, Form, Input, Button, Typography, FormInstance, Space } from 'antd';
import { useHistory, Redirect } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { showError, showSuccess } from 'components/common/standby-notice';
import './index.scss';
import { getListQuestion, SubmitIncident, upLoad, getSettingUpload } from 'features/incident/api';
import ModalGlobal from 'components/common/showModal/modalGlobal';
import { storage } from 'utils';
import moment from 'moment';
import AttchFileIcon from 'components/common/icon-svg/attch';
import Close from 'components/common/icon-svg/close';
import ListDropdown from './listDropdown';
import ListDate from './listDate';
import ListTextBox from './listTextbox';
import ListRating from './listQuestionRating';
import ListEmail from './listEmail';
import ListCheckBoxList from './listCheckBoxList';
import ListCheckBox from './listCheckbox';
import nProgress from 'nprogress';
import { ETypeQuestion } from './typeQuestion';
import { getHomeInfo } from 'features/home/api';
import { API_URL } from 'config';

const { Title, Text } = Typography;
const { TextArea } = Input;

let maxUpload = 0;
export interface incidentReportSubmit {
  org_id: string | null;
  description: string;
  files: any[];
  questions: any[];
}

export interface IListQuestion {
  visibility_custom: boolean;
  _id: string;
  question_name: string;
  question_type: string;
  answer_value: any[];
  is_required: boolean;
}

interface InfoHomePage {
  organization_id: string;
  organization_name: string;
  home_message: string;
  incident_message: string;
  working_time: string;
  term_url: string;
}

function ReportPage() {
  const { t } = useTranslation();
  const ref = useRef<FormInstance>(null);
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState<boolean>(false);
  const [isLoadingSubmitIncident, setIsLoadingSubmitIncident] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>('');
  const [visiable, setVisiable] = useState<boolean>(false);
  const [answersCheckBoxList, setAnswersCheckBoxList] = useState<any[]>([]);
  const [answersDropDown, setAnswersDropDown] = useState<any[]>([]);
  const [answersRating, setAnswersRating] = useState<any[]>([]);
  const [info, setInfo] = useState<InfoHomePage>();
  const [incidentReport, setIncidentReprot] = useState<incidentReportSubmit>({
    org_id: null,
    description: '',
    files: [],
    questions: [],
  });
  const [listQuestion, setListQuestion] = useState<any[]>();
  const [isAllowUpload, setIsAllowUpload] = useState<boolean>(false);
  const token = storage.getToken();
  const user = storage.getCurentOrganization();
  const inputFileRef: any = useRef();
  useEffect(() => {
    getListValueQuestion();
    getInfoHome();
    getSettingUploadFile();
  }, []);

  const getSettingUploadFile = () => {
    getSettingUpload()
      .then((result) => {
        if (result) {
          setIsAllowUpload(result?.data);
        }
      })
      .catch((err) => {});
  };

  const getListValueQuestion = () => {
    setIsLoadingQuestion(true);
    getListQuestion()
      .then((result) => {
        if (result) {
          const data = result.data;
          setListQuestion(data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingQuestion(false);
      });
  };

  const changeAnswerCheckboxList = (value: any, data: any) => {
    const body = {
      _id: data._id,
      answer: value,
    };
    const index = answersCheckBoxList.length
      ? answersCheckBoxList.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersCheckBoxList];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: value,
      });
    }
    setAnswersCheckBoxList(array);

    SetAnswerQS(data, value);
  };

  const changeAnswerDropDown = (e: any, data: any) => {
    const body = {
      _id: data._id,
      answer: e,
    };
    const index = answersDropDown.length
      ? answersDropDown.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersDropDown];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: e,
      });
    }
    setAnswersDropDown(array);

    SetAnswerQS(data, e);
  };

  const changeAnswerRating = (e: any, data: any) => {
    const body = {
      _id: data._id,
      answer: e,
    };
    const index = answersRating.length
      ? answersRating.findIndex((item: any) => item._id === data._id)
      : 0;

    const array = [...answersRating];

    if (index >= 0) {
      array[index] = body;
    } else {
      array.push({
        _id: data._id,
        answer: e,
      });
    }
    setAnswersRating(array);
    SetAnswerQS(data, e);
  };

  const answersDate = (date: any, data: any) => {
    const newDate = moment(date).format('YYYY/MM/DD');
    SetAnswerQS(data, newDate);
  };

  const answerEmail = (e: any, data: any) => {
    SetAnswerQS(data, e.target.value);
  };

  const answerCheckBox = (e: any, data: any) => {
    if (e.target.checked) {
      SetAnswerQS(data, 'yes');
    } else {
      SetAnswerQS(data, 'no');
      form.resetFields(['61b079e83bb462001378034a']);
    }
  };

  const answerTextBox = (e: any, data: any) => {
    SetAnswerQS(data, e.target.value);
  };
  const SetAnswerQS = (data: any, value: any) => {
    const index = incidentReport?.questions.length
      ? incidentReport?.questions.findIndex((item: any) => item.question_id === data._id)
      : 0;

    const body = {
      question_id: data._id,
      answer: value,
    };

    if (index >= 0) {
      incidentReport.questions[index] = body;
    } else {
      incidentReport?.questions.push({
        question_id: data._id,
        answer: value,
      });
    }
  };

  const handleClickUpload = () => {
    if (incidentReport?.files?.length >= 10) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';

      return showError(t('chat.menu.check.maxfile', { number: 10 }));
    }
    return inputFileRef.current?.click?.();
  };

  const handleUploadFile = (fileList: any[]) => {
    if (incidentReport?.files?.length + fileList.length > 10) {
      return showError(t('chat.menu.check.maxfile', { number: 10 }));
    }

    let arrayKey: any = [];

    if (fileList?.length > 0) {
      const url_refresh = API_URL + '/anonymous/refresh_token';
      fetch(url_refresh, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: storage.getRefreshToken(),
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          storage.setToken(response?.data?.access_token);
          Object.keys(fileList).forEach(function (key) {
            arrayKey = arrayKey.concat(fileList[key]);
          });
          arrayKey = arrayKey.map((e) => {
            return {
              name: encodeURIComponent(e.name),
              type:
                e.name.split('.').pop() === 'heic' || e.name.split('.').pop() === 'HEIC'
                  ? 'HEIC'
                  : e.type || `${e.name.split('.').pop()}`,
              size: e.size,
              file: e,
            };
          });

          customRequest(arrayKey);
        });
    }
  };

  const customRequest = async (fileList: any[]) => {
    const isAllowed = [
      'image/jpeg',
      'image/png',
      'audio/mpeg',
      'video/mp4',
      'heic',
      'HEIC',
      'video/quicktime',
      'MOV',
      'mov',
      'jpeg',
      'png',
      'mpeg',
      'mp4',
    ];
    const fileType = fileList?.map((e) => isAllowed.includes(e?.type.toLowerCase()));

    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);

    if (fileType.includes(false)) {
      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.allowed'));
    }

    if (isFileTobig) {
      inputFileRef.current.value = '';

      return showError(t('chat.menu.check.bigfile', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      inputFileRef.current.value = '';

      return showError(t('chat.report.file.small'));
    }

    try {
      let jsonCheck: any = {};

      for (let i = 0; i < fileList.length; i++) {
        jsonCheck[fileList[i].name] = {
          file: fileList[i].file,
          type: fileList[i].type,
        };
      }

      const payload = {
        files: [...fileList].map((eel) => eel.name),
      };
      setIsLoadingUpload(true);
      const Urls = await upLoad(payload);

      const dataRemap = (Urls?.data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonCheck[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });

      nProgress.start();

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          const newFiles: any[] = dataRemap.map((item: any) => {
            return { fileKey: item?.fileKey, original_name: item?.original_name };
          });
          inputFileRef.current.value = '';
          setIncidentReprot({ ...incidentReport, files: [...incidentReport.files, ...newFiles] });
        })
        .finally(() => {
          nProgress.done();
          inputFileRef.current.value = '';
          setIsLoadingUpload(false);
        });
    } catch (error) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setTypeModal('submit');
    setIsLoadingSubmitIncident(false);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e?.fileList) && e?.fileList.length > 0) return;
  };

  const onFinish = (values) => {
    let arrayValue: any[] = [];
    _.mapKeys(values, function (value, key) {
      let obj: any = {};
      if (value === true) {
        obj = {
          question_id: key,
          answer: value === true ? 'yes' : 'no',
        };
      } else if (key.split('.')[1] === 'date') {
        if (value) {
          obj = {
            question_id: key.split('.')[0],
            answer: moment(value).format('YYYY/MM/DD'),
          };
        } else {
          obj = {
            question_id: key.split('.')[0],
            answer: '',
          };
        }
      } else {
        obj = {
          question_id: key,
          answer: value ? value : '',
        };
      }

      if (obj?.question_id === 'description' || obj?.question_id === 'files') return;

      arrayValue = arrayValue.concat(obj);
    });

    const valueSend = {
      ...incidentReport,
      questions: arrayValue,
    };

    setIsLoadingSubmitIncident(true);

    SubmitIncident(valueSend)
      .then((res) => {
        setTimeout(() => {
          setIsLoadingSubmitIncident(false);
        }, 1000);
        if (res) {
          showSuccess(t('report.finish.title'));
          if (res.data.message) {
            history.push({
              pathname: '/success',
              state: { message: res.data.message },
            });
          } else {
            history.push({
              pathname: '/success',
            });
          }
        }
      })
      .catch((err) => {
        setIsLoadingSubmitIncident(false);
      });
  };

  const handleGoback = () => {
    setTypeModal('back');
    setVisiable(true);
  };

  const removeUploadFile = () => {
    setTypeModal('remove');
    setVisiable(true);
    maxUpload = 0;
  };
  const handleOk = () => {
    setIsLoadingUpload(true);

    if (typeModal === 'submit') {
      setVisiable(false);
      setIsLoadingSubmitIncident(false);
    } else if (typeModal === 'remove') {
      setIncidentReprot({ ...incidentReport, files: [] });

      setTimeout(() => {
        setIsLoadingUpload(false);
      }, 500);
      setVisiable(false);
    } else {
      history.push({
        pathname: '/',
      });
    }
  };
  const handleCancel = () => {
    setVisiable(false);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const getInfoHome = () => {
    getHomeInfo()
      .then((result) => {
        setInfo(result?.data);
      })
      .catch((err) => {});
  };
  if (!token) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <Spin spinning={isLoadingQuestion}>
      <HeaderComponent />
      <ModalGlobal
        visible={visiable}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contentText={
          typeModal === 'back'
            ? t(`report.leave.title`)
            : typeModal === 'remove'
            ? t(`report.remove_files.title`)
            : t(`report.new.question_lists.required`)
        }
        type={typeModal}
        text={
          typeModal === 'back'
            ? t(`report.leave.ok`)
            : typeModal === 'remove'
            ? t(`report.remove_files.delete`)
            : t(`report.leave.ok`)
        }
        textCancel={
          typeModal === 'back' ? t(`report.leave.cancel`) : t(`report.remove_files.cancel`)
        }
      />
      <div style={{ paddingBottom: 15 }}>
        <Content className="Incident">
          <Spin spinning={isLoadingSubmitIncident}>
            <Row className="row">
              <Col className="col" xs={20} sm={16} md={12} lg={12}>
                <Text
                  className="title"
                  style={{ padding: '16px 0', cursor: 'pointer' }}
                  onClick={handleGoback}
                >
                  <LeftOutlined style={{ marginRight: '10px' }} />
                  {t('report.leave_btn')}
                </Text>
                <div className="container">
                  <Title level={5} className="report_title">
                    {user?.organization_name}
                  </Title>
                  <Form
                    className="form"
                    ref={ref}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    autoComplete={'off'}
                  >
                    <Title level={5} className="report_message" style={{ paddingBottom: 12 }}>
                      {t('report.new.content.title')}
                    </Title>
                    <Form.Item
                      name="description"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          message: t('report.new.description.required'),
                        },
                        {
                          whitespace: true,
                          message: t('report.new.description.whitespace'),
                        },
                      ]}
                      validateTrigger={'onSubmit'}
                    >
                      <TextArea
                        maxLength={12000}
                        className="input-area"
                        placeholder={t('report.new.content.placeholder')}
                        // autoSize={{ minRows: 3 }}
                        style={{ minHeight: 70 }}
                        onChange={(e) => {
                          form.setFields([
                            {
                              name: 'description',
                              errors: [],
                            },
                          ]);
                          setIncidentReprot({
                            ...incidentReport,
                            description: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    {isAllowUpload && (
                      <Form.Item
                        className="files-container"
                        name="files"
                        getValueFromEvent={normFile}
                      >
                        <Spin indicator={antIcon} spinning={isLoadingUpload}>
                          <div
                            className="upload-incidents"
                            style={{
                              border: '1px dashed #d9d9d9',
                            }}
                          >
                            <Space size={13} className="title-upload" onClick={handleClickUpload}>
                              <AttchFileIcon />
                              {t('report.new.upload_btn')}
                            </Space>
                            <input
                              onChange={(event: any) => handleUploadFile(event.target.files)}
                              multiple
                              type="file"
                              style={{ display: 'none' }}
                              ref={inputFileRef}
                            />
                          </div>
                          {incidentReport.files.length > 0 ? (
                            <div
                              className="upload-incidents"
                              style={{
                                background: '#D3F2FD',
                                marginTop: 10,
                              }}
                            >
                              <Space size={13} className="title-upload">
                                <AttchFileIcon />
                                {t(`incident.detail.uploaded_files`, {
                                  file: incidentReport.files.length,
                                })}
                              </Space>
                              <div className="close-container-uploaded" onClick={removeUploadFile}>
                                <div className="close">
                                  <Close />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </Spin>
                      </Form.Item>
                    )}
                    {Array.isArray(listQuestion) && listQuestion.length > 0 && (
                      <div className="list-question">
                        <Title level={5} className="report_message">
                          {t('incident.detail.question_lists')}
                        </Title>
                        <Text className="question-text">
                          ( <span style={{ marginRight: 8 }}></span>
                          {t('incident.detail.question_title')})
                        </Text>
                      </div>
                    )}

                    {listQuestion?.map((item, index) => {
                      const valueProps = item?.question_detail;
                      const isDropDown: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.DROPDOWN;
                      const isTextBox: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.TEXTBOX;
                      const isCheckBoxList: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.CHECKBOXLIST;
                      const isMail: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.EMAIL;
                      const isCheckBox: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.CHECKBOX;
                      const isRating: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.RATING;
                      const isDate: boolean =
                        item?.question_detail?.question_type === ETypeQuestion.DATE;
                      return (
                        <Fragment key={index}>
                          {isDropDown && (
                            <ListDropdown
                              listDropdown={valueProps}
                              changeAnswerDropDown={changeAnswerDropDown}
                              answersDropDown={answersDropDown}
                            />
                          )}
                          {isCheckBoxList && (
                            <ListCheckBoxList
                              listCheckBoxList={valueProps}
                              changeAnswerCheckboxList={changeAnswerCheckboxList}
                              answersCheckBoxList={answersCheckBoxList}
                            />
                          )}
                          {isDate && <ListDate listDate={valueProps} answersDate={answersDate} />}
                          {isRating && (
                            <ListRating
                              listRating={valueProps}
                              changeAnswerRating={changeAnswerRating}
                              answersRating={answersRating}
                            />
                          )}

                          {isTextBox && (
                            <ListTextBox listTextBox={valueProps} answersTextBox={answerTextBox} />
                          )}
                          {isMail && (
                            <ListEmail listEmail={valueProps} answersEmail={answerEmail} />
                          )}
                          {isCheckBox && (
                            <ListCheckBox
                              listCheckBox={valueProps}
                              answerCheckBox={answerCheckBox}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                    <Form.Item label={info?.incident_message} className="item-btn">
                      <Button
                        loading={isLoadingUpload}
                        type="primary"
                        htmlType="submit"
                        className="btn-finish"
                      >
                        {t('report.new.submit')}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </Spin>
        </Content>
      </div>
    </Spin>
  );
}
export default ReportPage;
