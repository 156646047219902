import { axios } from 'lib';

const PRE_FIX = 'anonymous/incident';

export const BASE_URL_CHAT = 'incident/chat/anonymouse';

export interface IQueryGetListIncident {
  passcode: string;
}

export interface IQueryGetIncidentDetail {
  incident_id?: string;
  reset?: boolean;
}

export interface IPostReadChatIncidentFormValues {
  incident_id: string;
}

export interface IPostHideIcident {
  incident_id: string;
}

const getIncidentList = (query: IQueryGetListIncident) => {
  return axios.get(`${PRE_FIX}/list`, { params: query });
};

const getListInicdentNotQuery = () => {
  return axios.get(`${PRE_FIX}/list`);
};

const getIncidentDetail = (query: IQueryGetIncidentDetail) => {
  return axios.get(`${PRE_FIX}/detail`, { params: query });
};

const getListMessageChat = (query: IQueryGetIncidentDetail) => {
  return axios.get(`${BASE_URL_CHAT}/messages`, { params: query });
};

const postReadChatAnonymouse = (payload: IPostReadChatIncidentFormValues) => {
  return axios.post(`${BASE_URL_CHAT}/read-message`, payload);
};

const getCountNewMessage = async () => {
  return axios.get(`${PRE_FIX}/count-new-message`);
};

const postHideIncident = (data: IPostHideIcident) => {
  return axios.post(`${PRE_FIX}/hidden`, data);
};

const getUpLoadFile = (data: any) => {
  return axios.post('anonymous/incident/url-upload', data);
};

const getLimitMessage = () => {
  return axios.get('anonymous/organization/message-settings');
};

const getSurvey = (data: any) => {
  return axios.post(`${BASE_URL_CHAT}/get-survey`, data);
};
const updateSurvey = (data: any) => {
  return axios.post(`${BASE_URL_CHAT}/update-survey`, data);
};

const checkEnableSurvey = () => {
  return axios.get('anonymous/organization/check-enable-survey');
};
export {
  getIncidentList,
  getListInicdentNotQuery,
  getIncidentDetail,
  getListMessageChat,
  postReadChatAnonymouse,
  getCountNewMessage,
  postHideIncident,
  getUpLoadFile,
  getLimitMessage,
  getSurvey,
  updateSurvey,
  checkEnableSurvey
};
